import React, { Component } from "react";
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import AddRCModal from './modal/AddRCModal'
import RCDetail from './modal/RCDetail'
import { MDBCol, MDBFormInline, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBIcon } from 'mdbreact';
import { MDBDatePicker } from 'mdbreact';
import moment from 'moment';
import DatePicker from "react-datepicker";
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import APIResponse from '../common/APIResponse'
import CustomCheckBox from '../pages/sections/CustomCheckBox'
import "react-datepicker/dist/react-datepicker.css";
import 'moment/locale/fr';
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'
import Pagination from "../common/Pagination";
import Select from 'react-select';
import "./Styleing.css";
import { DateRangePicker, Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import DateRangePickerModal from '../common/DateRangePickerModal'
import makeAnimated from 'react-select/animated';


const animatedComponents = makeAnimated();
class RCMasterPage extends Component {
    constructor(props) {

        super(props);
        this.state = {
            rcdata: [],
            filterRcdata: [],
            openModal: false,
            purchaseNumber: '',
            stock: '',
            rcBookId: '',
            updateRow: {},
            id: 0,
            selecteddata: {},
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            isSubmitted: '',
            contactData: [],
            selectedstock: [''],
            selectedcustomer: '',
            stockOptions: [{ id: 0, name: 'stock', label: "", value: "" }],

            selectedrcBookId: '',
            rcBookIdOptions: [{ id: 0, name: 'rcBookId', label: "", value: "" }],
            selectedpurchaseNumber: '',
            purchaseNumberOptions: [{ id: 0, name: 'purchaseNumber', label: "", value: "" }],
            selectedisSubmitted: '',
            issubmittedOptions: [{ name: 'isSubmitted', label: "Yes", value: "Yes" }, { name: 'isSubmitted', label: "No", value: "N0" }],
            isOpenDateRange: false,
            startDate: null,
            endDate: null,
            dateLabel: '',
        }
    }

    componentDidMount() {
        this.renderDataFromServer();
    }
    renderDataFromServer() {
        window.localStorage.setItem('editRCId', 0)
        window.localStorage.setItem('openRCFrom', '')
        window.localStorage.setItem('RCdata', '')
        HttpAPI.getRCPurchase().then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState({
                        rcdata: response.data,
                        filterRcdata: response.data,
                    })

                    const categories = response.data.map(x => x.stock);
                    let uniqueStock = []
                    categories.map(row => {
                        if (uniqueStock.indexOf(row) === -1) {
                            uniqueStock.push(row)
                        }
                    })
                    var arr = []
                    uniqueStock.map((item, key) =>
                        arr.push({ id: 0, name: 'stock', label: item, value: item })

                    );
                    this.setState({ stockOptions: arr })
                    var arr = []
                    arr.push({ id: 0, name: 'purchaseNumber', label: '', value: '' })
                    response.data.map((item, key) =>
                        arr.push({ id: item.id, name: 'purchaseNumber', label: item.purchaseNumber, value: item.purchaseNumber })
                    );
                    this.setState({ purchaseNumberOptions: arr })

                }
                var arr = []
                arr.push({ id: 0, name: 'rcBookId', label: '', value: '' })
                response.data.map((item, key) =>
                    arr.push({ id: item.id, name: 'rcBookId', label: item.rcBookId, value: item.rcBookId })
                );
                this.setState({ rcBookIdOptions: arr })
            }

        );

        HttpAPI.getContact().then(
            response => {
                var response = response.data;
                this.setState({
                    contactData: response.data
                })
                var arr = []
                arr.push({ id: 0, name: 'customer', label: '', value: '' })
                response.data.map((item, key) =>
                    arr.push({ id: item.id, name: 'customer', label: item.firstName, value: item.firstName })
                );
                this.setState({ customerOptions: arr })
            }
        );
    }
    checkBoxChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked });
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }

    searchClick = () => {
        let criteria = ''
        if(this.state.rcBookId!==''){
            criteria = criteria + 'rc_book_id='+this.state.rcBookId
        }
        if (this.state.isSubmitted !== '') {
            if (this.state.isSubmitted === 'Yes') {
                criteria = criteria + '&is_submitted=true'
            } else {
                criteria = criteria + '&is_submitted=false'
            }
        }
        if (this.state.stock !== '') {
            criteria = criteria + "&stock=" + this.state.stock
        }
        if (this.state.purchaseNumber !== '') {
            criteria = criteria + "&purchase_number=" + this.state.purchaseNumber
        }
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        if (this.state.startDate !== null) {
            criteria = criteria + "&rcDateFrom=" + (new Date(this.state.startDate - tzoffset)).toISOString().slice(0, -1)
        }
        if (this.state.endDate !== null) {
            criteria = criteria + "&rceDateTo=" + (new Date(this.state.endDate - tzoffset)).toISOString().slice(0, -1)
        }

        HttpAPI.getRCFilter(criteria).then(response => {
            var response = response.data;
            this.setState({
                rcdata: response.data,
                filterRcdata: response.data
            })
        });

    }
    changeSelectVar = (e) => {
        
        let id = e.id;
        this.setState({
            [e.name]: e.value,
            ['selected' + e.name]: e
        });
    }
    changeMultiSelectVar = (e) => {
        if(e.length>0){
            let stockdata=e.map(row=>row.value)
            let stockData=''
            stockdata.map(row=>{
                if(stockData===''){
                    stockData=row
                }else{
                    stockData=stockData+','+row
                }
            })
            this.setState({stock:stockData})
        }else{
            this.setState({stock:''})
        }
    }
    deleteRowById(id) {
        HttpAPI.deleteRCById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'RC Delete Successfully',
                        apiResponseModalOpen: true
                    })

                    this.renderDataFromServer();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.closeAPIResponse();
                }, 1000)


            }
        );
    }
    getRowById(id) {
        window.localStorage.setItem('editRCId', id)
        this.openaddrcmodel()

    }
    clearSearch = () => {
        this.setState({
            purchaseNumber: '',
            stock: '',
            startDate: null,
            rcBookId:'',
            endDate:null,
            dateLabel:'',
            isSubmitted: '',
            selectedstock: [''],
            selectedpurchaseNumber:'',
            selectedisSubmitted:'',
            selectedrcBookId:'',
            selectedisSubmitted:''
            
        })
        this.renderDataFromServer();
    }
    ViewRCDetail(id) {
        HttpAPI.getRCById(id).then(
            response => {
                var response = response.data;

                this.setState({ selecteddata: response.data })
                this.setState({ openModal: true })
            }
        );
    }
    handleDateChange = (e) => {
        this.setState({ rcRecvdDate: e });
    }
    openaddrcmodel = () => {
        //console.log("Button Clicked");
        //this.setState({ openModal: true })
        window.localStorage.setItem('openRCFrom', '')
        window.localStorage.setItem('openRCFromId', 0)
        window.localStorage.setItem('RCdata', '')
        window.location.href = '/addRC'
    }
    closeaddrcmodel = () => {
        this.setState({ selecteddata: {} })
        this.setState({ openModal: false })
    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    onToggleDateControl = () => {
        this.setState({ isOpenDateRange: true })
    }
    hangleDateRange = (selectedStartDate, selectedEndDate) => {

        this.setState({
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            isOpenDateRange: false,
            dateLabel: moment(selectedStartDate).format('DD/MM/YYYY').toString() + '-' + moment(selectedEndDate).format('DD/MM/YYYY').toString()
        })
    }
    globalSearch = (searchInput) => {
        let filteredData = this.state.rcdata.filter(value => {
            return (
                (value.rcRecvdDate !== null && moment(value.rcRecvdDate).format('DD/MM/YYYY').toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseMST.bikeNumber !== null && value.purchaseMST.bikeNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.rcBookId !== null && value.rcBookId.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.stock !== null && value.stock.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseNumber !== null && value.purchaseNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.Submitted !== null && (value.Submitted === true ? 'Yes' : 'No').toLowerCase().includes(searchInput.toLowerCase()))
            );
        });
        this.setState({ filterRcdata: filteredData });
    }
    render() {
        const selectionRange = {
            startDate: new Date(),
            endDate: new Date(),
            //key: 'selection',
        }
        const columns = [
            {
                id: "1",
                Header: () => (
                    <div className="bgcolor">
                        RC Date
                    </div>
                ),
                accessor: d => moment(d.rcRecvdDate).format('DD/MM/YYYY'),
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "2",
                Header: () => (
                    <div className="bgcolor">
                        Bike Number
                    </div>
                ),
                accessor: "purchaseMST.bikeNumber",
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "3",
                Header: () => (
                    <div className="bgcolor">
                        Rc Book ID
                    </div>
                ),
                accessor: "rcBookId",
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "4",
                Header: () => (
                    <div className="bgcolor">
                        Stock
                    </div>
                ),
                accessor: "stock",
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "5",
                Header: () => (
                    <div className="bgcolor">
                        Purchase No
                    </div>
                ),
                accessor: "purchaseNumber",
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },
            {
                id: "6",
                Header: () => (
                    <div className="bgcolor">
                        Submitted
                    </div>
                ),
                accessor: d => d.Submitted === true ? 'Yes' : 'No',
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },

            {
                id: "12",
                Header: () => (
                    <div className="bgcolor">
                        Actions
                    </div>
                ),
                accessor: "id",
                width: 150,
                maxWidth: 150,
                sortable: false,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='eye' style={{ cursor: 'pointer' }} onClick={() => this.ViewRCDetail(props.value)} />
                                <MDBIcon className=" m-2" icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.getRowById(props.value)} />
                                <MDBIcon className=" m-2" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.value)} />
                            </div>
                        </div>
                    );
                }
            }
        ]
        return (
            <React.Fragment>
                <MDBRow className="justify-content-left ml-0">
                    
                    <div className="col-sm-12 d-flex flex-row-reverse">
                        <CustomBreadCrumb items={['Master', 'RC']} linkTo={['', '']} />
                    </div>
                </MDBRow>
                <MDBRow className="">
                    <MDBCol sm="12" >

                        <MDBCard className="mb-5 mr-0 rccarddata ">
                            <MDBCardBody className="align-items-center justify-content-between mr-0">

                                <div className="row">
                                    <div className="form-group col-xl-2 col-lg-3 col-md-4 col-sm-12 col-xs-12">
                                        <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >  RcBook ID</label>
                                        <Select name="rcBookId" options={this.state.rcBookIdOptions} value={this.state.selectedrcBookId} onChange={this.changeSelectVar} id="select" class="browser-default md-select form-control">
                                            {/* <option value="select stock">Select Stock</option>
                                                
                                                {this.state.rcdata.map(item => {
                                                    return <option value={item.stock}>{item.stock}</option>
                                                })} */}
                                        </Select>
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-3 col-md-4 col-sm-12 col-xs-12 purchasefor">
                                        <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >  Stock</label>
                                        <Select name="stock" isMulti={true} 
                                        options={this.state.stockOptions} 
                                        //value={this.state.selectedstock} 
                                        onChange={this.changeMultiSelectVar} id="select"
                                        class="browser-default md-select form-control"
                                        closeMenuOnSelect={false}
                                        components={animatedComponents} 
                                        >
                                        
                                        </Select>
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-3 col-md-4 col-sm-12 col-xs-12  purchasefor">
                                        <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >  Purchase No</label>
                                        <Select name="purchaseNumber" options={this.state.purchaseNumberOptions} value={this.state.selectedpurchaseNumber} onChange={this.changeSelectVar} id="select" class="browser-default md-select form-control">
                                            {/* <option value="select stock">Select Stock</option>
                                                
                                                {this.state.rcdata.map(item => {
                                                    return <option value={item.stock}>{item.stock}</option>
                                                })} */}
                                        </Select>
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-3 col-md-3 col-sm-12 col-xs-12 purchasefor">
                                        <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Is Submitted</label>
                                        <Select name="isSubmitted" options={this.state.issubmittedOptions} value={this.state.selectedisSubmitted} onChange={this.changeSelectVar} id="select" class="browser-default md-select form-control">
                                            {/* <option value="select stock">Select Stock</option>
                                                
                                                {this.state.rcdata.map(item => {
                                                    return <option value={item.stock}>{item.stock}</option>
                                                })} */}
                                        </Select>
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-3 col-md-4 col-sm-12 col-xs-12 purchasedate ">
                                        <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Date Range</label>
                                        <input className="form-control"
                                            value={this.state.dateLabel}
                                            onClick={this.onToggleDateControl}
                                        />


                                    </div>
                                    <div className="form-group col-xl-1 col-lg-1 col-md-2 col-sm-12 col-xs-12">
                                        <MDBBtn className="  rcsave btn-md " size='md' onClick={this.searchClick}>
                                            <i className="fas fa-filter" />
                                            <span className="Savedata pl-1 ">
                                                Apply Filter
                                            </span>

                                        </MDBBtn>
                                    </div>
                                    <div className="form-group col-xl-1 col-lg-1 col-md-2 col-sm-12 col-xs-12">
                                        <MDBBtn className="rccancel" size='md' onClick={this.clearSearch}><i className="fas fa-undo-alt" /><span className="Savedata pl-1">Clear Filter</span></MDBBtn>
                                    </div>

                                </div>
                                <div className="row  purchasesearchicon">
                                    <div className="input-group md-form form-sm form-2 col-xl-11 col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                        <input className="form-control my-0 py-0  lime-border" type="text" onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                        <div className="input-group-append">
                                            <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                                aria-hidden="true"></i></span>
                                        </div>
                                    </div>
                                    <div className="form-group col-xl-1 col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                        <MDBBtn className="mt-3 purchaseaddnew" size='md' onClick={this.openaddrcmodel}>
                                            <span className="Savedata">
                                                Add New
                                            </span>
                                        </MDBBtn>
                                    </div>
                                </div>


                                <DateRangePickerModal isOpen={this.state.isOpenDateRange} hangleDateRange={this.hangleDateRange} />
                                <MDBRow className="justify-content-left districttable ">

                                    <MDBCol lg="12" className="mb-2">
                                        <ReactTable
                                            resizable={false}
                                            minRows={1}
                                            noDataText="NO DATA FOUND"
                                            showPagination={true}
                                            PaginationComponent={Pagination}
                                            defaultPageSize={25}
                                            style={{
                                                // This will force the table body to overflow and scroll, since there is not enough room
                                            }}
                                            className=' -highlight '
                                            data={this.state.filterRcdata}
                                            columns={columns} />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <APIResponse
                        isOpen={this.state.apiResponseModalOpen}
                        response={this.state.apiResponse}
                        msg={this.state.apiResponseMsg}
                        closeAPIResponse={this.closeAPIResponse}
                    />
                </MDBRow>
                <RCDetail isOpen={this.state.openModal} RCRecord={this.state.selecteddata} />
            </React.Fragment>


        );
    }
}
export default RCMasterPage;