import React, { Component } from "react";
import { MDBContainer, MDBFormInline, MDBModalBody, MDBModal, MDBInput, MDBCol, MDBRow, MDBCard, MDBCardBody, MDBBreadcrumb, MDBBreadcrumbItem, MDBBtn, MDBIcon } from "mdbreact";
import DatePicker from "react-datepicker";
import APIResponse from '../../common/APIResponse'
import HttpAPI from '../../common/api_calls';
import ApiUtils from '../../common/api_utils';
import Pagination from "../../common/Pagination";
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import CustomBreadCrumb from '../sections/CustomBreadCrumb'
import Select from 'react-select';
import * as moment from 'moment';
class AddExpense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sellData: [],
            purchaseData: [],
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            amount: '',
            description: '',
            reference: '',
            bikeNumber: '',
            vendorMobile: '',
            staffName: '',
            staffNumber: '',
            attachPhoto: null,
            date: new Date(),
            vendorName: '',
            vendorId: 0,
            purchaseNumber: '',
            purchaseId: 0,
            sellNumber: '',
            sellId: 0,
            expenseIncome: '',
            transctionType: '',
            attachPhotoName:'',
            selectedexpenseIncome: '',
            selectedtransctionType: '',
            selectedpurchaseSellNo: '',
            //selectedvendorName: '',
            expenseIncomeOption: [{ name: 'expenseIncome', label: "Income", value: "Income", }, { name: 'expenseIncome', label: "Expense", value: "Expense", }],
            transctionTypeOption: [{ name: 'transctionType', label: "Road Transport", value: "Road Transport" }, { name: 'transctionType', label: "Spare parts", value: "Spare parts" }, { name: 'transctionType', label: "Other", value: "Other" }],
            purchaseSellNoOption: [{ id: 0, name: 'purchaseSellNo', label: "", value: "" }],
            //vendorNameOption: [{ id: 0, name: 'vendor', label: "", value: "" }],
            id: 0,
            editRecord: {},
            errorMap: {
                'expenseIncome': { 'valid': true, 'error': '' },
                'transctionType': { 'valid': true, 'error': '' },
                'purchaseSellNo': { 'valid': true, 'error': '' },
                'vendor': { 'valid': true, 'error': '' },
                'amount': { 'valid': true, 'error': '' },
                'description': { 'valid': true, 'error': '' },
                'reference': { 'valid': true, 'error': '' },
                'bikeNumber': { 'valid': true, 'error': '' },
                'vendorMobile': { 'valid': true, 'error': '' },
                'staffName': { 'valid': true, 'error': '' },
                'staffNumber': { 'valid': true, 'error': '' },
                'attachPhoto': { 'valid': true, 'error': '' },
                'date': { 'valid': true, 'error': '' },
            }
        }
        this.expenseIncomeInput = React.createRef();
        this.transctionTypeInput = React.createRef();
        this.purchaseSellNoInput = React.createRef();
        this.vendorNameInput = React.createRef();
        this.amountInput = React.createRef();
        this.descriptionInput = React.createRef();
        this.referenceInput = React.createRef();
        this.bikeNumberInput = React.createRef();
        this.staffNameInput = React.createRef();
        this.staffNumberInput = React.createRef();
        this.attachPhotoInput = React.createRef();
        this.dateInput = React.createRef();
    }
    componentDidMount() {
        this.renderDataFromServer()
    }
    renderDataFromServer() {
        // HttpAPI.getContact().then(
        //     response => {
        //         var response = response.data;
        //         if (response.status === true) {
        //             let arr = []
        //             response.data.map(item => {
        //                 arr.push({
        //                     id: item.id, name: 'vendor', label: item.contactType + '-' + item.firstName + ' ' + item.lastName,
        //                     value: item.firstName
        //                 })
        //             })

        //             this.setState({ contactData: response.data, vendorNameOption: arr })
        //         }
        //     });
        HttpAPI.getPurchase().then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    HttpAPI.getSell().then(
                        responseSell => {
                            var responseSell = responseSell.data;
                            if (responseSell.status === true) {
                                let arr = []
                                response.data.map(row => {
                                    arr.push({
                                        id: row.id, name: 'purchaseSellNo', label: row.purchaseNumber,
                                        value: 'purchase-'+row.id
                                    })
                                })
                                responseSell.data.map(row => {
                                    arr.push({
                                        id: row.id, name: 'purchaseSellNo', label: row.sellCode,
                                        value: 'sell-'+row.id
                                    })
                                })
                                this.setState({ purchaseSellNoOption: arr,
                                    sellData:responseSell.data,
                                purchaseData:response.data })
                            } else {
                                let arr = []
                                response.data.map(row => {
                                    arr.push({
                                        id: row.id, name: 'purchaseSellNo', label: row.purchaseNumber,
                                        value: 'purchase'
                                    })
                                })
                                this.setState({ purchaseSellNoOption: arr,purchaseData:response.data })
                            }
                        });
                }
            });

        if (window.localStorage.getItem('editExpenseId') > 0) {
            HttpAPI.getExpenseById(window.localStorage.getItem('editExpenseId')).then(
                response => {
                    var response = response.data;
                    if (response.status === true) {
                        this.editData(response.data);
                    }
                })
        }

    }
    editData=(data)=>{
        this.setState({
            amount: data.amount,
            description: data.description,
            reference: data.refNumber,
            vendorMobile: '',
            staffName: data.staffMemberName,
            staffNumber: data.staffMemberMobile,
            attachPhoto: data.photo,
            date: new Date(data.date),
            expenseIncome: data.type,
            transctionType: data.transactionType,
            attachPhotoName:data.photo,
            vendorName: '',
            vendorId: 0,
            purchaseNumber: '',
            purchaseId: 0,
            sellNumber: '',
            sellId: 0,
            selectedexpenseIncome: {name:'expenseIncome',label:data.type,value:data.type},
            selectedtransctionType: {name:'transctionType',label:data.transactionType,value:data.transactionType},
            selectedpurchaseSellNo: '',
            selectedvendorName: '',
        })
        if(data.purchaseMSTId!==null){
            this.setState({
                bikeNumber: data.purchaseMST.bikeNumber,
                selectedpurchaseSellNo:{id:data.purchaseMSTId,name:'purchaseSellNo',label:data.purchaseMST.purchaseNumber,value:'purchase-'+data.purchaseMSTId},
                selectedvendorName:{id:data.purchaseMST.contactMST.id,name:'vendor',label:data.purchaseMST.contactMST.firstName,value:data.purchaseMST.contactMST.firstName},
                vendorMobile:data.purchaseMST.contactMST.mobileNumber
            })
        }
        if(data.sellMSTId!==null){
            this.setState({
                bikeNumber: data.sellMST.purchaseMST.bikeNumber,
                selectedpurchaseSellNo:{id:data.sellMSTId,name:'purchaseSellNo',label:data.sellMST.sellCode,value:'sell-'+data.sellMSTId},
                selectedvendorName:{id:data.sellMST.contactMST.id,name:'vendor',label:data.sellMST.contactMST.firstName,value:data.sellMST.contactMST.firstName},
                vendorMobile:data.sellMST.contactMST.mobileNumber
            })
        }
        
    }
    changeSelectVar = (e) => {
        this.setState({
            [e.name]: e.value,
            ['selected' + e.name]: e
        });
    }
    setFileInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.files[0],
            [e.target.name + 'Name']: e.target.files[0].name
        });
    }
    changePurchaseSell = (e) => {
        let id = e.id
        let arr=e.value.split('-')
        if (arr[0] === 'sell') {
            this.setState({
                purchaseNumber: '',
                purchaseId: 0,
                sellNumber: e.label,
                sellId: e.id,
            })
            let sellRecord=this.state.sellData.filter(row=>row.id===e.id)[0]
            this.setState({
                vendorName:sellRecord.contactMST.contactType + '-' + sellRecord.contactMST.firstName + ' ' + sellRecord.contactMST.lastName,
                vendorMobile:sellRecord.contactMST.mobileNumber,
                bikeNumber:sellRecord.purchaseMST.bikeNumber
            })
        } else {
            this.setState({
                purchaseNumber: e.label,
                purchaseId: e.id,
                sellNumber: '',
                sellId: 0,
            })
            let purchaseRecord=this.state.purchaseData.filter(row=>row.id===e.id)[0]
            this.setState({
                vendorName:purchaseRecord.contactMST.contactType + '-' + purchaseRecord.contactMST.firstName + ' ' + purchaseRecord.contactMST.lastName,
                vendorMobile:purchaseRecord.contactMST.mobileNumber,
                bikeNumber:purchaseRecord.bikeNumber
            })
        }
        this.setState({ selectedpurchaseSellNo: e })
    }
    changeVendor = (e) => {
        let id = e.id
        let record = this.state.contactData.filter(row => row.id === Number(id))[0]
        this.setState({
            vendorMobile: record.mobileNumber
        })
        this.setState({
            [e.name + 'Id']: e.id,
            [e.name + 'Name']: e.value,
            ['selected' + e.name]: e
        });
    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    updateExpense() {
        let data = {
            'id': this.state.id,
            'type': this.state.expenseIncome,
            'transactionType': this.state.transctionType,
            'purchaseMSTId': this.state.purchaseId,
            'sellMSTId': this.state.sellId,
            'description': this.state.description,
            'refNumber': this.state.reference,
            'amount': this.state.amount,
            'staffMemberName': this.state.staffName,
            'staffMemberMobile': this.state.staffNumber,
            'date': this.state.date,
            'active': this.state.editRecord.active,
            'createdAt': this.state.editRecord.createdAt,
            'createdBy': this.state.editRecord.createdBy
        }
        let senddata = ApiUtils.appendUpdateRequiredField(data)
        HttpAPI.updateExpense(senddata).then(response => {
            var response = response.data;
            if (response.status === true) {
                
                if(this.state.attachPhoto!==null){
                    HttpAPI.uploadExpenseImage(this.state.attachPhoto,response.data.id).then()
                }
                this.setState({
                    apiResponse: true,
                    apiResponseMsg: 'Expense updated successfully',
                    apiResponseModalOpen: true
                })
                this.clearData()
                this.renderDataFromServer();
            } else {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: response.message,
                    apiResponseModalOpen: true
                })
            }
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 1000)
        });
    }
    createExpense() {
        let data = {
            'type': this.state.expenseIncome,
            'transactionType': this.state.transctionType,
            'purchaseMSTId': this.state.purchaseId,
            'sellMSTId': this.state.sellId,
            'description': this.state.description,
            'refNumber': this.state.reference,
            'amount': this.state.amount,
            'staffMemberName': this.state.staffName,
            'staffMemberMobile': this.state.staffNumber,
            'date': this.state.date
        }
        let senddata = ApiUtils.appendCreateRequiredField(data)
        HttpAPI.createExpense(senddata).then(response => {
            var response = response.data;
            if (response.status === true) { 
                if(this.state.attachPhoto!==null){
                    HttpAPI.uploadExpenseImage(this.state.attachPhoto,response.data.id).then()
                }
                
                this.setState({
                    apiResponse: true,
                    apiResponseMsg: 'Expense Added successfully',
                    apiResponseModalOpen: true
                })

                this.clearData()
                this.renderDataFromServer();
            } else {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: response.message,
                    apiResponseModalOpen: true
                })
            }
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 1000)
        });
    }
    saveData = () => {
        if (this.validateData() === true) {
            if (this.state.id > 0) {
                this.updateExpense();
            } else {
                this.createExpense()
            }
        }
    }
    validateData() {
        let map = {
            'expenseIncome': { 'valid': true, 'error': '' },
            'transctionType': { 'valid': true, 'error': '' },
            'purchaseSellNo': { 'valid': true, 'error': '' },
            'vendor': { 'valid': true, 'error': '' },
            'amount': { 'valid': true, 'error': '' },
            'description': { 'valid': true, 'error': '' },
            'reference': { 'valid': true, 'error': '' },
            'bikeNumber': { 'valid': true, 'error': '' },
            'vendorMobile': { 'valid': true, 'error': '' },
            'staffName': { 'valid': true, 'error': '' },
            'staffNumber': { 'valid': true, 'error': '' },
            'attachPhoto': { 'valid': true, 'error': '' },
            'date': { 'valid': true, 'error': '' },
        }
        let isValid = true;
        if (this.state.expenseIncome === '') {
            map.expenseIncome.valid = false;
            map.expenseIncome.error = 'Please select Expense or Income'
            if (isValid === true) {
                this.expenseIncomeInput.current.focus()
            }
            isValid = false
        }
        if (this.state.transctionType === '') {
            map.transctionType.valid = false;
            map.transctionType.error = 'Please select Transaction Type'
            if (isValid === true) {
                this.transctionTypeInput.current.focus()
            }
            isValid = false
        }
        if (this.state.amount === '') {
            map.amountInput.valid = false;
            map.amountInput.error = 'Please fill amount'
            if (isValid === true) {
                this.transctionTypeInput.current.focus()
            }
            isValid = false
        }

        return isValid;
    }
    closeAddForm = () => {
        this.clearData();
        window.localStorage.setItem('editExpenseId', 0)
        window.location.href = '/misc'
    }
    clearData() {

    }
    render() {
        return (<div>
            <MDBRow className="justify-content-left ml-0" >
                <MDBCol sm="3">

                </MDBCol>

                <MDBCol sm="3">

                </MDBCol>
                <div className="CustomBreadCrumbaddvoucher">
                    <CustomBreadCrumb items={['Account', 'Miscellaneous', 'Add Expense']} linkTo={['', '/misc', '']} />
                </div>
            </MDBRow>
            <MDBRow className="justify-content-left ml-0 ">
                <MDBCol lg="12">
                    <MDBCard className="mb-5">
                        <MDBCardBody className="align-items-center justify-content-between">
                            <div className="row">
                                <div className="form-group col-sm-2">
                                    <label for="expenseIncomeId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Expense/Income</label>
                                    <Select name="expenseIncome" ref={this.expenseIncomeInput} options={this.state.expenseIncomeOption} value={this.state.selectedexpenseIncome} onChange={this.changeSelectVar} id="expenseIncomeId" class="input-field browser-default md-select form-control form-control-sm" >
                                    </Select>
                                    <span className='error-message'>{this.state.errorMap.expenseIncome.valid == true ? '' : this.state.errorMap.expenseIncome.error}</span>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="transctionTypeId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Transaction Type</label>
                                    <Select name="transctionType" ref={this.transctionTypeInput} options={this.state.transctionTypeOption} value={this.state.selectedtransctionType} onChange={this.changeSelectVar} id="transctionTypeId" class="input-field browser-default md-select form-control form-control-sm" >
                                    </Select>
                                    <span className='error-message'>{this.state.errorMap.transctionType.valid == true ? '' : this.state.errorMap.transctionType.error}</span>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="amountId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Amount</label>
                                    <input type="text" ref={this.amountInput} name="amount" value={this.state.amount} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="amountId"></input>
                                    <span className='error-message'>{this.state.errorMap.amount.valid == true ? '' : this.state.errorMap.amount.error}</span>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label for="descriptionId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Description</label>
                                    <input type="text" ref={this.descriptionInput} name="description" value={this.state.description} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="descriptionId"></input>
                                    <span className='error-message'>{this.state.errorMap.description.valid == true ? '' : this.state.errorMap.description.error}</span>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="referenceId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Reference Number</label>
                                    <input type="text" ref={this.descriptionInput} name="reference" value={this.state.reference} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="referenceId"></input>
                                    <span className='error-message'>{this.state.errorMap.reference.valid == true ? '' : this.state.errorMap.reference.error}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-sm-2">
                                    <label for="purchaseSellNoId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Purchase/Sell No</label>
                                    <Select name="purchaseSellNo" ref={this.purchaseSellNoInput} options={this.state.purchaseSellNoOption} value={this.state.selectedpurchaseSellNo} onChange={this.changePurchaseSell} id="purchaseSellNoId" class="input-field browser-default md-select form-control form-control-sm" >
                                    </Select>
                                    <span className='error-message'>{this.state.errorMap.purchaseSellNo.valid == true ? '' : this.state.errorMap.purchaseSellNo.error}</span>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="bikeNoId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Bike Number</label>
                                    <input disabled type="text" ref={this.bikeNumberInput} name="bikeNumber" value={this.state.bikeNumber} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="bikeNoId"></input>
                                    <span className='error-message'>{this.state.errorMap.bikeNumber.valid == true ? '' : this.state.errorMap.bikeNumber.error}</span>
                                </div>
                                {/* <div className="form-group col-sm-2">
                                    <label for="vendorNameId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Vendor Name</label>
                                    <Select name="vendor" ref={this.vendorNameInput} options={this.state.vendorNameOption} value={this.state.selectedvendorName} onChange={this.changeVendor} id="vendorNameId" class="input-field browser-default md-select form-control form-control-sm" >
                                    </Select>
                                    <span className='error-message'>{this.state.errorMap.vendor.valid == true ? '' : this.state.errorMap.vendor.error}</span>
                                </div> */}
                                 <div className="form-group col-sm-2">
                                    <label for="vendorNameId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Vendor Name</label>
                                    <input disabled type="text" ref={this.vendorNameInput} name="vendorName" value={this.state.vendorName} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="vendorNameId"></input>
                                    <span className='error-message'>{this.state.errorMap.vendor.valid == true ? '' : this.state.errorMap.vendor.error}</span>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="vendorMobileId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Vendor Mobile</label>
                                    <input disabled type="text" ref={this.vendorMobileInput} name="vendorMobile" value={this.state.vendorMobile} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="vendorMobileId"></input>
                                    <span className='error-message'>{this.state.errorMap.vendorMobile.valid == true ? '' : this.state.errorMap.vendorMobile.error}</span>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="staffNameId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Staff Name</label>
                                    <input type="text" ref={this.staffNameInput} name="staffName" value={this.state.staffName} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="staffNameId"></input>
                                    <span className='error-message'>{this.state.errorMap.staffName.valid == true ? '' : this.state.errorMap.staffName.error}</span>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="staffNumberId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Staff Number</label>
                                    <input type="text" ref={this.vendorMobileInput} name="staffNumber" value={this.state.staffNumber} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="staffNumberId"></input>
                                    <span className='error-message'>{this.state.errorMap.staffNumber.valid == true ? '' : this.state.errorMap.staffNumber.error}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 browsedata">
                                    <label for="inputGroupFile01" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Attach Photo</label>
                                    <input
                                        type="file"
                                        className="custom-file-input font-small"
                                        id="inputGroupFile01"
                                        aria-describedby="inputGroupFileAddon01"
                                        name='attachPhoto'
                                        accept='.jpg, .png, .jpeg'
                                        onChange={this.setFileInputVar}
                                        ref={this.attachPhotoInput}
                                    />
                                    <label className="custom-file-label font-small browsefile" htmlFor="inputGroupFile01">
                                        {this.state.attachPhotoName}</label>
                                    <span className='error-message'>{this.state.errorMap.attachPhoto.valid == true ? '' : this.state.errorMap.attachPhoto.error}</span>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Date</label>
                                    <DatePicker
                                        selected={this.state.date}
                                        onChange={this.handleDateChange}
                                        placeholderText='Date'
                                        className="form-control"
                                        
                                        ref={this.dateInput}
                                    />
                                    <span className='error-message'>{this.state.errorMap.date.valid == true ? '' : this.state.errorMap.date.error}</span>
                                </div>
                            </div>
                            <MDBRow className="SaveCancelbutton mt-1">
                                <MDBCol lg='6'>
                                    <MDBBtn size='sm' onClick={this.saveData}>
                                        <span className="Savedata">
                                            Save
                                    </span>
                                    </MDBBtn>
                                    <MDBBtn size='sm' onClick={this.closeAddForm}>
                                        <span className="Savedata">
                                            Cancel
                                    </span>
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
            <APIResponse
                isOpen={this.state.apiResponseModalOpen}
                response={this.state.apiResponse}
                msg={this.state.apiResponseMsg}
                closeAPIResponse={this.closeAPIResponse}
            />
        </div>);
    }
}
export default AddExpense