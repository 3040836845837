import React from 'react';
import { MDBFooter, MDBBtn, MDBIcon, MDBCol } from 'mdbreact';

const Footer = () => {
    if (window.location.pathname === '/login'||window.location.pathname === '/') return null;
    return (
        <div class="font-small  fixed-bottom">
         <p className="footer-copyright mb-0 py-1 text-right">
                &copy; {new Date().getFullYear()} Copyright: <a href="https://www.harvices.com"> harvices.com </a>
            </p>
      </div>
      
    );
}

export default Footer;