import React, { Component } from "react";
import { MDBIcon, MDBTabPane, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBCol, MDBselect } from 'mdbreact';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import HttpAPI from '../../common/api_calls';
import ApiUtils from '../../common/api_utils';
import APIResponse from '../../common/APIResponse'
import "./addressinfocardsession.css";
import Pagination from "../../common/Pagination";
import Select from 'react-select';
class AddressInfoCardSection extends Component {
    constructor(props) {
        super(props);
        this.state = {

            addressInfoData: [],
            addressTypeList: ApiUtils.getAddressType(),
            contactType: this.props.contactType,
            addressType: '',
            addressId: '',
            addressLine1: '',
            addressLine2: '',
            countryId: 0,
            countryName: '',
            countryData: [],
            stateId: 0,
            stateName: '',
            stateData: [],
            filterState: [],
            cityId: 0,
            cityName: '',
            cityData: [],
            filterCity: [],
            pincode: '',
            localCreate: [],
            maxId: 1,
            recordId: 0,
            addressInfoData: this.props.addRessInfo,
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            disableAddressName: true,
            selectedaddressType: '',
            selectedcountry: '',
            selectedstate: '',
            selectedcity: '',
            addressTypeOption: [{ id: 0, name: 'addressType', label: "", value: "" }],
            countryOption: [{ id: 0, name: 'country', label: "", value: "" }],
            stateOption: [{ id: 0, name: 'state', label: "", value: "" }],
            cityOption: [{ id: 0, name: 'city', label: "", value: "" }],
            errorMap:{'addressType': { 'valid': true, 'error': '' },
            'addressLine1': { 'valid': true, 'error': '' },
            'country': { 'valid': true, 'error': '' },
            'state': { 'valid': true, 'error': '' },
            'city': { 'valid': true, 'error': '' },
            'pincode': { 'valid': true, 'error': '' },}
        }
        this.addressTypeInput = React.createRef();
        this.addressLine1Input = React.createRef();
        this.countryInput = React.createRef();
        this.stateInput = React.createRef();
        this.cityInput = React.createRef();
        this.pincodeInput = React.createRef();
    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    setSelectInputVar = (e) => {
        if (e.name === 'addressType') {
            if (this.state.contactType === 'Bank' && e.value === 'Yard') {
                this.setState({
                    disableAddressName: false
                })
            } else {
                this.setState({
                    addressName: '',
                    disableAddressName: true
                })
            }
        }
        this.setState({
            [e.name]: e.value,
            ['selected' + e.name]: e
        });


    }
    saveData = () => {
        if (this.validateData() == true) {
            if (this.state.recordId === 0) {
                this.createAddressInfo();
            } else {
                this.updateAddressInfo();
            }

        }

    }
    changeSelectVar = (e) => {
        this.setState({
            [e.name + 'Id']: e.id,
            [e.name + 'Name']: e.value,
            ['selected' + e.name]: e
        });
    }
    changeCountryVar = (e) => {
        var filterId = Number(e.id);
        const data = this.state.stateData.filter(row => row.countryId === filterId);
        // this.setState({
        //     filterState: data,
        //     filterCity: []
        // });
        var arr = []
        arr.push({ id: 0, name: 'state', label: '', value: '' })
        data.filter(row => row.countryId === this.state.countryId).map((item, key) =>
            arr.push({
                id: item.id, name: 'state',
                label: item.stateName,
                value: item.stateName
            })
        );
        this.setState({
            stateOption: arr,
            selectedstate: '',
            selectedcity: '',
            cityOption: [],
            stateName: '',
            stateId: 0,
            cityName: '',
            cityId: 0
        })
        this.changeSelectVar(e);
    }
    changeStateVar = (e) => {
        var filterId = Number(e.id);

        const data = this.state.cityData.filter(row => row.stateId === filterId);

        var arr = []
        arr.push({ id: 0, name: 'city', label: '', value: '' })
        data.filter(row => row.countryId === this.state.countryId).map((item, key) =>
            arr.push({
                id: item.id, name: 'city',
                label: item.cityName,
                value: item.cityName
            })
        );
        this.setState({
            cityOption: arr,
            selectedcity: '',
            cityName: '',
            cityId: 0
        })
        this.changeSelectVar(e);
    }
    clearData = () => {
        this.setState({
            addressId: '',
            addressType: '',
            addressName: '',
            addressLine1: '',
            addressLine2: '',
            countryId: '',
            countryName: '',
            stateId: '',
            stateName: '',
            cityId: '',
            cityName: '',
            pincode: '',
            recordId: 0,
            disableAddressName: true,
            errorMap:{'addressType': { 'valid': true, 'error': '' },
            'addressLine1': { 'valid': true, 'error': '' },
            'country': { 'valid': true, 'error': '' },
            'state': { 'valid': true, 'error': '' },
            'city': { 'valid': true, 'error': '' },
            'pincode': { 'valid': true, 'error': '' },}
        })
       
    }
    populateDefault = () => {

        let filterCountry = this.state.countryData.filter(row => row.countryName.toLowerCase() === 'india')
        if (filterCountry !== null && filterCountry.length > 0) {
            this.setState({
                countryName: filterCountry[0].countryName,
                countryId: filterCountry[0].id,
                selectedcountry: { id: filterCountry[0].id, name: 'country', label: filterCountry[0].countryName, value: filterCountry[0].countryName }
            })
        }
        let filterState = this.state.stateData.filter(row => row.stateName.toLowerCase() === 'gujarat')
        if (filterState !== null && filterState.length > 0) {
            this.setState({
                filterState: this.state.stateData.filter(row => row.countryId === this.state.countryId),
                stateName: filterState[0].stateName,
                stateId: filterState[0].id,
                selectedstate: { id: filterState[0].id, name: 'state', label: filterState[0].stateName, value: filterState[0].stateName }
            })
        }
        let filterCity = this.state.cityData.filter(row => row.cityName.toLowerCase() === 'savarkundla')
        if (filterCity !== null && filterCity.length > 0) {
            this.setState({
                filterCity: this.state.cityData.filter(row => row.stateId === this.state.stateId),
                cityName: filterCity[0].cityName,
                cityId: filterCity[0].id,
                selectedcity: { id: filterCity[0].id, name: 'city', label: filterCity[0].cityName, value: filterCity[0].cityName }
            })
        }
    }
    validateData() {
        let map = {
            'addressType': { 'valid': true, 'error': '' },
            'addressLine1': { 'valid': true, 'error': '' },
            'country': { 'valid': true, 'error': '' },
            'state': { 'valid': true, 'error': '' },
            'city': { 'valid': true, 'error': '' },
            'pincode': { 'valid': true, 'error': '' },
        }
        let isValid = true;
        if (this.state.addressType === '' || this.state.addressType === 'select address Type') {
            //this.setState({ apiResponseMsg: 'Please select Address Type' })
            map.addressType.valid = false;
            map.addressType.error = 'Please select Address Type'
            this.addressTypeInput.current.focus()
            isValid = false
        }
        if (this.state.addressInfoData.length > 0) {
            let addressType = this.state.addressInfoData.filter(row => row.addressType === this.state.addressType)
            if (addressType.length > 0) {
                //this.setState({ apiResponseMsg: 'Address Type already present' })
                map.addressType.valid = false;
                map.addressType.error = 'Address Type already present'
                if(isValid===true){
                    this.addressType.current.focus()
                }
                isValid = false
            }

        }
        if (this.state.addressLine1 === '' && this.state.addressLine2 === '') {
            //this.setState({ apiResponseMsg: 'Please Fill Address' })
            map.addressLine1.valid = false;
            map.addressLine1.error = 'Please Fill Address'
            if(isValid===true){
                this.addressLine1Input.current.focus()
            }
            isValid = false
        }
        if (this.state.countryName === '' || this.state.countryName === 'select country') {
            //this.setState({ apiResponseMsg: 'Please select Country' })
            map.country.valid = false;
            map.country.error = 'Please select Country'
            if(isValid===true){
                this.countryInput.current.focus()
            }
            isValid = false
        }
        if (this.state.stateName === '' || this.state.stateName === 'select state') {
            //this.setState({ apiResponseMsg: 'Please select State' })
            map.state.valid = false;
            map.state.error = 'Please select State'
            if(isValid===true){
                this.stateInput.current.focus()
            }
            isValid = false
        }
        if (this.state.cityName === '' || this.state.cityName === 'select city') {
            //this.setState({ apiResponseMsg: 'Please select City' })
            map.city.valid = false;
            map.city.error = 'Please select City'
            if(isValid===true){
                this.cityInput.current.focus()
            }
            isValid = false
        }
        if (this.state.pincode === '') {
            //this.setState({ apiResponseMsg: 'Please fill Pincode' })
            map.pincode.valid = false;
            map.pincode.error = 'Please fill Pincode'
            if(isValid===true){
                this.pincodeInput.current.focus()
            }
            isValid = false
        }
        if (ApiUtils.validateZipcode(this.state.pincode) === false) {
            //this.setState({ apiResponseMsg: 'Please fill valid Pincode' })
            map.pincode.valid = false;
            map.pincode.error = 'Please fill valid Pincode'
            if(isValid===true){
                this.pincodeInput.current.focus()
            }
            isValid = false
        }
        
        this.setState({errorMap:map})
        return isValid;
    }
    componentDidMount() {
        this.renderDataFromServer();
    }
    componentWillReceiveProps(newProps) {
        this.setState({
            contactId: newProps.contactId,
            contactType: newProps.contactType
        })
        if (newProps.contactId > 0) {
            HttpAPI.getAddressByContactId(newProps.contactId).then(
                response => {
                    var response = response.data;
                    if (this.state.localCreate.length === 0) {
                        this.setState({ addressInfoData: response.data })
                        let size = response.data.length + 1
                        this.setState({ maxId: size })
                    } else {

                        let localData = this.state.localCreate
                        response.data.map(row => {
                            localData.push(row)
                        })

                        this.setState({ addressInfoData: localData })
                        let size = localData.length + 1
                        this.setState({ maxId: size })
                    }


                }
            );
        }
    }

    updateAddressInfo = () => {
        let countryData = {
            'countryName': this.state.countryName
        }
        let stateData = {
            'stateName': this.state.stateName
        }
        let cityData = {
            'cityName': this.state.cityName
        }
        let updatedata = this.state.addressInfoData;
        updatedata.map(row => {
            if (row.id === this.state.recordId) {

                row.addressId = this.state.addressId
                row.addressType = this.state.addressType
                row.addressName = this.state.addressName
                row.addressLine1 = this.state.addressLine1
                row.addressLine2 = this.state.addressLine2
                row.countryId = this.state.countryId
                row.countryMST = countryData
                row.stateId = this.state.stateId
                row.stateMST = stateData
                row.cityId = this.state.cityId
                row.cityMST = cityData
                row.pinCode = this.state.pincode
            }
        })
        this.setState({ addressInfoData: updatedata })
        this.props.setAddressInfo(updatedata)
        this.clearData();
        this.populateDefault();

    }
    createAddressInfo = () => {
        let countryData = {
            'countryName': this.state.countryName
        }
        let stateData = {
            'stateName': this.state.stateName
        }
        let cityData = {
            'cityName': this.state.cityName
        }
        let data = {
            'id': this.state.maxId,
            'addressId': this.state.addressId,
            'addressName': this.state.addressName,
            'addressType': this.state.addressType,
            'addressLine1': this.state.addressLine1,
            'addressLine2': this.state.addressLine2,
            'countryId': this.state.countryId,
            'countryMST': countryData,
            'stateId': this.state.stateId,
            'stateMST': stateData,
            'cityId': this.state.cityId,
            'cityMST': cityData,
            'pinCode': this.state.pincode,

        }

        let combineData = this.state.addressInfoData
        let localCombineData = this.state.localCreate;
        combineData.push(data)
        localCombineData.push(data)
        this.setState({
            addressInfoData:
                combineData,
            localCreate: localCombineData
        })
        this.props.setAddressInfo(combineData)
        this.clearData()
        this.populateDefault();


    }
    renderDataFromServer() {

        HttpAPI.getCountry().then(
            response => {
                var response = response.data;
                this.setState({ countryData: response.data })
                var arr = []
                arr.push({ id: 0, name: 'country', label: '', value: '' })
                response.data.map((item, key) =>
                    arr.push({
                        id: item.id, name: 'country',
                        label: item.countryName,
                        value: item.countryName
                    })
                );
                this.setState({ countryOption: arr })
                let filterCountry = response.data.filter(row => row.countryName.toLowerCase() === 'india')
                if (filterCountry !== null && filterCountry.length > 0) {
                    this.setState({
                        countryName: filterCountry[0].countryName,
                        countryId: filterCountry[0].id,
                        selectedcountry: { id: filterCountry[0].id, name: 'country', label: filterCountry[0].countryName, value: filterCountry[0].countryName }
                    })
                }
            }
        );
        HttpAPI.getState().then(
            response => {
                var response = response.data;
                this.setState({ stateData: response.data })
                //this.populateDefault();

                let filterState = response.data.filter(row => row.stateName.toLowerCase() === 'gujarat')
                if (filterState !== null && filterState.length > 0) {
                    this.setState({
                        stateName: filterState[0].stateName,
                        stateId: filterState[0].id,
                        selectedstate: { id: filterState[0].id, name: 'state', label: filterState[0].stateName, value: filterState[0].stateName }
                    })
                    var arr = []
                    arr.push({ id: 0, name: 'state', label: '', value: '' })
                    response.data.filter(row => row.countryId === this.state.countryId).map((item, key) =>
                        arr.push({
                            id: item.id, name: 'state',
                            label: item.stateName,
                            value: item.stateName
                        })
                    );
                    this.setState({ stateOption: arr })
                }

            }
        );
        HttpAPI.getCity().then(
            response => {
                var response = response.data;
                this.setState({ cityData: response.data })
                let filterCity = response.data.filter(row => row.cityName.toLowerCase() === 'savarkundla')
                if (filterCity !== null && filterCity.length > 0) {
                    this.setState({
                        cityName: filterCity[0].cityName,
                        cityId: filterCity[0].id,
                        selectedcity: {
                            id: filterCity[0].id, name: 'city',
                            label: filterCity[0].cityName,
                            value: filterCity[0].cityName
                        }
                    })
                    var arr = []
                    arr.push({ id: 0, name: 'city', label: '', value: '' })
                    response.data.filter(row => row.stateId === this.state.stateId).map((item, key) =>
                        arr.push({
                            id: item.id, name: 'city',
                            label: item.cityName,
                            value: item.cityName
                        })
                    );
                    this.setState({ cityOption: arr })
                }
                var arr = []
                arr.push({ id: 0, name: 'addressType', label: '', value: '' })
                this.state.addressTypeList.map((item, key) =>
                    arr.push({
                        id: 0, name: 'addressType',
                        label: item,
                        value: item
                    })
                );
                this.setState({ addressTypeOption: arr })
                this.populateDefault();
            }
        );

    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    deleteRowById = (id) => {
        let data = []
        let filterData = this.state.addressInfoData.filter(row => row.id === id)[0]
        this.state.addressInfoData.map(row => {
            if (row.id !== id) {
                data.push(row)
            }
        })
        if (filterData.createdAt === undefined) {
            let localCreateData = []
            this.state.localCreate.map(row => {
                if (row.id !== id) {
                    localCreateData.push(row)
                }
            })
            this.setState({ localCreate: localCreateData })
            this.setState({ addressInfoData: data })
            this.props.setAddressInfo(data)

        } else {
            HttpAPI.deleteAddressById(id).then(
                response => {
                    this.setState({ addressInfoData: data })
                    this.props.setAddressInfo(data)
                });
        }


    }
    getRowById = (id) => {
        let data = this.state.addressInfoData.filter(n => n.id === id)[0]
        const stateFilterData = this.state.stateData.filter(row => row.countryId === data.countryId);
        const cityFilterData = this.state.cityData.filter(row => row.stateId === data.stateId)
        this.setState({
            addressId: data.addressId,
            addressType: data.addressType,
            addressName: data.addressName,
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            countryId: data.countryId,
            countryName: data.countryMST.countryName,
            stateId: data.stateId,
            stateName: data.stateMST.stateName,
            cityId: data.cityId,
            cityName: data.cityMST.cityName,
            pincode: data.pinCode,
            filterState: stateFilterData,
            filterCity: cityFilterData,
            recordId: data.id,
            selectedaddressType: { id: 0, name: 'addressType', label: data.addressType, value: data.addressType },
            selectedcountry: { id: data.countryMST.id, name: 'country', label: data.countryMST.countryName, value: data.countryMST.countryName },
            selectedstate: { id: data.stateMST.id, name: 'state', label: data.stateMST.stateName, value: data.stateMST.stateName },
            selectedcity: { id: data.cityMST.id, name: 'city', label: data.cityMST.cityName, value: data.cityMST.cityName },
        })


    }
    render() {
        const columns = [
            {
                id: "1",
                Header: () => (
                    <div className="bgcolor">
                        Address Type
                    </div>
                ),
                accessor: "addressType",
                style:{
                        
                    height:35,
                    textAlign:'center'
                    
                    
                
                
            }

            },
            {
                id: "2",
                Header: () => (
                    <div className="bgcolor">
                        Country
                    </div>
                ),
                accessor: "countryMST.countryName",
                style:{
                        
                    height:35,
                    textAlign:'center'
                    
                    
                
                
            }
            },
            {
                id: "3",
                Header: () => (
                    <div className="bgcolor">
                        State
                    </div>
                ),
                accessor: "stateMST.stateName",
                style:{
                        
                    height:35,
                    textAlign:'center'
                    
                    
                
                
            }
            },
            {
                id: "4",
                Header: () => (
                    <div className="bgcolor">
                        City
                    </div>
                ),
                accessor: "cityMST.cityName",
                style:{
                        
                    height:35,
                    textAlign:'center'
                    
                    
                
                
            }
            },
            {
                id: "5",
                Header: () => (
                    <div className="bgcolor">
                        Pincode
                    </div>
                ),
                accessor: "pinCode",
                style:{
                        
                    height:35,
                    textAlign:'center'
                    
                    
                
                
            }
            },
            {
                id: "6",
                Header: () => (
                    <div className="bgcolor">
                        Actions
                    </div>
                ),
                accessor: "id",
                sortable: false,
                style:{
                        
                    height:35,
                    textAlign:'center'
                    
                    
                
                
            },
                Cell: props => {

                    return (

                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.getRowById(props.value)} />
                                <MDBIcon className=" m-2" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.value)} />
                            </div>
                        </div>

                    );
                }
            }
        ]
        return (<div>
            <MDBCard className="m-0 p-0 carddata">
                <MDBCardBody className="align-items-center justify-content-between">
                    <div className="row">
                        <div className="form-group col-xl-2 col-lg-2 col-md-3">
                            <label for="Addresstype" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Address type</label>
                            <Select ref={this.addressTypeInput} options={this.state.addressTypeOption} name='addressType' value={this.state.selectedaddressType} onChange={this.setSelectInputVar} id="Addresstype" class="input-field browser-default sm-select form-control">
                                {/* <option >Select</option>
                                {
                                    this.state.addressTypeList.map(item => {
                                        return <option value={item}>{item}</option>
                                    })
                                } */}
                            </Select>
                            <span className='error-message'>{this.state.errorMap.addressType.valid == true ? '' : this.state.errorMap.addressType.error}</span>
                        </div>
                        <div className="form-group col-xl-2 col-lg-2 col-md-3">
                            <label for="AddressName" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Address Name</label>
                            <input type="text" disabled={this.state.disableAddressName} name='addressName' onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" value={this.state.addressName} id="AddressName">
                            </input>
                        </div>
                        <div className="form-group col-xl-2 col-lg-2 col-md-3">
                            <label for="addressLine1" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Address Line 1</label>
                            <input type="text" ref={this.addressLine1Input} name='addressLine1' onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" value={this.state.addressLine1} id="addressLine1">
                            </input>
                            <span className='error-message'>{this.state.errorMap.addressLine1.valid == true ? '' : this.state.errorMap.addressLine1.error}</span>
                        </div>
                        <div className="form-group col-xl-2 col-lg-2 col-md-3">
                            <label for="addressLine2" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Address Line 2</label>
                            <input type="text" name='addressLine2' onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" value={this.state.addressLine2} id="addressLine2">
                            </input>
                        </div>
                        <div className="form-group col-xl-2 col-lg-2 col-md-3" >
                            <label for="country" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Country</label>
                            <Select ref={this.countryInput} options={this.state.countryOption} name="country" value={this.state.selectedcountry} onChange={this.changeCountryVar} id="country" class="input-field browser-default md-select form-control">
                                {/* <option value="select country">Select</option>
                                {
                                    this.state.countryData.map(item => {
                                        return <option id={item.id} value={item.countryName}>{item.countryName}</option>
                                    })
                                } */}
                            </Select>
                            <span className='error-message'>{this.state.errorMap.country.valid == true ? '' : this.state.errorMap.country.error}</span>
                        </div>
                        <div className="form-group col-xl-2 col-lg-2 col-md-3" >
                            <label for="state" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >State</label>
                            <Select ref={this.stateInput} options={this.state.stateOption} name="state" id="state" value={this.state.selectedstate} onChange={this.changeStateVar} class="input-field browser-default md-select form-control">
                                {/* <option value="select state">Select</option>
                                {
                                    this.state.filterState.map(item => {
                                        return <option id={item.id} value={item.stateName}>{item.stateName}</option>
                                    })
                                } */}
                            </Select>
                            <span className='error-message'>{this.state.errorMap.state.valid == true ? '' : this.state.errorMap.state.error}</span>
                        </div>
                    </div>
                    <div className="row">
                        
                        
                        <div className="form-group col-xl-2 col-lg-2 col-md-3" >
                            <label for="city" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >City</label>
                            <Select ref={this.cityInput} name="city" id="city" options={this.state.cityOption} value={this.state.selectedcity} onChange={this.changeSelectVar} class="input-field browser-default md-select form-control">
                                {/* <option value="select city" label="Select City" size="sm"></option>
                                {
                                    this.state.filterCity.map(item => {
                                        return <option id={item.id} value={item.cityName}>{item.cityName}</option>
                                    })
                                } */}
                            </Select>
                            <span className='error-message'>{this.state.errorMap.city.valid == true ? '' : this.state.errorMap.city.error}</span>
                        </div>
                        <div className="form-group col-xl-2 col-lg-2 col-md-3">
                            <label for="pincode" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Pincode</label>
                            <input type="text" ref={this.pincodeInput} name='pincode' onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" value={this.state.pincode} id="pincode">
                            </input>
                            <span className='error-message'>{this.state.errorMap.pincode.valid == true ? '' : this.state.errorMap.pincode.error}</span>
                        </div>
                        <div className="col-xl-2">
                        <MDBBtn size='sm' onClick={this.saveData} className="addresssavebutton">
                                <span className="Savedata">
                                    Add New
                                </span>

                            </MDBBtn>
                        </div>
                        <div className="col-xl-2">
                        <MDBBtn size='sm' onClick={this.clearData} className="addresscancelbutton">
                                <span className="Savedata" >
                                    Cancel
                                </span>
                            </MDBBtn>
                        </div>
                    </div>
                    <MDBRow className="justify-content-left ml-0 mx-auto">
                        {/* <MDBCol sm='2' className="userspace">
                            <MDBInput type='text' value={this.state.addressId} name='addressId' onChange={this.setInputVar} label="Address ID" size="sm">
                            </MDBInput>
                        </MDBCol> */}
                    </MDBRow>
                    <div className="row addressinfo ">
                        
                        <div className="col-sm-2">
                        
                        </div>
                    </div>
                   
                    <MDBRow className="">
                        <MDBCol lg='12'>
                            <ReactTable
                                resizable={false}
                                minRows={1}
                                noDataText="NO DATA FOUND"
                                showPagination={true}
                                defaultPageSize={25}
                                PaginationComponent={Pagination}
                                style={{
                                    // This will force the table body to overflow and scroll, since there is not enough room
                                }}
                                data={this.state.addressInfoData}
                                columns={columns} />
                        </MDBCol>
                    </MDBRow>
                    <APIResponse
                        isOpen={this.state.apiResponseModalOpen}
                        response={this.state.apiResponse}
                        msg={this.state.apiResponseMsg}
                        closeAPIResponse={this.closeAPIResponse}
                    />
                </MDBCardBody>
            </MDBCard>
        </div>)
    }
}
export default AddressInfoCardSection