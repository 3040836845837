import React, { Component } from "react";
import { MDBRow, MDBInput, MDBCard, MDBTabContent, MDBTabPane, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCardBody, MDBBreadcrumbItem, MDBCol } from 'mdbreact';
import ApiUtils from '../../common/api_utils';
import APIResponse from '../../common/APIResponse'
import HttpAPI from '../../common/api_calls';
import PersonalUserAdd from '../sections/PersonalUserAdd'
import CustomBreadCrumb from '../sections/CustomBreadCrumb'
import Select from 'react-select';
class AddUserModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: this.props.isOpen,
            userRoleName: '',
            firstName: '',
            lastName: '',
            mobileNumber: '',
            email: '',
            address1: '',
            address2: '',
            stateName: '',
            cityName: '',
            userName: '',
            password: '',
            citydata: [],
            stateData: [],
            UMdata: [],
            UserData: [],
            filtercity: [],
            stateId: 0,
            id: 0,
            cityId: 0,
            userRoleId: 0,
            umId: 0,
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            selecteduserRole:'',
            usermodelOption: [{ id: 0, name: 'userRole', label: "", value: "" }],
            selectedstate:'',
            stateOption: [{ id: 0, name: 'state', label: "", value: "" }],
            selectedcity:'',
            cityOption: [{ id: 0, name: 'city', label: "", value: "" }],

        }

    }
    componentDidMount() {
        this.renderDataFromServer();
    }
    renderDataFromServer() {

        HttpAPI.getUMRole().then(
            response => {
                var response = response.data;
                this.setState({
                    UserData: response.data
                })
                var arr=[]
                    arr.push({id:0,name:'userRole',label:'',value:''})
                    response.data.map((item,key)=>
                    arr.push({
                        id: item.id, name: 'userRole',
                        label: item.userRole ,
                        value: item.userRole
                    })
                    
                    );
                    this.setState({ usermodelOption: arr })
            }
        );

        HttpAPI.getState().then(
            response => {
                var response = response.data;
                this.setState({
                    stateData: response.data
                })
                var arr=[]
                arr.push({id:0,name:'state',label:'',value:''})
                response.data.map((item,key)=>
                arr.push({
                    id: item.id, name: 'state',
                    label: item.stateName ,
                    value: item.stateName
                })
                
                );
                this.setState({ stateOption: arr })
            }
        );
        HttpAPI.getCity().then(
            response => {
                var response = response.data;
                this.setState({
                    citydata: response.data
                })
            }
        );
       let id=window.localStorage.getItem('editUserId')
       if(id>0){
        HttpAPI.getUMById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState({ editUserdata: response.data })
                    this.setState({ umId: response.data.id })
                   this.setEditData(response.data)
                }
            }
        );
       }
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }


    changeSelectVar = (e) => {
        this.setState({
            [e.name + 'Id']: e.id,
            [e.name + 'Name']: e.value,
            ['selected'+e.name  ]: e,
        });
    }
    changeSelectState = (e) => {
        var filterId = Number(e.id);
        const data = this.state.citydata.filter(row => row.stateId === filterId);
        var arr=[]
        arr.push({id:0,name:'city',label:'',value:''})
        data.map((item,key)=>
        arr.push({
            id: item.id, name: 'city',
            label: item.cityName ,
            value: item.cityName
        })
        );
        this.setState({
            cityOption: arr, 
            selectedcity:'',
        });
        this.changeSelectVar(e);
    }
    setInputVar = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        });



    }
    validateData = () => {
        if (this.state.userRoleName === '' || this.state.userRoleName==='Select Role') {
            this.setState({ apiResponseMsg:'Please Fill User Roll Name' })
            return false;
        }
        
        if (this.state.firstName === '') {
            this.setState({ apiResponseMsg: 'Please Fill First Name' })
            return false;
        }
        if (this.state.lastName === '') {
            this.setState({ apiResponseMsg: 'Please Fill Last Name' })
            return false;
        }
        if (this.state.mobileNumber === '') {
            this.setState({ apiResponseMsg: 'Please Fill Mobile Number' })
            return false;
        }
        if (this.state.email === '') {
            this.setState({ apiResponseMsg: 'Please Fill Email Adress' })
            return false;
        }
        if (this.state.address1 === '') {
            this.setState({ apiResponseMsg: 'Please Fill  Adress 1' })
            return false;
        }
        if (this.state.address2 === '') {
            this.setState({ apiResponseMsg: 'Please Fill  Adress 2' })
            return false;
        }
        

        if (this.state.stateName === '' || this.state.stateName==='Select state') {
            this.setState({ apiResponseMsg: 'Please select state' })
            return false;
        }
        if (this.state.cityName === '' || this.state.cityName==='Select city') {
            this.setState({ apiResponseMsg: 'Please select city' })
            return false;
        }
        
       
        if(this.state.userName===''){
            this.setState({ apiResponseMsg: 'Please fill User Name' })
            return false;
        }

        if(this.state.password===''){
            this.setState({ apiResponseMsg: 'Please fill password' })
            return false;
        }
        
        return true;
    }
    createUM = () => {
        let data = {
            'userRoleId': this.state.userRoleId,
            'firstName': this.state.firstName,
            'lastName': this.state.lastName,
            'mobileNumber': this.state.mobileNumber,
            'email': this.state.email,
            'address1': this.state.address1,
            'address2': this.state.address2,
            'stateId': this.state.stateId,
            'cityId': this.state.cityId,
            'userName': this.state.userName,
            'password': this.state.password
        }


        let senddata = ApiUtils.appendCreateRequiredField(data);
        HttpAPI.createUM(senddata).then(
            response => {
                var response = response.data;
                if (response.status === true) {

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'User Insert Successfully',
                        apiResponseModalOpen: true
                    })
                    
                    this.closeAddForm();
                   
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)
            }
        );
    }
    UpdateUM = () => {
        let data = {
            'id': this.state.umId,
            'userRoleId': this.state.userRoleId,
            'firstName': this.state.firstName,
            'lastName': this.state.lastName,
            'mobileNumber': this.state.mobileNumber,
            'email': this.state.email,
            'address1': this.state.address1,
            'address2': this.state.address2,
            'stateId': this.state.stateId,
            'cityId': this.state.cityId,
            'userName': this.state.userName,
            'password': this.state.password,
            'active': this.state.editUserdata.active,
            'createdAt': this.state.editUserdata.createdAt,
            'createdBy': this.state.editUserdata.createdBy,
        }


        let senddata = ApiUtils.appendUpdateRequiredField(data);
        HttpAPI.UpdateUM(senddata).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'User Update Successfully',
                        apiResponseModalOpen: true
                    })
                    
                    this.closeAddForm()

                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.closeAPIResponse();
                }, 1000)
            }
        );
    }
    setEditData = (editUserdata) => {

        this.setState({
            userRoleId: editUserdata.userRoleId,
            userRoleName: editUserdata.userRoleMST.userRole,
            firstName: editUserdata.firstName,
            lastName: editUserdata.lastName,
            mobileNumber: editUserdata.mobileNumber,
            email: editUserdata.email,
            address1: editUserdata.address1,
            address2: editUserdata.address2,
            stateId: editUserdata.stateId,
            stateName: editUserdata.stateMST.stateName,
            cityId: editUserdata.cityId,
            cityName: editUserdata.cityMST.cityName,
            userName: editUserdata.userName,
            password: editUserdata.password,
        })
    }
    saveData = () => {
        if(this.validateData()===true){   
        if (this.state.umId === 0) {
            this.createUM();
        } else {
            this.UpdateUM();
        }
    }else{
        this.setState({
            apiResponse: false,
            apiResponseModalOpen: true
        })
        
    }
    }
    
    clearData = () => {
        window.localStorage.setItem('editUserId',0)
        this.setState({
            userRole: '',
            firstName: '',
            lastName: '',
            mobileNumber: '',
            email: '',
            address1: '',
            address2: '',
            stateId: '',
            stateName: '',
            cityId: '',
            cityName: '',
            userName: '',
            password: '',
            umId: 0,
            selecteduserRole:'',
            usermodelOption: [{ id: 0, name: 'userRole', label: "", value: "" }],
            selectedstate:'',
            stateOption: [{ id: 0, name: 'state', label: "", value: "" }],
            selectedcity:'',
            cityOption: [{ id: 0, name: 'city', label: "", value: "" }],

        })
    }
    closeAddForm = () => {
        this.clearData();
        
    }

    render() {
        return (<div>
             <MDBRow >
            
                <div className="col-sm-12 d-flex flex-row-reverse">
                <CustomBreadCrumb items={['User Profile', 'User Master', 'Add User']} linkTo={['', '/usermanagment', '']} />
                </div>
                    <MDBCol lg="12">
                                
                                <MDBCard className="mb-5">
                                    <MDBCardBody className="align-items-center justify-content-between">
                                        <div className="row">
                                            <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Select Role</label>
                                            <Select name="userRole" options={this.state.usermodelOption}  value={this.state.selecteduserRole}  id="select" onChange={this.changeSelectVar} class="browser-default md-select form-control">
                                                        <option value="select role">Select Role</option>

                                                     {/*   {
                                                            this.state.UserData.map(item => {
                                                                return <option id={item.id} value={item.userRole}>{item.userRole}</option>
                                                            })
                                                        } */}
                                                    </Select>
                                            </div>
                                            <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="FirstName" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > First Name</label>
                                            <input type="text" name="firstName" value={this.state.firstName} id="FirstName" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                            </input>
                                            </div>
                                            <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="lastname" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Last Name</label>
                                            <input type="text" name="lastName" value={this.state.lastName} id="lastname" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                            </input>
                                            </div>
                                            <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="username" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > User Name</label>
                                            <input type="text" name="userName" value={this.state.userName} id="username" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                            </input>
                                            </div>
                                            <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="password" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Password</label>
                                            <input type="password" name="password" value={this.state.password} id="password" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                            </input>
                                            </div>
                                            <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="email" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Email</label>
                                            <input type="text" name="email" value={this.state.email} id="email" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                            </input>
                                            </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                                <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Select State</label>
                                                <Select name="state" options={this.state.stateOption} value={this.state.selectedstate} onChange={this.changeSelectState} id="select" class="browser-default md-select form-control">
                                                        <option value="select state">Select State</option>
                                                     {/*   {
                                                            this.state.stateData.map(item => {
                                                                return <option id={item.id} value={item.stateName}>{item.stateName}</option>
                                                            })
                                                        } */}

                                                    </Select>
                                                </div>
                                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                                <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Select City</label>
                                                <Select name="city" options={this.state.cityOption}  value={this.state.selectedcity} onChange={this.changeSelectVar} id="select" class="browser-default md-select form-control">
                                                        <option value="select city">Select City</option>
                                                     {/*   {
                                                            this.state.filtercity.map(item => {
                                                                return <option id={item.id} value={item.cityName}>{item.cityName}</option>
                                                            })
                                                        } */}
                                                    </Select>
                                                </div>
                                             <div className="form-group col-xl-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="Address1" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Address 1</label>
                                            <input type="text" name="address1" value={this.state.address1} id="Address1" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                            </input>
                                            </div>
                                            <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="Address2" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Address 2</label>
                                            <input type="text" name="address2" value={this.state.address2} id="Address2" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                            </input>
                                            </div>
                                            <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="mobile" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Mobile</label>
                                            <input type="number" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" name="mobileNumber" value={this.state.mobileNumber} id="mobile" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                            </input>
                                            </div>
                                            </div>
                                            
                                        
                                       
                                        
                                        
                                        <MDBRow className=" SaveCancelbutton">
                                            
                                           
                                            <MDBCol xl='6' lg='6' md='6'sm='12' xs='12'>
                                                <MDBBtn className="mt-4 addusersavedata" size='sm' onClick={this.saveData} >
                                                    <span className="Savedata">
                                                    Add New
                                                    </span>
                                                   
                                        </MDBBtn>

                                                <MDBBtn className="mt-4 addusercanceldata" size='sm' onClick={this.closeAddForm}>
                                                    <span className="Savedata">
                                                    Cancel
                                                    </span>
                                                   
                                         </MDBBtn>
                                            </MDBCol>
                                        </MDBRow>
                                        
                                            
                                            <APIResponse
                                                isOpen={this.state.apiResponseModalOpen}
                                                response={this.state.apiResponse}
                                                msg={this.state.apiResponseMsg}
                                                closeAPIResponse={this.closeAPIResponse}
                                            />
                                       
                                    </MDBCardBody>
                                </MDBCard>
                                </MDBCol>
                                </MDBRow>
            </div>
        )
    }
}
export default AddUserModal;