import React, { Component } from "react";
import { MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn } from "mdbreact";
import * as moment from 'moment';
class PurchaseDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            record: this.props.purchaseRecord,
        }
    }
    componentWillReceiveProps(newProps) {
        this.setState({
            openModal: newProps.isOpen,
            record: newProps.purchaseRecord,
        })
    }
    closeModal = () => {
        this.setState({
            openModal: false
        });

    }
    render() {
        return (<div>
            <MDBModal isOpen={this.state.openModal} size='lg'>
                <MDBModalHeader >Purchase Detail</MDBModalHeader>
                <MDBModalBody>
                    <MDBRow style={{ backgroundColor: 'lightgrey' }} className="font-small m-0 p-0 block-example border border-light rounded">
                        <MDBCol sm='12'>
                            <span> Purchase Details </span>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="justify-content-left ml-0 mx-auto ">
                        <MDBCol lg='3' >
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold'>Purchase No :- </label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small lead'>{this.state.record.purchaseNumber}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3' >
                            <MDBRow className="purchasedetailmargin ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Purchase Date  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small purchasedetailmarginlabe'>{moment(this.state.record.purchaseDate).format('DD/MM/YYYY')}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailmarginfor ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold'>Purchase For  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small purchasedetailmarginforscrap'>{this.state.record.purchaseFor}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className=" purchasedetailcompany ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Company  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small purchasedetailfor'>{this.state.record.companyMST == null ? '' : this.state.record.companyMST.description}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="justify-content-left ml-0 mx-auto">
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small font-weight-bold  '>Model  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small  '>{this.state.record.modelMST == null ? '' : this.state.record.modelMST.description}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0 '>
                                <label className='small font-weight-bold '>Model Year  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small purchasedateymodelyearlabel'>{this.state.record.modelYear}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className=" ml-1">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small font-weight-bold  '>Vendor/Dealer  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small  purchasevendor'>{this.state.record.contactMST == null ? '' : this.state.record.contactMST.firstName}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchaseAmout ml-2">
                                <MDBCol size='3' lg='9' className='m-0 p-0'>
                                <label className='small font-weight-bold  '>Purchase Amount  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small purchaseamountmargin'>{this.state.record.purchaseAmount}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow  style={{ backgroundColor: 'lightgrey' }} className=" font-small m-0 p-0 block-example border border-light rounded">
                        <MDBCol sm='12'>
                            <span> Bike Details </span>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="justify-content-left ml-0 mx-auto ">
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small font-weight-bold '>Bike No  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.bikeNumber}</label>

                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small font-weight-bold '>Engine No  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.engineNumber}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                <label className='small font-weight-bold '>Chasis No  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.chasisNumber}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 " > 
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                <label className='small  font-weight-bold'>RTO Detail  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.rtoDetail}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="justify-content-left ml-0 mx-auto">
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small  font-weight-bold'>Parsing Date  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{moment(this.state.record.parsingDate).format('DD/MM/YYYY')}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                <label className='small font-weight-bold '>RTO Date  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small '>{moment(this.state.record.rtoDate).format('DD/MM/YYYY')}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow  className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                <label className='small font-weight-bold'>RC Book Received</label>
                            </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.rcBookReceived === true ? 'Yes' : 'No'}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow  className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                <label className='small font-weight-bold'>Is Financed</label>
                            </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.financed === true ? 'Yes' : 'No'}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="justify-content-left ml-0 mx-auto">
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small font-weight-bold'>All Doc Received</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.allDocsReceived === null ? 'No' : this.state.record.allDocsReceived}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                <label className='small font-weight-bold '>Is ID Proof Received</label>
                            </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <label className='small ml-3'>{this.state.record.idProofReceived === true ? 'Yes' : 'No'}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>

                    </MDBRow>
                    <MDBRow className="justify-content-left ml-0 mx-auto">
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-0 mx-auto ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                <label className='small  font-weight-bold '>{this.state.record.contactMST == null ? '' : this.state.record.contactMST.idType}</label>
                            </MDBCol>
                                <MDBCol size='3' lg='4' className='m-0 p-0'>
                                    <img src={this.state.record.contactMST == null ? '' : this.state.record.contactMST.idImage} alt="thumbnail" className="img-thumbnail" />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                <label className='small font-weight-bold'>{this.state.record.contactMST == null ? '' : this.state.record.contactMST.addressProof}</label>
                            </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <img src={this.state.record.contactMST == null ? '' : this.state.record.contactMST.addressProofImage} alt="thumbnail" className="img-thumbnail" />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                <label className='small font-weight-bold'>Photo</label>
                            </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <img src={this.state.record.contactMST == null ? '' : this.state.record.contactMST.photo} alt="thumbnail" className="img-thumbnail" />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-2 ">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                <label className='small font-weight-bold'>Sign</label>
                            </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <img src={this.state.record.contactMST == null ? '' : this.state.record.contactMST.sign} alt="thumbnail" className="img-thumbnail" />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="secondary" onClick={this.closeModal} >Close</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </div>)
    }
}
export default PurchaseDetail