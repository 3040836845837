import React, { Component } from "react";
import { MDBContainer, MDBFormInline, MDBModalBody, MDBModal, MDBInput, MDBCol, MDBRow, MDBCard, MDBCardBody, MDBBreadcrumb, MDBBreadcrumbItem, MDBBtn, MDBIcon } from "mdbreact";
import DatePicker from "react-datepicker";
import APIResponse from '../common/APIResponse'
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import Pagination from "../common/Pagination";
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'
import Select from 'react-select';
import * as moment from 'moment';
class MiscPage extends Component{
    constructor(props) {
        super(props);
        this.state = {
        
        }
    }
    componentDidMount() {
    }
    renderDataFromServer() {
    }
    render() {
    
        return(<div>
            <MDBRow className="justify-content-left ml-0" >
                <MDBCol sm="3">

                </MDBCol>

                <MDBCol sm="3">

                </MDBCol>
                <div className="CustomBreadCrumbaddvoucher">
                    <CustomBreadCrumb items={['Account', 'Miscellaneous', 'Add Expense']} linkTo={['', '/misc', '']} />
                </div>
            </MDBRow>
            <MDBRow className="justify-content-left ml-0 ">
                <MDBCol lg="12">
                    <MDBCard className="mb-5">
                        <MDBCardBody className="align-items-center justify-content-between">

                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </div>);
    }

}
export default MiscPage