import React, { Component } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBModal,
  MDBModalBody,
  MDBModalFooter
} from 'mdbreact';
import SectionContainer from '../common/sectionContainer';
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import APIResponse from '../common/APIResponse'
class Login extends Component {
  constructor(props) {

    super(props);
    this.state = {
      isLoggedIn: window.localStorage.getItem('isAuthUser') === null ? false : window.localStorage.getItem('isAuthUser'),
      userName: '',
      password: '',
      apiResponse: false,
      apiResponseModalOpen: false,
      apiResponseMsg: ''
    }
  }
  componentDidMount(){
  }
  setInputVar = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  closeAPIResponse=()=>{
    this.setState({
        apiResponse:false,
        apiResponseMsg:'',
        apiResponseModalOpen:false
    })
  }
  validateData() {
    console.log('username' +this.state.userName)
    console.log('username' +this.state.password)
    if(this.state.userName===''){
      return false;
    }else if(this.state.password===''){
      return false;
    }
    return true;
  }
  handleLogin = event => {
   if(this.validateData()===true){ 
    let data = {
      'userName': this.state.userName,
      'password': this.state.password,
    };
    HttpAPI.getLogin(data).then(
      response => {
        if (typeof response != 'undefined') {
          var response = response.data;
          if (response.status === true) {
            window.localStorage.setItem('loggedInUser', this.state.userName);
            window.localStorage.setItem('isAuthUser', true)
            window.location.href = '/dashboard'
          } else {
            this.setState({
              apiResponse: true,
              apiResponseMsg: 'Please enter valid username and password.',
              apiResponseModalOpen: true
            })
            window.setTimeout(() => {
              this.closeAPIResponse();
            }, 1000)
          }
        } else {
          this.setState({
            apiResponse: true,
            apiResponseMsg: 'Please enter valid username and password.',
            apiResponseModalOpen: true
          })
          window.setTimeout(() => {
            this.closeAPIResponse();
          }, 2000)
        }
      });
    }else{
      this.setState({
        apiResponse: true,
        apiResponseMsg: 'Please fill username and password.',
        apiResponseModalOpen: true
      })
      window.setTimeout(() => {
        this.closeAPIResponse();
      }, 2000)

    }
    //alert(`MDBInput value:`);
    event.preventDefault();
  };
  render() {

    return (
      <MDBContainer className='mt-5'>
        <MDBRow>
          <MDBCol lg="4"></MDBCol>
          <MDBCol lg="4">
            <SectionContainer>
              <form>
                <p className='h5 text-center mb-4'>Sign in</p>
                <div className='grey-text'>
                  <MDBInput
                    label='Type your username'
                    icon='user'
                    group
                    type='email'
                    validate
                    error='wrong'
                    success='right'
                    name='userName'
                    onChange={this.setInputVar}
                  />
                  <MDBInput
                    label='Type your password'
                    icon='lock'
                    group
                    type='password'
                    validate
                    name='password'
                    onChange={this.setInputVar}
                  />
                </div>
                <div className='text-center'>
                  <MDBBtn onClick={this.handleLogin}>Login</MDBBtn>
                </div>
              </form>
            </SectionContainer>
          </MDBCol>
          <MDBCol lg="4"></MDBCol>
        </MDBRow>
        <APIResponse
          isOpen={this.state.apiResponseModalOpen}
          response={this.state.apiResponse}
          msg={this.state.apiResponseMsg}
          closeAPIResponse={this.closeAPIResponse}
        />
      </MDBContainer>
    );
  }
}

export default Login;