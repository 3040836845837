import React, { Component } from "react";
import { MDBCol, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBIcon } from 'mdbreact';
import AddUserModal from './modal/AddUserModal'
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import APIResponse from '../common/APIResponse'
import moment from 'moment';
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'
import Pagination from "../common/Pagination";
import "./Styleing.css";
class UserMasterPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserData: [],
            filterUserData:[],
            activeItem: "1",
            openModal: false,
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            citydata: [],
            stateData: [],
            selectedusermaster: {},
            userRole: ''
        };

    }

    componentDidMount() {
        this.renderDataFromServer();
    }
    renderDataFromServer() {
        window.localStorage.setItem('editUserId',0)
        HttpAPI.getUM().then(
            response => {
                var response = response.data;
                this.setState({
                    UserData: response.data,
                    filterUserData:response.data
                })
            }
        );
        HttpAPI.getState().then(
            response => {
                var response = response.data;
                this.setState({
                    stateData: response.data
                })
            }
        );
        HttpAPI.getCity().then(
            response => {
                var response = response.data;
                this.setState({
                    citydata: response.data
                })
            }
        );
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    globalSearch = (searchInput) => {
        let filteredData = this.state.UserData.filter(value => {
            return (
                (value.userRoleMST!==null && value.userRoleMST.userRole.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.userName!==null && value.userName.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.password!==null && value.password.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.createdAt!==null && moment(value.createdAt).format('DD/MM/YYYY').toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.createdBy!==null && value.createdBy.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.updatedAt!==null && moment(value.updatedAt).format('DD/MM/YYYY').toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.updatedBy!==null && value.updatedBy.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.active!==null && (!value.active).toString().toLowerCase().includes(searchInput.toLowerCase())) 
                );
            });
            this.setState({ filterUserData: filteredData }); 
    }
    deleteRowById(id) {
        HttpAPI.deleteUMById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'Model Delete Successfully',
                        apiResponseModalOpen: true
                    })

                    this.renderDataFromServer();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.closeAPIResponse();
                }, 1000)


            }
        );
    }
    getRowById(id) {
        window.localStorage.setItem('editUserId',id)
        this.openAddContact()
        
    }
    deleteRowById(id) {
        HttpAPI.deleteUMById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'User Delete Successfully',
                        apiResponseModalOpen: true
                    })

                    this.renderDataFromServer();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.closeAPIResponse();
                }, 1000)


            }
        );
    }
    toggle = tab => e => {
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab
            });
        }
    };
    openAddContact = () => {
        //this.setState({ openModal: true })
        window.location.href = '/addUser'
    }
    closeAddUser = () => {
        this.setState({ selectedusermaster: {} })
        this.setState({ openModal: false })
        this.renderDataFromServer();
    }
    render() {
        const columns = [
            {
                id: "1",
                Header:()=>(
                    <div className="bgcolor">
                            User id Type
                    </div>
                ) ,
                accessor: "id",
                style: {
                    height:35,
                    textAlign: 'Center',

                },
            },
            {
                id: "2",
                Header:()=>(
                    <div className="bgcolor">
                        
                        User Role
                       
                    </div>
                ) ,
                accessor: "userRoleMST.userRole",
                style: {
                    height:35,
                    textAlign: 'Center',

                },
            },
            {
                id: "3",
                Header:()=>(
                    <div className="bgcolor">
                        User Name
                    </div>
                ),
                accessor: "userName",
                style: {
                    height:35,
                    textAlign: 'Center',

                },
            },
            {
                id: "4",
                Header:()=>(
                    <div className="bgcolor">
                            password
                    </div>
                ),
                accessor: "password",
                style: {
                    height:35,
                    textAlign: 'Center',

                },
            },
            {
                id: "5",
                Header:()=>(
                    <div className="bgcolor">
                            CreateDt
                    </div>
                ),
                accessor: d => moment(d.createdAt).format('DD/MM/YYYY'),
                style: {
                    height:35,
                    textAlign: 'Center',

                },
            },
            {
                id: "6",
                Header:()=>(
                    <div className="bgcolor"> 
                         CreatedBy
                    </div>
                ),
                accessor: "createdBy",
                style: {
                    height:35,
                    textAlign: 'Center',

                },
            },
            {
                id: "7",
                Header:()=>(
                    <div className="bgcolor">
                            UpdateDt
                    </div>
                ),
                accessor: d => moment(d.updatedAt).format('DD/MM/YYYY'),
                style: {
                    height:35,
                    textAlign: 'Center',

                },
            },
            {
                id: "8",
                Header:()=>(
                    <div className="bgcolor">
                         UpdatedBy
                    </div>
                ),
                accessor: "updatedBy",
                style: {
                    height:35,
                    textAlign: 'Center',

                },
            },
            {
                id: "9",
                Header:()=>(
                    <div className="bgcolor">
                            isDeleted
                    </div>
                ),
                accessor: d=>(!d.active).toString(),
                style: {
                    height:35,
                    textAlign: 'Center',

                },
            },
            {
                id: "10",
                Header:()=>(
                    <div className="bgcolor">
                            Actions
                    </div>
                ),
                accessor: "id",
                sortable: false,
                style: {
                    height:35,
                    textAlign: 'Center',

                },
                Cell: props => {

                    return (

                        <div>
                            <MDBIcon className=" " icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.getRowById(props.value)} />
                            <MDBIcon className="pl-3" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.value)} />
                        </div>

                    );
                }
            }
        ]
        return (
            <React.Fragment>
                <MDBRow>
                   
                    <div className="col-sm-12 d-flex flex-row-reverse">
                    <CustomBreadCrumb items={['User', 'User Master']} linkTo={['', '']} />
                    </div>
                    
                    <MDBCol md="12">
                        
                        <MDBCard className="mb-6 mr-0 ">
                            <MDBCardBody className="align-items-center justify-content-between mr-0">
                            
                            <div className="row ml-0 searchtopmargin">
                            <div className="input-group md-form form-sm form-2 pl-0 col-xl-11 col-lg-10 col-md-10 col-sm-12">
                                <input className="form-control my-0 py-0  lime-border" type="text"  onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                <div className="input-group-append">
                                <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                aria-hidden="true"></i></span>
                                </div>
                               </div>
                               <div className="form-group col-xl-1 col-lg-2 col-md-2 col-sm-12 ">
                               <MDBBtn size='md' className="Usercontactsavebutton" onClick={this.openAddContact}>
                                    <span className="Savedata">
                                         Add New
                                    </span>
                                </MDBBtn>
                               </div>
                               </div> 
                            
                            
                            
                            
                                <div className=" justify-content-left districttable mx-auto">
                                <ReactTable
                                    resizable={false}
                                    minRows={1}
                                    showPagination={true}
                                    defaultPageSize={25}
                                    noDataText="NO DATA FOUND"
                                    PaginationComponent={Pagination}
                                    style={{
                                        // This will force the table body to overflow and scroll, since there is not enough room
                                    }}
                                    data={this.state.filterUserData}
                                    columns={columns} />
                                </div> 
                                
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    
                    <APIResponse
                        isOpen={this.state.apiResponseModalOpen}
                        response={this.state.apiResponse}
                        msg={this.state.apiResponseMsg}
                        closeAPIResponse={this.closeAPIResponse}
                    />
                </MDBRow>
            </React.Fragment>
        );
    }
}
export default UserMasterPage