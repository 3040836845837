import React, { Component } from "react";
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import { MDBCol, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBIcon } from 'mdbreact';
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import APIResponse from '../common/APIResponse'
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'
import Select from 'react-select';
import './Styleing.css';

import Pagination from "../common/Pagination";

class CityPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            citydata: [],
            filtercitydata:[],
            cityColumns: [],
            stateData: [],
            countryData: [],
            districtData: [],
            filterState: [],
            filterDistrict: [],
            countryId: 0,
            countryName: '',
            stateId: 0,
            stateName: "",
            districtId: 0,
            districtName: "",
            talukaId: 0,
            talukdName: "",
            cityName: "",
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            id: 0,
            cityId: 0,
            selectedcountry:'',
            selectedstate:'',
            selecteddistrict:'',
            countryOption: [{ id: 0, name: 'country', label: "", value: "" }],
            stateOption: [{ id: 0, name: 'state', label: "", value: "" }],
            districtOption: [{ id: 0, name: 'district', label: "", value: "" }],
            errorMap: {
                'countryName': { 'valid': true, 'error': '' },
                'stateName': { 'valid': true, 'error': '' },
                'districtName': { 'valid': true, 'error': '' },
                'cityName': { 'valid': true, 'error': '' },
            }

        }
        this.countryInput = React.createRef();
        this.stateInput = React.createRef();
        this.districtInput = React.createRef();
        this.cityInput = React.createRef();
    }
    componentDidMount() {
        this.renderDataFromServer();
    }

    changeSelectVar = (e) => {
        this.setState({
            [e.name + 'Id']: e.id,
            [e.name + 'Name']: e.value,
            ['selected'+e.name  ]: e,
        });
    }
    changeCountryVar = (e) => {
        var filterId = Number(e.id);
        const data = this.state.stateData.filter(row => row.countryId === filterId);
        var arr=[]
        arr.push({id:0,name:'state',label:'',value:''})
        data.map((item,key)=>
        arr.push({
            id: item.id, name: 'state',
            label: item.stateName ,
            value: item.stateName
        })
        );
        this.setState({ 
            stateOption: arr, 
            selectedstate:'',
            selecteddistrict:'',
            districtOption:[],
        })
        this.changeSelectVar(e);
    }
    changeStateVar = (e) => {
        var filterId = Number(e.id);
        const districtdata = this.state.districtData.filter(row => row.stateId === filterId);
        var arr=[]
        arr.push({id:0,name:'district',label:'',value:''})
        districtdata.map((item,key)=>
        arr.push({
            id: item.id, name: 'district',
            label: item.districtName ,
            value: item.districtName
        })
        );
        this.setState({ 
            districtOption: arr, 
            selecteddistrict:'',
        })
        this.changeSelectVar(e);
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    globalSearch = (searchInput) => {
        let filteredData = this.state.citydata.filter(value => {
            return (
                (value.cityName!==null && value.cityName.toLowerCase().includes(searchInput.toLowerCase()))
                );
            });
            this.setState({ filtercitydata: filteredData });
        
    }
    renderDataFromServer() {
        HttpAPI.getCity().then(
            response => {
                var response = response.data;
                this.setState({ citydata: response.data,filtercitydata:response.data })

            }
        );
        HttpAPI.getCountry().then(
            response => {
                var response = response.data;
                this.setState({ countryData: response.data })
                var arr=[]
                    arr.push({id:0,name:'country',label:'',value:''})
                    response.data.map((item,key)=>
                    arr.push({
                        id: item.id, name: 'country',
                        label: item.countryName ,
                        value: item.countryName
                    })
                    );
                    this.setState({ countryOption: arr })
            }
        );
        HttpAPI.getState().then(
            response => {
                var response = response.data;
                this.setState({ stateData: response.data })

            }
        );
        HttpAPI.getDistrict().then(
            response => {
                var response = response.data;
                this.setState({ districtData: response.data })
                this.populateDefault(response.data)

            }
        );
    }
    populateDefault(districtData){
        this.setState({
            selectedcountry:{
                id: this.state.countryData[0].id, name: 'country',
                label: this.state.countryData[0].countryName ,
                value: this.state.countryData[0].countryName
            },
            countryId:this.state.countryData[0].id,
            countryName:this.state.countryData[0].countryName 

        })
        const data = this.state.stateData.filter(row => row.countryId === this.state.countryData[0].id);
        var arr = []
        arr.push({ id: 0, name: 'state', label: '', value: '' })
        data.map(item => {
            arr.push({ id: item.id, name: 'state', label: item.stateName, value: item.stateName })
        })
        this.setState({
            selectedstate: { id: data[0].id, name: 'state', label: data[0].stateName, value: data[0].stateName },
            stateOption: arr,
            stateId:data[0].id,
            stateName:data[0].stateName
        })
        const districtdata = districtData.filter(row => row.stateId === data[0].id);
        var arr=[]
        arr.push({id:0,name:'district',label:'',value:''})
        districtdata.map((item,key)=>
        arr.push({
            id: item.id, name: 'district',
            label: item.districtName ,
            value: item.districtName
        })
        );
        this.setState({ 
            districtOption: arr, 
            selecteddistrict:{
                id: districtdata[0].id, name: 'district',
                label: districtdata[0].districtName ,
                value: districtdata[0].districtName
            },
            districtId:districtdata[0].id,
            districtName:districtdata[0].districtName
        })
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    deleteRowById(id) {
        HttpAPI.deleteCityById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'City Delete Successfully',
                        apiResponseModalOpen: true
                    })
                    this.clearData();
                    this.renderDataFromServer();

                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.closeAPIResponse();
                }, 1000)

            }
        );
    }
    getRowById(id) {
        HttpAPI.getCityById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState(
                        {
                            cityName: response.data.cityName,
                            countryName: response.data.countryMST.countryName,
                            countryId: response.data.countryId,
                            stateName: response.data.stateMST.stateName,
                            stateId: response.data.stateId,
                            districtName: response.data.districtMST.districtName,
                            districtId: response.data.districtId,
                            selectedcountry:{ id: response.data.countryId, name: 'country', label: response.data.countryMST.countryName, value:response.data.countryMST.countryName },
                            selectedstate:{ id: response.data.stateId, name: 'state', label: response.data.stateMST.stateName, value:response.data.stateMST.stateName },
                            selecteddistrict:{ id: response.data.districtId, name: 'district', label: response.data.districtMST.districtName, value:response.data.districtMST.districtName },
                            updateRow: response.data,
                            id: response.data.id
                        }
                    );
                    const data = this.state.stateData.filter(row => row.countryId === response.data.countryId);
                    this.setState({
                        filterState: data
                    });
                    const districtdata = this.state.districtData.filter(row => row.stateId === response.data.stateId);
                    this.setState({
                        filterDistrict: districtdata
                    });

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: false
                    })

                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)


            }
        );
    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    createCity() {
        let data = {
            'cityName': this.state.cityName,

            'countryId': this.state.countryId,
            'stateId': this.state.stateId,
            'districtId': this.state.districtId,


        };
        let senddata = ApiUtils.appendCreateRequiredField(data);
        HttpAPI.createCity(senddata).then(
            response => {
                var response = response.data;
                if (response.status === true) {

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'City Insert Successfully',
                        apiResponseModalOpen: true
                    })
                    this.clearData();
                    this.renderDataFromServer();

                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)

            }
        );
    }
    UpdateCity() {

        let data = {
            'id': this.state.updateRow.id,
            'cityName': this.state.cityName,

            'countryId': this.state.countryId,
            'stateId': this.state.stateId,
            'districtId': this.state.districtId,
            'active': this.state.updateRow.active,
            'createdAt': this.state.updateRow.createdAt,
            'createdBy': this.state.updateRow.createdBy,
        };
        let senddata = ApiUtils.appendUpdateRequiredField(data);

        HttpAPI.UpdateCity(senddata).then(
            response => {
                var response = response.data;

                if (response.status === true) {
                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'City Update Successfully',
                        apiResponseModalOpen: true
                    })
                    this.clearData();
                    this.renderDataFromServer();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)

            }
        );
    }
    saveData = () => {
        if (this.validateData() === true) {
            if (this.state.id > 0) {
                this.UpdateCity();
            } else {
                this.createCity();
            }
        } 
    }
    clearData = () => {
        this.setState({
            cityName: '',
            countryId: 0,
            countryName: '',
            stateId: 0,
            stateName: '',
            districtId: 0,
            districtName: '',
            errorMap: {
                'countryName': { 'valid': true, 'error': '' },
                'stateName': { 'valid': true, 'error': '' },
                'districtName': { 'valid': true, 'error': '' },
                'cityName': { 'valid': true, 'error': '' },
            }
        })
    }
    validateData() {
        let map = {
            'countryName': { 'valid': true, 'error': '' },
            'stateName': { 'valid': true, 'error': '' },
            'districtName': { 'valid': true, 'error': '' },
            'cityName': { 'valid': true, 'error': '' },
        }
        let isValid = true;
        if (this.state.countryName === '' || this.state.countryName === 'select country') {
            //this.setState({ apiResponseMsg: 'Please select Country' })
            map.countryName.valid = false;
            map.countryName.error = 'Please select Country'
            this.countryInput.current.focus()
            isValid = false
        }
        if (this.state.stateName === '' || this.state.stateName === 'select state') {
            //this.setState({ apiResponseMsg: 'Please select State' })
            map.stateName.valid = false;
            map.stateName.error = 'Please select State'
            if(isValid===true){
                this.stateInput.current.focus()
            }
            isValid = false
        }
        if (this.state.districtName === '' || this.state.districtName === 'select district') {
            //this.setState({ apiResponseMsg: 'Please select District' })
            map.districtName.valid = false;
            map.districtName.error = 'Please select District'
            if(isValid===true){
                this.districtInput.current.focus()
            }
            isValid = false
        }
        if (this.state.cityName === '') {
            //this.setState({ apiResponseMsg: 'Please fill City Name' })
            map.cityName.valid = false;
            map.cityName.error = 'Please fill City Name'
            if(isValid===true){
                this.cityInput.current.focus()
            }
            isValid = false
        }
        this.setState({errorMap:map})
        return isValid;
    }

    render() {
        const columns = [
            {
                id: "1",
                Header: () => (
                    <div className="bgcolor">
                        City Name
                    </div>

                ),
                accessor: "cityName",
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "2",
                Header: () => (
                    <div className="bgcolor">
                        Actions
                    </div>
                ),
                accessor: "id",
                sortable: false,
                style: {
                    height:35,
                    textAlign: 'Center',

                },
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.getRowById(props.value)} />
                                <MDBIcon className=" m-2" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.value)} />
                            </div>
                        </div>
                    );
                }
            }
        ]
        return (
            <React.Fragment>
                <MDBRow>
                   

                    <div className=" col-sm-9 d-flex flex-row-reverse" >
                        <CustomBreadCrumb items={['Master', 'City']} linkTo={['', '']} />
                    </div>
                </MDBRow>
                <MDBRow>
                    <MDBCol lg='3'>

                    </MDBCol>
                    <MDBCol lg="6">

                        <MDBCard className="mb-5 mr-0" >
                            <MDBCardBody className="align-items-center justify-content-between mr-0">
                                <div className="row ">
                                    <div className="form-group col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Select Country</label>
                                        <Select name="country" ref={this.countryInput} options={this.state.countryOption} value={this.state.selectedcountry} id="select" onChange={this.changeCountryVar} class="browser-default md-select form-control">
                                            {/* <option value='select country'>Select </option>
                                            {
                                                this.state.countryData.map(item => {
                                                    return <option id={item.id} value={item.countryName}>{item.countryName}</option>
                                                })
                                            } */}
                                        </Select>
                                        <span className='error-message'>{this.state.errorMap.countryName.valid == true ? '' : this.state.errorMap.countryName.error}</span>
                                    </div>
                                    <div className="form-group col-xl-3  col-lg-3 col-md-3  col-sm-3 col-xs-3 selectstate">
                                        <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Select State</label>
                                        <Select name="state" ref={this.stateInput} value={this.state.selectedstate} options={this.state.stateOption} id="select" onChange={this.changeStateVar} class="browser-default md-select form-control">
                                            {/* <option value='select state'>Select </option>
                                            {
                                                this.state.filterState.map(item => {
                                                    return <option id={item.id} value={item.stateName}>{item.stateName}</option>
                                                })
                                            } */}
                                        </Select>
                                        <span className='error-message'>{this.state.errorMap.stateName.valid == true ? '' : this.state.errorMap.stateName.error}</span>
                                    </div>
                                    <div className="form-group col-xl-3  col-lg-3 col-md-3  col-sm-3 col-xs-3 selectstate">
                                        <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Select District</label>
                                        <Select name="district" ref={this.districtInput} value={this.state.selecteddistrict} options={this.state.districtOption} id="select" onChange={this.changeSelectVar} class="browser-default md-select form-control">
                                            {/* <option value='select district' >Select </option>
                                            {
                                                this.state.filterDistrict.map(item => {
                                                    return <option id={item.id} value={item.districtName}>{item.districtName}</option>
                                                })
                                            } */}
                                        </Select>
                                        <span className='error-message'>{this.state.errorMap.districtName.valid == true ? '' : this.state.errorMap.districtName.error}</span>
                                    </div>
                                    <div className="form-group col-xl-3  col-lg-3 col-md-3  col-sm-3 col-xs-3  selectstate">
                                        <label for="city" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > City Name</label>
                                        <input type="text" name="cityName" ref={this.cityInput} value={this.state.cityName} id="city" className="form-control form-text font-small m-0 p-0 " onChange={this.setInputVar}>
                                        </input>
                                        <span className='error-message'>{this.state.errorMap.cityName.valid == true ? '' : this.state.errorMap.cityName.error}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-3 col-sm-12 col-xs-12">
                                    <MDBBtn size='md' onClick={this.saveData} className="citysavebutton">
                                            <span className="Savedata">
                                                Add New
                                            </span>
                                        </MDBBtn>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-3 col-sm-3 col-xs-3">
                                    <MDBBtn size='md' onClick={this.clearData} className="citysavecancel">
                                            <span className="Savedata">
                                                Cancel
                                            </span>
                                        </MDBBtn>
                                    </div>
                                </div>
                               
                                <div className="input-group md-form form-sm form-2 pl-0 countrysearchicon mt-2">
                                <input className="form-control my-0 py-0  lime-border" type="text"  onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                <div className="input-group-append">
                                <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                aria-hidden="true"></i></span>
                                </div>
                               </div>
                                <MDBRow className="districttable" >
                                    <MDBCol lg="12" className="mb-2">
                                        <ReactTable
                                            resizable={false}
                                            minRows={1}
                                            noDataText="NO DATA FOUND"
                                            showPagination={true}
                                            PaginationComponent={Pagination}
                                            defaultPageSize={5}
                                            style={{
                                                // This will force the table body to overflow and scroll, since there is not enough room
                                            }}
                                            data={this.state.filtercitydata}
                                            columns={columns} />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>


                        <APIResponse
                            isOpen={this.state.apiResponseModalOpen}
                            response={this.state.apiResponse}
                            msg={this.state.apiResponseMsg}
                            closeAPIResponse={this.closeAPIResponse}
                        />

                    </MDBCol>
                    <MDBCol lg="3">
                    </MDBCol>
                </MDBRow>
            </React.Fragment>


        );
    }
}
export default CityPage;