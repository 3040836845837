import React, { Component } from 'react';
import { MDBCard, MDBRow, MDBCardBody, MDBIcon, MDBBreadcrumb, MDBBreadcrumbItem, MDBFormInline, MDBBtn } from 'mdbreact';
class CustomBreadCrumb extends Component {
    constructor(props) {
        super(props);
    }
    getBreadCrumbItem = () => {
        let arr = []
        for (var i = 0; i < this.props.items.length; i++) {
            if (i === this.props.items.length - 1) {
                arr.push(<MDBBreadcrumbItem active>{this.props.items[i]}</MDBBreadcrumbItem>)
            } else {
                if(this.props.linkTo[i]!==''){
                    arr.push(<MDBBreadcrumbItem><a href={this.props.linkTo[i]} >{this.props.items[i]}</a></MDBBreadcrumbItem>)
                }else{
                    arr.push(<MDBBreadcrumbItem>{this.props.items[i]}</MDBBreadcrumbItem>)
                }
                
            }

        }
        return arr;
    }
    render() {


        return (
            <MDBRow className="justify-content-left mb-2 border-dark">
                <MDBBreadcrumb  bold className='font-small '>
                    {this.getBreadCrumbItem()}
                </MDBBreadcrumb>
            </MDBRow>
        );
    }
}
export default CustomBreadCrumb;