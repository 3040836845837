import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import ApiUtils from './api_utils.js';


var HttpAPI = {

  //API_BASE_URL: 'http://localhost:8080/AIMSRestAPI',
  API_BASE_URL: 'https://tomcat.harvices.com/aims-uat/AIMSRestAPI',

    getLogin: function (data) {
        var url = this.API_BASE_URL + '/userMST/login';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },createCountry: function (data) {
        var url = this.API_BASE_URL + '/countryMST/create';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },UpdateCountry: function (data) {
        var url = this.API_BASE_URL + '/countryMST/update';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .put(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,createState: function (data) {
        var url = this.API_BASE_URL + '/stateMST/create';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },UpdateState: function (data) {
        var url = this.API_BASE_URL + '/stateMST/update';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .put(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,createDistrict: function (data) {
        var url = this.API_BASE_URL + '/districtMST/create';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },UpdateDistrict: function (data) {
        var url = this.API_BASE_URL + '/districtMST/update';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .put(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,createCity: function (data) {
        var url = this.API_BASE_URL + '/cityMST/create';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },UpdateCity: function (data) {
        var url = this.API_BASE_URL + '/cityMST/update';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .put(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,createTaluka: function (data) {
        var url = this.API_BASE_URL + '/talukaMST/create';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },UpdateTaluka: function (data) {
        var url = this.API_BASE_URL + '/talukaMST/update';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .put(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,createCompany: function (data) {
        var url = this.API_BASE_URL + '/companyMST/create';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },
      UpdateCompany: function (data) {
        var url = this.API_BASE_URL + '/companyMST/update';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .put(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,createModel: function (data) {
        var url = this.API_BASE_URL + '/modelMST/create';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },UpdateModel: function (data) {
        var url = this.API_BASE_URL + '/modelMST/update';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .put(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,createVehicleCategory: function (data) {
        var url = this.API_BASE_URL + '/modelCategotyMST/create';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },
      UpdateVehicleCategory: function (data) {
        var url = this.API_BASE_URL + '/modelCategotyMST/update';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .put(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      
      ,createContact: function (data) {
        var url = this.API_BASE_URL + '/contactMST/create';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },
      createAddress: function (data) {
        var url = this.API_BASE_URL + '/addressMST/createALL';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },createRC: function (data) {
        var url = this.API_BASE_URL + '/rcMST/create';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,UpdateRC: function (data) {
        var url = this.API_BASE_URL + '/rcMST/update';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .put(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      
      ,createUM: function (data) {
        var url = this.API_BASE_URL + '/userMST/create';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,UpdateUM: function (data) {
        var url = this.API_BASE_URL + '/userMST/update';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .put(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,createPurchase: function (data) {
        var url = this.API_BASE_URL + '/purchaseMST/create';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,createSell: function (data) {
        var url = this.API_BASE_URL + '/sellMST/create';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,createPartyVoucher: function (data) {
        var url = this.API_BASE_URL + '/partyVoucher/create';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },
      createReceipt: function (data) {
        var url = this.API_BASE_URL + '/sellReceipt/create';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },
      createExpense: function (data) {
        var url = this.API_BASE_URL + '/miscMST/create';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },
      getPartyVoucherByPurchaseId: function (id) {
        var url = this.API_BASE_URL + '/partyVoucher/purchaseId/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },
      getReceiptBySellId: function (id) {
        var url = this.API_BASE_URL + '/sellReceipt/sellId/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },
      getExpenseById: function (id) {
        var url = this.API_BASE_URL + '/miscMST/id/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getCountry: function () {
        var url = this.API_BASE_URL + '/countryMST/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      
      ,getState: function () {
        var url = this.API_BASE_URL + '/stateMST/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getDistrict: function () {
        var url = this.API_BASE_URL + '/districtMST/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getCity: function () {
        var url = this.API_BASE_URL + '/cityMST/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getTaluka: function () {
        var url = this.API_BASE_URL + '/talukaMST/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getCompany: function () {
        var url = this.API_BASE_URL + '/companyMST/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getModel: function () {
        var url = this.API_BASE_URL + '/modelMST/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getUM: function () {
        var url = this.API_BASE_URL + '/userMST/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getUMRole: function () {
        var url = this.API_BASE_URL + '/userRoleMST/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getVehicleCategory: function () {
        var url = this.API_BASE_URL + '/modelCategotyMST/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getContact: function () {
        var url = this.API_BASE_URL + '/contactMST/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getAddress: function () {
        var url = this.API_BASE_URL + '/addressMST/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },getRc: function () {
        var url = this.API_BASE_URL + '/rcMST/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },getRCPurchase: function () {
        var url = this.API_BASE_URL + '/rcMST/allRCDTO';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getPurchase: function () {
        var url = this.API_BASE_URL + '/purchaseMST/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },getSell: function () {
        var url = this.API_BASE_URL + '/sellMST/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      } 
       ,getNORCPurchase: function () {
        var url = this.API_BASE_URL + '/purchaseMST/getAll';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getPartyVoucher: function () {
        var url = this.API_BASE_URL + '/partyVoucher/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getReceipt: function () {
        var url = this.API_BASE_URL + '/sellReceipt/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getSellData: function () {
        var url = this.API_BASE_URL + '/sellMST/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getExpenseData: function () {
        var url = this.API_BASE_URL + '/miscMST/all';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getCountryById: function (id){
        var url = this.API_BASE_URL + '/countryMST/id/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      
      ,getStateById: function (id){
        var url = this.API_BASE_URL + '/stateMST/id/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getDistrictById: function (id){
        var url = this.API_BASE_URL + '/districtMST/id/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getCityById: function (id){
        var url = this.API_BASE_URL + '/cityMST/id/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      
      ,getTalukaById: function (id){
        var url = this.API_BASE_URL + '/talukaMST/id/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getCompanyById: function (id){
        var url = this.API_BASE_URL + '/companyMST/id/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getModelById: function (id){
        var url = this.API_BASE_URL + '/modelMST/id/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getVehicleCategoryById: function (id) {
        var url = this.API_BASE_URL + '/modelCategotyMST/id/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getContactById: function (id) {
        var url = this.API_BASE_URL + '/contactMST/id/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getAddressById: function (id) {
        var url = this.API_BASE_URL + '/addressMST/id/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getRCById: function (id) {
        var url = this.API_BASE_URL + '/rcMST/id/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getAddressByContactId: function (id) {
        var url = this.API_BASE_URL + '/addressMST/contactId/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getPurchaseById: function (id) {
        var url = this.API_BASE_URL + '/purchaseMST/id/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getPurchaseByRCId: function (id) {
        var url = this.API_BASE_URL + '/purchaseMST/rcId/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getSellById: function (id) {
        var url = this.API_BASE_URL + '/sellMST/id/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getUMById: function (id) {
        var url = this.API_BASE_URL + '/userMST/id/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getPartyVoucherById: function (id) {
        var url = this.API_BASE_URL + '/partyVoucher/id/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getReceiptById: function (id) {
        var url = this.API_BASE_URL + '/sellReceipt/id/'+id;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,deleteCountryById: function (id){
        var url = this.API_BASE_URL + '/countryMST/delete/'+id;
        return axios
          .delete(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,deleteStateById: function (id){
        var url = this.API_BASE_URL + '/stateMST/delete/'+id;
        return axios
          .delete(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      
      ,deleteDistrictById: function (id){
        var url = this.API_BASE_URL + '/districtMST/delete/'+id;
        return axios
          .delete(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,deleteCityById: function (id){
        var url = this.API_BASE_URL + '/cityMST/delete/'+id;
        return axios
          .delete(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      
      ,deleteTalukaById: function (id){
        var url = this.API_BASE_URL + '/talukaMST/delete/'+id;
        return axios
          .delete(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,deleteCompanyById: function (id){
        var url = this.API_BASE_URL + '/companyMST/delete/'+id;
        return axios
          .delete(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,deleteModelById: function (id){
        var url = this.API_BASE_URL + '/modelMST/delete/'+id;
        return axios
          .delete(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      
      ,deleteVehicleCategoryById: function (id) {
        var url = this.API_BASE_URL + '/modelCategotyMST/delete/'+id;
        return axios
          .delete(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,deleteContactById: function (id) {
        var url = this.API_BASE_URL + '/contactMST/delete/'+id;
        return axios
          .delete(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },deleteAddressById: function (id) {
        var url = this.API_BASE_URL + '/addressMST/delete/'+id;
        return axios
          .delete(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,deleteRCById: function (id) {
        var url = this.API_BASE_URL + '/rcMST/delete/'+id;
        return axios
          .delete(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,deleteUMById: function (id) {
        var url = this.API_BASE_URL + '/userMST/delete/'+id;
        return axios
          .delete(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,deletePurchaseById: function (id) {
        var url = this.API_BASE_URL + '/purchaseMST/delete/'+id;
        return axios
          .delete(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,deleteSellById: function (id) {
        var url = this.API_BASE_URL + '/sellMST/delete/'+id;
        return axios
          .delete(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,deletePartyVoucherById: function (id) {
        var url = this.API_BASE_URL + '/partyVoucher/delete/'+id;
        return axios
          .delete(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,deleteReceiptById: function (id) {
        var url = this.API_BASE_URL + '/sellReceipt/delete/'+id;
        return axios
          .delete(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,deleteExpenseById: function (id) {
        var url = this.API_BASE_URL + '/miscMST/delete/'+id;
        return axios
          .delete(url, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,updateContact: function (data) {
        var url = this.API_BASE_URL + '/contactMST/update';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .put(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      },
      updateAddress: function (data) {
        var url = this.API_BASE_URL + '/addressMST/updateALL';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .put(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,updatePurchase: function (data) {
        var url = this.API_BASE_URL + '/purchaseMST/update';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .put(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,updateSell: function (data) {
        var url = this.API_BASE_URL + '/sellMST/update';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .put(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,updatePartyVoucher: function (data) {
        var url = this.API_BASE_URL + '/partyVoucher/update';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .put(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,updateReceipt: function (data) {
        var url = this.API_BASE_URL + '/sellReceipt/update';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .put(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,updateExpense: function (data) {
        var url = this.API_BASE_URL + '/miscMST/update';
        console.log('In getLogin API CALLs: ', data);
        // console.log("In getLogin API CALLs encodedData: ",encodedData);
        return axios
          .put(url, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,uploadExpenseImage: function (file,id) {
        var url = this.API_BASE_URL + '/files/miscMST/photoProof';
        var formData = new FormData();
        formData.append('file', file, id);
        return axios
          .post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,uploadContactIdImage: function (file,id) {
        var url = this.API_BASE_URL + '/files/contactMST/idProof';
        var formData = new FormData();
        formData.append('file', file, id);
        return axios
          .post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,uploadAddressProofImage: function (file,id) {
        var url = this.API_BASE_URL + '/files/contactMST/addressProof';
        var formData = new FormData();
        formData.append('file', file, id);
        return axios
          .post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,uploadPhotoImage: function (file,id) {
        var url = this.API_BASE_URL + '/files/contactMST/photoProof';
        var formData = new FormData();
        formData.append('file', file, id);
        return axios
          .post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,uploadSignImage: function (file,id) {
        var url = this.API_BASE_URL + '/files/contactMST/signProof';
        var formData = new FormData();
        formData.append('file', file, id);
        return axios
          .post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,uploadPurchaseIdImage: function (file,id) {
        var url = this.API_BASE_URL + '/purchaseIdImageMST/uploadFileIdImage';
        var formData = new FormData();
        formData.append('file', file, id);
        return axios
          .post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,uploadPurchaseAddressProofImage: function (file,id) {
        var url = this.API_BASE_URL + '/purchaseAddressproofMST/uploadFileAddressProof';
        var formData = new FormData();
        formData.append('file', file, id);
        return axios
          .post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,uploadPurchasePhotoImage: function (file,id) {
        var url = this.API_BASE_URL + '/purchasePhotoMST/uploadFilePhoto';
        var formData = new FormData();
        formData.append('file', file, id);
        return axios
          .post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,uploadPurchaseSignImage: function (file,id) {
        var url = this.API_BASE_URL + '/purchaseSignMST/uploadFileSign';
        var formData = new FormData();
        formData.append('file', file, id);
        return axios
          .post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getPurchaseNo: function () {
        var url = this.API_BASE_URL + '/purchaseMST/getPurchaseNo';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getSellNo: function () {
        var url = this.API_BASE_URL + '/sellMST/getSellCode';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getCompanyCode: function () {
        var url = this.API_BASE_URL + '/companyMST/getCompanyCode';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getVehicaleCategoryCode: function () {
        var url = this.API_BASE_URL + '/vehicleCategotyMST/getVehicleCode';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getModelCode: function () {
        var url = this.API_BASE_URL + '/modelMST/getModelCode';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getPartyVoucherCode: function () {
        var url = this.API_BASE_URL + '/partyVoucher/getVoucherCode';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getPartyReceiptCode: function () {
        var url = this.API_BASE_URL + '/partyVoucher/getReceiptCode';
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getRCFilter: function (criteria) {
        var url = this.API_BASE_URL + '/rcMST/filter/?'+criteria;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getPurchaseFilter: function (criteria) {
        var url = this.API_BASE_URL + '/purchaseMST/filter/?'+criteria;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
      ,getSellFilter: function (criteria) {
        var url = this.API_BASE_URL + '/sellMST/filter/?'+criteria;
        return axios
          .get(url, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(ApiUtils.checkStatus)
          .then(response => {
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error.response;
          });
      }
}



export { HttpAPI as default };
