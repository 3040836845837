import React, { Component } from "react";
import { MDBTabContent, MDBTabPane, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCardBody, MDBBreadcrumbItem, MDBCol } from 'mdbreact';
class APIResponse extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen:this.props.isOpen,
            msg:this.props.msg,
            response:this.props.response,
    }

    }
    componentWillReceiveProps(newProps){
        this.setState({isModalOpen:newProps.isOpen})
        this.setState({msg:newProps.msg})
        this.setState({response:newProps.response})
    }
    toggle=()=>{
        this.props.closeAPIResponse();
    }

    render(){
        return(
            <React.Fragment>
                <MDBTabContent activeItem={this.state.activeItem} >
                    <MDBModal isOpen={this.state.isModalOpen} toggle={this.toggle} size='sm'>
                    <MDBModalHeader toggle={this.toggle}>Alert</MDBModalHeader>
                    <MDBModalBody className="text-center">
                    {this.state.msg}
                    </MDBModalBody>
                 <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn>
          </MDBModalFooter>
                    </MDBModal>
                </MDBTabContent>
            </React.Fragment>
        )
    }
}
export default APIResponse;