import React, { Component } from 'react';
import { MDBTabContent, MDBFormInline, MDBIcon, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBCol } from 'mdbreact';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import DateRangePickerModal from '../common/DateRangePickerModal'

import PurchaseDetail from './modal/PurchaseDetail'
import * as moment from 'moment';
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'
import Pagination from "../common/Pagination";
import Select from 'react-select';


import "./Styleing.css";

class PurchasePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            purchaseData: [],
            filterPurchaseData: [],
            openModal: false,
            openRCModal: false,
            selecteddata: {},
            selectedPurchase: {},
            isOpenDateRange: false,
            startDate:null,
            endDate:null,
            dateLabel:'',
            purchaseFor: '',
            rcReceived: '',
            finance: '',
            allDocReceived: '',
            selectedpurchaseFor: '',
            selectedrcReceived: '',
            selectedfinance: '',
            selectedallDocReceived: '',
            purchaseForOption: [{ name: 'purchaseFor', label: "Retail", value: "Retail" }, { name: 'purchaseFor', label: "Scrap", value: "Scrap" }],
            rcReceivedOption: [{ name: 'rcReceived', label: "Yes", value: "Yes" }, { name: 'rcReceived', label: "No", value: "No" }],
            financeOption: [{ name: 'finance', label: "Yes", value: "Yes" }, { name: 'finance', label: "No", value: "No" }],
            allDocReceivedOption: [{ name: 'allDocReceived', label: "Yes", value: "Yes" }, { name: 'allDocReceived', label: "No", value: "No" }],

        };

    }
    closeaddrcmodel = (id) => {
        this.setState({ openRCModal: false })
        this.setState({ rcId: id })
    }
    openAddPurchase = () => {
        window.localStorage.setItem('openRCFromId',0)
        window.localStorage.setItem('RCdata','')
        window.localStorage.setItem('editPurchaseId',0);
        window.localStorage.setItem('editRCId',0);
        window.location.href = '/addPurchase'
        //this.setState({ openModal: true })
    }
    closeAddPurchase = () => {
        this.setState({ selectedPurchase: {} })
        this.setState({ openModal: false })

    }
    componentDidMount() {
        window.localStorage.setItem('editPurchaseId', 0)
        window.localStorage.setItem('openRCFrom', '')
        window.localStorage.setItem('RCdata', '')
        this.renderDataFromServer();
    }
    renderDataFromServer() {
        HttpAPI.getPurchase().then(
            response => {
                var response = response.data;
                var data = response.data;
                // var dataList = []
                // data.map(row => {
                //     let dataRow = row
                //     if (row.purchaseFor.toLowerCase() === 'scrap') {
                //         let isScrap = { 'isScrap': true }
                //         dataRow = Object.assign(dataRow, isScrap)
                //     } else {
                //         let isScrap = { 'isScrap': false }
                //         dataRow = Object.assign(dataRow, isScrap)
                //     }
                //     if (row.financed === true) {
                //         let isScrap = { 'loanDetail': 'Loan' }
                //         dataRow = Object.assign(dataRow, isScrap)
                //     } else {
                //         let isScrap = { 'loanDetail': 'Cash' }
                //         dataRow = Object.assign(dataRow, isScrap)
                //     }

                //     dataList.push(dataRow)
                // })
                this.setState({ purchaseData: data });
                this.setState({ filterPurchaseData: data })
            }
        );

    }
    deleteRowById(id) {

        HttpAPI.deletePurchaseById(id).then(
            response => {
                this.renderDataFromServer();
            });
    }
    getRowById(id) {
        window.localStorage.setItem('editPurchaseId', id)
        this.openAddPurchase()
        // HttpAPI.getPurchaseById(id).then(
        //     response => {
        //         var response = response.data;
        //         this.setState({ selectedPurchase: response.data })
        //         this.openAddPurchase()
        //     }
        // );
    }
    ViewPuchaseDetail(id) {
        HttpAPI.getPurchaseById(id).then(
            response => {
                var response = response.data;

                this.setState({ selectedPurchase: response.data })
                this.setState({ openModal: true })
            }
        );
    }

    clearSearch = () => {
        this.setState({
            purchaseFor: '',
            rcReceived: '',
            finance: '',
            allDocReceived: '',
            dateLabel:'',
            selectedpurchaseFor: '',
            startDate:null,
            endDate:null,
            selectedrcReceived: '',
            selectedfinance: '',
            selectedallDocReceived: '',
        })
        this.renderDataFromServer();
    }
    searchClick = () => {
        let criteria = ''
        
        if (this.state.purchaseFor !== '') {
            criteria = criteria + 'purchase_for='+this.state.purchaseFor
        }
        if (this.state.rcReceived !== '') {
            criteria = criteria + "&rc_book_received=" + this.state.rcReceived.toLowerCase()
        }
        if (this.state.finance !== '') {
            if(this.state.finance==='Yes'){
                criteria = criteria + "&is_financed=true" 
            }else{
                criteria = criteria + "&is_financed=false" 
            }
            
        }
        if (this.state.allDocReceived !== '') {
                criteria = criteria + "&all_docs_received="+this.state.allDocReceived.toLowerCase()
        }
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        if (this.state.startDate !== null) {
            criteria = criteria + "&purchaseDateFrom=" + (new Date(this.state.startDate - tzoffset)).toISOString().slice(0, -1)
        }
        if (this.state.endDate !== null) {
            criteria = criteria + "&purchaseDateTo=" + (new Date(this.state.endDate - tzoffset)).toISOString().slice(0, -1)
        }

        HttpAPI.getPurchaseFilter(criteria).then(response => {
            var response = response.data;
            var data = response.data;
            // var dataList = []
            // data.map(row => {
            //     let dataRow = row
            //     if (row.purchaseFor === 'scrap') {
            //         let isScrap = { 'isScrap': true }
            //         dataRow = Object.assign(dataRow, isScrap)
            //     } else {
            //         let isScrap = { 'isScrap': false }
            //         dataRow = Object.assign(dataRow, isScrap)
            //     }
            //     if (row.financed === true) {
            //         let isScrap = { 'loanDetail': 'Loan' }
            //         dataRow = Object.assign(dataRow, isScrap)
            //     } else {
            //         let isScrap = { 'loanDetail': 'Cash' }
            //         dataRow = Object.assign(dataRow, isScrap)
            //     }

            //     dataList.push(dataRow)
            // })
            this.setState({ purchaseData: data,filterPurchaseData:data })
        });
    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    setSelectInputVar=(e)=>{
        this.setState({
            [e.name ]: e.value,
            ['selected' + e.name]: e
        });
    }
    changeSelectVar = (e) => {
        let id = e.id;
        this.setState({
            [e.name + 'Name']: e.value,
            ['selected' + e.name]: e
        });

        if (id > 0) {
            let filterRow = this.state.customerData.filter(row => row.id === Number(id))[0]

            this.setState({ mobileNo: filterRow.mobileNumber })
        }
    }
    addPartyVoucher = (props) => {
        window.localStorage.setItem('editPartyVoucherId',0)
        window.localStorage.setItem('partyVoucherPurchaseId', props.original.id);
        window.location.href = '/addVoucher'
    }
    addRC(rc) {
        if (rc.original.rcId !== null && rc.original.rcId > 0) {
            window.localStorage.setItem('editRCId', rc.original.rcId)
        } else {
            let data = {
                'purchaseNumber': rc.original.purchaseNumber,
                'bikeNumber': rc.original.bikeNumber,
                'engineNumber': rc.original.engineNumber,
                'chasisNumber': rc.original.chasisNumber,
                'companyId': rc.original.companyId,
                'modelId': rc.original.modelId,
                'companyMST': rc.original.companyMST,
                'modelMST': rc.original.modelMST,
                'contactId': rc.original.contactId,
                'contactMST': rc.original.contactMST
            }
            window.localStorage.setItem('RCdata', JSON.stringify(data))
        }
       window.localStorage.setItem('openRCFrom', 'purchase')
        window.location.href = '/addRC'
    }
    handleDateChangeFrom = (e) => {
        this.setState({ parsingDtFrom: e })
    }
    handleDateChangeTo = (e) => {
        this.setState({ parsingDtTo: e })
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    globalSearch = (searchInput) => {
        let filteredData = this.state.purchaseData.filter(value => {
            return (
                (value.purchaseDate !== null && moment(value.purchaseDate).format('DD/MM/YYYY').toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseNumber !== null && value.purchaseNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.companyMST !== null && value.companyMST.description.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.modelMST == null && value.modelMST.description.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.bikeNumber !== null && value.bikeNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.contactMST != null && value.contactMST.firstName.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.contactMST != null && value.contactMST.mobileNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.rcBookReceived !== null && (value.rcBookReceived===true?'Yes':'No').toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseFor !== null && value.purchaseFor.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseAmount !== null && value.purchaseAmount.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.paidAmount !== null && value.paidAmount.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.financed !== null && (value.financed===true?'Yes':'No').toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.allDocsReceived !== null && (value.allDocsReceived===true?'Yes':'No').toLowerCase().includes(searchInput.toLowerCase()))
            );
        });
        this.setState({ filterPurchaseData: filteredData });
    }
    onToggleDateControl=()=>{
        this.setState({isOpenDateRange:true})
    }
    hangleDateRange=(selectedStartDate,selectedEndDate)=> {
        
        this.setState({
            startDate:selectedStartDate,
            endDate:selectedEndDate,
            isOpenDateRange:false,
            dateLabel:moment(selectedStartDate).format('DD/MM/YYYY').toString() + '-'+moment(selectedEndDate).format('DD/MM/YYYY').toString()
        })
    }
    render() {
        
        const columns = [
            {
                id: "1",
                Header: () => (
                    <div className="bgcolor">
                        Date
                    </div>
                ),
                accessor: d => moment(d.purchaseDate).format('DD/MM/YYYY'),
                width: 100,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "2",
                Header: () => (
                    <div className="bgcolor">
                        <div>Purchase No</div>
                        
                    </div>
                ),
                accessor: "purchaseNumber",
                maxWidth: 150,
                width: 150,
                style: {
                    height:35,
                    textAlign: 'Center',
                    
                },

            },
            /*
            {
                id: "3",
                Header: () => (
                    <div className="bgcolor">
                        Company
                    </div>
                ),
                accessor: "companyMST.description",
                width: 150,
                style: {
                    height:35,
                    textAlign: 'Center',

                },
            },
            */
            
            
            {
                id: "4",
                Header: () => (
                    <div className="bgcolor">
                        Model Name
                    </div>
                ),
                accessor: "modelMST.description",
                width: 100,
                style: {
                    height:35,
                    textAlign: 'Center',

                },
            },
            {
                id: "5",
                Header: () => (
                    <div className="bgcolor">
                        Bike No
                    </div>
                ),
                accessor: "bikeNumber",
                width: 120,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            
            {
                id: "6",
                Header: () => (
                    <div className="bgcolor">
                        Vendor Name
                    </div>
                ),
                accessor: "contactMST.firstName",
                width: 150,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            /*
           
            {
                id: "7",
                Header: () => (
                    <div className="bgcolor">
                        Vendor No
                            
                    </div>
                ),
                accessor: "contactMST.mobileNumber",
                width: 100,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            */
            {
                id: "8",
                Header: () => (
                    <div className="bgcolor">
                        RC Rec
                    </div>
                ),
                accessor: d=>d.rcBookReceived===true?'Yes':'No',
                width: 80,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "9",
                Header: () => (
                    <div className="bgcolor">
                        Purchase For
                    </div>
                ),
                accessor: "purchaseFor",
                width: 95,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "10",
                Header: () => (
                    <div className="bgcolor">
                       Total Amt
                    </div>
                ),
                accessor: "purchaseAmount",
                width: 95,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "11",
                Header: () => (
                    <div className="bgcolor">
                       Due Amt
                    </div>
                ),
                accessor:d=>d.purchaseAmount-d.paidAmount,
               
                width: 80,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            
            {
                id: "12",
                Header: () => (
                    <div className="bgcolor">
                        Financed
                    </div>
                ),
                accessor:d=>d.financed===true?'Yes':'No',
                
                width: 80,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "13",
                Header: () => (
                    <div className="bgcolor">
                        All docs rec
                    </div>
                ),
                accessor:d=>d.allDocsReceived===true?'Yes':'No',
                width: 90,
                style: {
                   
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "14",
                Header: () => (
                    <div className="bgcolor">
                        Pay
                    </div>
                ),

                sortable: false,
                width: 60,
                style: {
                    height:35,
                    textAlign: 'Center',

                },
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='book' style={{ cursor: 'pointer' }} onClick={() => this.addPartyVoucher(props)} />
                            </div>
                        </div>
                    );
                }
            },
            {
                id: "15",
                Header: () => (
                    <div className="bgcolor">
                        Add RC
                    </div>
                ),
               
                style: {
                    height:35,
                   
                    textAlign: 'Center',

                },
                sortable: false,
                width: 65,
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='book' style={{ cursor: 'pointer' }} onClick={() => this.addRC(props)} />
                            </div>
                        </div>
                    );
                }
            },
            {
                id: "16",
                Header: () => (
                    <div className="bgcolor">
                        KARAR
                    </div>
                ),
                sortable: false,
                style: {
                    height:35,
                    textAlign: 'Center',

                },
                width: 65,
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='book' style={{ cursor: 'pointer' }} onClick={() => this.getRowById(props.value)} />
                            </div>
                        </div>
                    );
                }
            },
            {
                id: "17",
                Header: () => (
                    <div className="bgcolor">
                        Actions
                    </div>
                ),
                accessor: "id",
                width: 120,
                sortable: false,
                style: {
                    height:35,
                    textAlign: 'Center',

                },
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='eye' style={{ cursor: 'pointer' }} onClick={() => this.ViewPuchaseDetail(props.value)} />
                                <MDBIcon className=" m-2" icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.getRowById(props.value)} />
                                <MDBIcon className=" m-2" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.value)} />
                            </div>
                        </div>
                    );
                }
            }
        ]
        return (<div>
            <MDBRow className="justify-content-left ml-0">
                
                <div className="col-sm-12 d-flex flex-row-reverse">
                    <CustomBreadCrumb items={['General', 'Purchase']} linkTo={['', '']} />
                </div>
            </MDBRow>
            <MDBRow className="">
                <MDBCol sm="12">
                    <MDBCard className="mb-6 mr-0  " >
                        <MDBCardBody className="align-items-center justify-content-between mr-0">
                            <div className="row">
                            <div className="form-group col-xl-2 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Purchase For</label>
                                    <Select name="purchaseFor" options={this.state.purchaseForOption} value={this.state.selectedpurchaseFor} onChange={this.setSelectInputVar} id="purchase" class="input-field browser-default md-select form-control form-control-sm" >
                                    </Select>

                                </div>
                                <div className="form-group col-xl-2 col-lg-3 col-md-3  col-sm-12 col-xs-12 purchasefor">
                                    <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >RC Received</label>
                                    <Select name="rcReceived" options={this.state.rcReceivedOption} value={this.state.selectedrcReceived} onChange={this.setSelectInputVar} id="purchase" class="input-field browser-default md-select form-control form-control-sm" >
                                    </Select>
                                </div>
                                <div className="form-group col-xl-2 col-lg-3 col-md-3 col-sm-12 col-xs-12  purchasefor">
                                    <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Financed</label>
                                    <Select name="finance" options={this.state.financeOption} value={this.state.selectedfinance} onChange={this.setSelectInputVar} id="purchase" class="input-field browser-default md-select form-control form-control-sm" >
                                    </Select>
                                </div>
                                <div className="form-group col-xl-2 col-lg-3 col-md-3 col-sm-12 col-xs-12 purchasefor">
                                    <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >All Doc Received</label>
                                    <Select name="allDocReceived" options={this.state.allDocReceivedOption} value={this.state.selectedallDocReceived} onChange={this.setSelectInputVar} id="purchase" class="input-field browser-default md-select form-control form-control-sm" >
                                    </Select>
                                </div>
                                <div className="form-group col-xl-2 col-lg-3 col-md-3 col-sm-12 col-xs-12  purchasedate purchasedata ">
                                    <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Date Range</label>
                                    <input
                                    value={this.state.dateLabel} classname="" onClick={this.onToggleDateControl} className="daterange form-control"/>                                    
                                </div>
                                
                                <div className="form-group col-xl-1 col lg-3 col-md-4 col-sm-12 col-xs-12 ">
                                <MDBBtn className="  purchasesave btn-md " size='md' onClick={this.searchClick}><i className="fas fa-filter"></i><span className="Savedata pl-1 ">Apply filter</span></MDBBtn>
                                </div>
                                <div className="form-group col-xl-1 col-lg-3 col-md-4 col-sm-12 col-xs-12">
                                <MDBBtn className=" purchaseCancal  " size='md' onClick={this.clearSearch}><i className="fas fa-undo-alt"></i><span className="Savedata pl-1">  Clear Filter</span></MDBBtn>
                                </div>
                            </div>
                            <div className="row purchasesearchicon">
                            <div className="input-group md-form form-sm form-2 col-xl-11 col-lg-10 col-md-10 col-sm-12 col-xs-12 ">
                                <input className="form-control my-0 py-0  lime-border" type="text" onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                <div className="input-group-append">
                                    <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                        aria-hidden="true"></i></span>
                                </div>
                                
                            </div>
                            
                            <div className="form-group col-xl-1 col-lg-1 col-md-2 col-sm-12 col-xs-12">
                            <MDBBtn className="mt-3 purchaseaddnew" size='md' onClick={this.openAddPurchase}><span className="Savedata">Add New </span></MDBBtn>
                                </div>       
                            </div>
                            <DateRangePickerModal isOpen={this.state.isOpenDateRange} hangleDateRange={this.hangleDateRange} />
                            <MDBRow className="justify-content-left ">
                                <MDBCol lg="12"  >

                                    <ReactTable
                                        resizable={false}
                                        minRows={1}
                                        noDataText="NO DATA FOUND"
                                        showPagination={true}
                                        PaginationComponent={Pagination}
                                        defaultPageSize={15}

                                        style={{
                                            // This will force the table body to overflow and scroll, since there is not enough room
                                        }}
                                        data={this.state.filterPurchaseData}
                                        columns={columns} />
                                </MDBCol>
                            </MDBRow>
                            {/* <AddPurchaseModal isOpen={this.state.openModal} closeAddPurchase={this.closeAddPurchase} editPurchase={this.state.selectedPurchase} /> */}
                            <PurchaseDetail isOpen={this.state.openModal} purchaseRecord={this.state.selectedPurchase} />
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </div>);
    }
}
export default PurchasePage