import React, { Component } from "react";
import { MDBRow, MDBBtn, MDBInput, MDBCard, MDBCardBody, MDBCol } from 'mdbreact';
import HttpAPI from '../../common/api_calls';
import ApiUtils from '../../common/api_utils';
import APIResponse from '../../common/APIResponse'
class PersonalInfoCardSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactTypeList: ApiUtils.getContactType(),
            idTypeList: ApiUtils.getIDType(),
            firstName: '',
            lastName: '',
            middleName: '',
            mobile: '',
            photo: '',
            idImage: '',
            addressProofList: ApiUtils.getIDType(),
            addressProofImage: '',
            phone: '',
            sign: '',
            contactType: '',
            idType: '',
            addressProof: '',
            addressInfoData: [],
            editContact: {},
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: ''

        }

    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    createPersonalInfo = () => {
        let data = {
            'contactType': this.state.contactType,
            'firstName': this.state.firstName,
            'middleName': this.state.middleName,
            'lastName': this.state.lastName,
            'mobileNumber': this.state.mobile,
            'idType': this.state.idType,
            'addressProof': this.state.addressProof,
            'phoneNumber': this.state.phone
        }


        let senddata = ApiUtils.appendCreateRequiredField(data);
        HttpAPI.createContact(senddata).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    if (this.state.addressInfoData !== []) {
                        let ArrAddress = []
                        this.state.addressInfoData.map(function (row) {
                            let id = { 'contactId': response.data.id }
                            let addressData = Object.assign(row, id);
                            let sendAddressData = ApiUtils.appendCreateRequiredField(addressData);
                            ArrAddress.push(sendAddressData)
                        })
                        HttpAPI.createAddress(ArrAddress).then(
                            response => {

                            });
                    }

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })


                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)
            }
        );
    }
    componentWillReceiveProps(newProps) {
        this.setState({ addressInfoData: newProps.addressInfo })
        if (Object.keys(newProps.editContact).length !== 0) {
            this.setState({ editContact: newProps.editContact })
            this.setEditData(newProps.editContact)
        }
    }
    setEditData = (editContact) => {
        this.setState({
            idType: editContact.idType,
            contactType: editContact.contactType,
            firstName: editContact.firstName,
            lastName: editContact.lastName,
            middleName: editContact.middleName,
            mobile: editContact.mobileNumber,
            phone: editContact.phoneNumber,
            addressProof: editContact.addressProof
        })
    }
    saveData = () => {
        if (this.validateData() == true) {
            this.createPersonalInfo();
        }
    }
    clearData = () => {
        this.setState({
            companyId: 0,
            copanyName: '',
            categoryCode: '',
            categoryName: ''
        })
    }
    validateData = () => {
        return true;
    }
    render() {
        return (<div>
            <MDBCard className="mb-5">
                <MDBCardBody className="align-items-center justify-content-between">
                    <MDBRow className="m-0 p-0">
                        <MDBCol sm='6'>
                            <div class="md-form mt-3">
                                <select name="contactType" value={this.state.contactType} onChange={this.setInputVar} id="select" class="browser-default md-select form-control">
                                    <option value="select contact type">Select Contact Type</option>
                                    {
                                        this.state.contactTypeList.map(item => {
                                            return <option value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </MDBCol>
                        <MDBCol sm='6'>
                            <div class="md-form mt-3">
                                <select name="idType" id="select" value={this.state.idType} onChange={this.setInputVar} class="browser-default md-select form-control">
                                    <option value="select id type">Select ID Type</option>
                                    {
                                        this.state.idTypeList.map(item => {
                                            return <option value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="m-0 p-0">
                        <MDBCol sm='6'>
                            <MDBInput type='text' value={this.state.firstName} onChange={this.setInputVar} name='firstName' label="First Name">
                            </MDBInput>
                        </MDBCol>
                        <MDBCol sm='6'>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupFileAddon01">
                                        ID Image</span>
                                </div>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="inputGroupFile01"
                                        aria-describedby="inputGroupFileAddon01"
                                        name='idImage'
                                        onChange={this.setInputVar}
                                    />
                                    <label className="custom-file-label" htmlFor="inputGroupFile01">
                                        Choose file</label>
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="m-0 p-0">
                        <MDBCol sm='6'>
                            <MDBInput type='text' onChange={this.setInputVar} value={this.state.middleName} name='middleName' label="Middle Name">
                            </MDBInput>
                        </MDBCol>
                        <MDBCol sm='6'>
                            <div class="md-form mt-3">
                                <select name="addressProof" onChange={this.setInputVar} id="select" class="input-field browser-default md-select form-control">
                                    <option value="select address proof">Select Address Proof</option>
                                    {
                                        this.state.addressProofList.map(item => {
                                            return <option value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="m-0 p-0">
                        <MDBCol sm='6'>
                            <MDBInput type='text' name='lastName' value={this.state.lastName} onChange={this.setInputVar} label="Last Name">
                            </MDBInput>
                        </MDBCol>
                        <MDBCol sm='6'>

                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupFileAddon01">
                                        Address Proof Image
    </span>
                                </div>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="inputGroupFile01"
                                        aria-describedby="inputGroupFileAddon01"
                                        name='addressProofImage'
                                        onChange={this.setInputVar}
                                    />
                                    <label className="custom-file-label" htmlFor="inputGroupFile01">
                                        Choose file
    </label>
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="m-0 p-0">
                        <MDBCol sm='6'>
                            <MDBInput type='text' name='mobile' value={this.state.mobile} onChange={this.setInputVar} label="Mobile">
                            </MDBInput>
                        </MDBCol>
                        <MDBCol sm='6'>
                            <MDBInput type='text' name='phone' value={this.state.phone} onChange={this.setInputVar} label="Phone">
                            </MDBInput>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="m-2 p-0">
                        <MDBCol sm='6' className="m-0">

                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupFileAddon01">
                                        Photo
    </span>
                                </div>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="inputGroupFile01"
                                        aria-describedby="inputGroupFileAddon01"
                                        name='photo'
                                        onChange={this.setInputVar}
                                    />
                                    <label className="custom-file-label" htmlFor="inputGroupFile01">
                                        Choose file
    </label>
                                </div>
                            </div>
                        </MDBCol>
                        <MDBCol sm='6' className="m-0">

                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupFileAddon01">
                                        Sign
    </span>
                                </div>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="inputGroupFile01"
                                        aria-describedby="inputGroupFileAddon01"
                                        name='sign'
                                        onChange={this.setInputVar}
                                    />
                                    <label className="custom-file-label" htmlFor="inputGroupFile01">
                                        Choose file
    </label>
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="m-0 p-0">
                        <MDBCol sm='12'>
                            <MDBBtn size='sm' onClick={this.saveData}>
                                Save
                        </MDBBtn>

                            <MDBBtn size='sm' onClick={this.clearData}>
                                Cancel
                        </MDBBtn>
                        </MDBCol>

                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
            <APIResponse
                isOpen={this.state.apiResponseModalOpen}
                response={this.state.apiResponse}
                msg={this.state.apiResponseMsg}
                closeAPIResponse={this.closeAPIResponse}
            />
        </div>);
    }
}
export default PersonalInfoCardSection