import React, { Component } from "react";
import { MDBInput, MDBCol, MDBRow, MDBCard, MDBCardBody, MDBBreadcrumb, MDBBreadcrumbItem, MDBBtn, MDBIcon } from "mdbreact";
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'
import APIResponse from '../common/APIResponse'
import Pagination from "../common/Pagination";
import "./Styleing.css";
import * as moment from 'moment';
class ReceiptPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bikeNo: '',
            partyVoucherData: [],
            filterData:[],
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
        }
    }
    searchClick = () => {
        let searchInput=this.state.bikeNo
        if(searchInput!==''){
            let filteredData = this.state.partyVoucherData.filter(value => {
                return (value.bikeNumber.toLowerCase().includes(searchInput.toLowerCase()))
            })
            this.setState({ filterData: filteredData });
        }else{
            this.setState({ filterData: this.state.partyVoucherData });
        }
        
    }
    componentDidMount() {
        this.renderDataFromServer()
    }
    renderDataFromServer() {
        HttpAPI.getReceipt().then(response => {
            var response = response.data;
            if (response.status === true) {
                let filterData=response.data.filter(row=>row.sellReceipt===true)
                this.setState({ 
                    partyVoucherData: filterData,
                    filterData: filterData })
            }
        })
    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    editPartyVoucher=(id)=>{
        window.localStorage.setItem('editReceiptId',id);
        window.localStorage.setItem('receiptSellId',0);
        window.location.href = '/addReceipt'
    }
    deleteRowById = (id) => {
        HttpAPI.deleteReceiptById(id).then(response => {
            var response = response.data;
            if (response.status === true) {
                this.setState({
                    apiResponse: true,
                    apiResponseMsg: 'Receipt Deleted Successfully',
                    apiResponseModalOpen: true
                })
                this.renderDataFromServer()
            } else {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: response.message,
                    apiResponseModalOpen: true
                })
            }
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 1000)
        })
    }
    render() {
        const columns = [
            {
                id: "1",
                Header:()=>(
                    <div className="bgcolor">
                            Code
                    </div>
                ),
                accessor: "code",
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "2",
                Header:()=>(
                    <div className="bgcolor">
                            Date
                    </div>
                ),
                accessor: d => moment(d.date).format('DD/MM/YYYY'),
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "3",
                Header:()=>(
                    <div className="bgcolor">
                            Mode of Payment
                    </div>
                ),
                accessor: "modeOfPayment",
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "4",
                Header:()=>(
                    <div className="bgcolor">
                            Customer Name
                    </div>
                ),
                accessor: "contactMST.firstName",
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "4",
                Header:()=>(
                    <div className="bgcolor">
                            Bike Number
                    </div>
                ),
                accessor: "bikeNumber",
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "5",
                Header:()=>(
                    <div className="bgcolor">
                            Amount
                    </div>
                ),
                accessor: "totalPayment",
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "6",
                Header:()=>(
                    <div className="bgcolor">
                           Paid Amount
                    </div>
                ),
                accessor: "paidPayment",
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "6",
                Header:()=>(
                    <div className="bgcolor">
                           Pending Amount
                    </div>
                ),
                accessor: "remainingPayment",
                width: 150,
                maxWidth: 150,
                minWidth: 150,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "7",
                Header:()=>(
                    <div className="bgcolor">
                            Actions
                    </div>
                ) ,
                accessor: "id",
                
                sortable: false,
                style: {
                    height:35,
                    textAlign: 'Center',
                    
                   
                   

                },
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className=" " icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.editPartyVoucher(props.value)} />
                                <MDBIcon className="pl-3" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.value)} />
                            </div>
                        </div>
                    );
                    
                }
            }
        ]
        return (<React.Fragment>
            <MDBRow>
                <MDBCol lg="3">

                </MDBCol>
                <MDBCol lg="3">

                </MDBCol>
                <MDBCol lg="3">

                </MDBCol>
                <div id="partyvoucher">
                <CustomBreadCrumb items={['Account', 'Party Voucher']} linkTo={['', '']} />
                </div>
                <MDBCol lg="12">
                    
                    <MDBCard className="mb-5 mr-0 ">
                        <MDBCardBody className="align-items-center justify-content-between mr-0">
                        <div className="row">
                                    <div className="form-group col-sm-2">
                                    <label for="purchaseId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Bike No</label>
                                            <input type="text"  onChange={this.setInputVar} name='bikeNo' className="form-control form-text font-small m-0 p-0" value={this.state.bikeNo} id="purchaseId">
                                            </input>
                                    </div>
                                    <div className="form-group col=sm-2 partyvocuhersearch">
                                    <MDBBtn size='md' onClick={this.searchClick}>
                                        <span className="Savedata">
                                        Search
                                        </span>
                                       
                                        </MDBBtn>
                                    </div>
                                </div>
                            <MDBRow className="justify-content-left ml-0 mx-auto ">
                                
                                
                                <MDBCol sm="4" className="mb-3">
                                    
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="justify-content-left  partyvouchertable">
                                <MDBCol lg="12" className="mb-2">
                                    <ReactTable
                                        resizable={false}
                                        minRows={1}
                                        noDataText="NO DATA FOUND"
                                        showPagination={true}
                                        PaginationComponent={Pagination}
                                        defaultPageSize={25}
                                        style={{
                                            // This will force the table body to overflow and scroll, since there is not enough room
                                        }}
                                        data={this.state.filterData}
                                        columns={columns} />
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <APIResponse
                    isOpen={this.state.apiResponseModalOpen}
                    response={this.state.apiResponse}
                    msg={this.state.apiResponseMsg}
                    closeAPIResponse={this.closeAPIResponse}
                />
            </MDBRow>
        </React.Fragment>);
    }

}
export default ReceiptPage;