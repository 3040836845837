import React, { Component } from "react";
import { MDBTabContent, MDBTabPane, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCardBody, MDBBreadcrumbItem, MDBCol } from 'mdbreact';
import loaderImg from '../../assets/loader.gif'
class APILoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: this.props.isOpen,
        }

    }
    componentWillReceiveProps(newProps) {
        this.setState({ isModalOpen: newProps.isOpen })
        
    }
    toggle = () => {
       
    }

    render() {
        return (
            <React.Fragment>
               
                    <MDBModal isOpen={this.state.isModalOpen} toggle={this.toggle} size='sm'>
                        
                        <MDBModalBody className="text-center">
                            <img src={loaderImg} />
                        </MDBModalBody>
                        
                    </MDBModal>
               
            </React.Fragment>
        )
    }
}
export default APILoader;