import React, { Component } from "react";
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import { MDBCol, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBIcon } from 'mdbreact';
import APIResponse from '../common/APIResponse'
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'
import './Styleing.css';
import Pagination from "../common/Pagination";
import Select from 'react-select';
class CountryPage extends Component {
    constructor(props) {

        super(props);
        this.state = {
            DistrictData: [],
            filterDistrictData:[],
            DistrictColumns: [],
            stateData: [],
            filterState: [],
            countryData: [],
            countryName: '',
            stateName: "",
            districtName: "",
            countryId: 0,
            stateId: 0,
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            id: 0,
            selectedcountry:'',
            countryOption: [{ id: 0, name: 'country', label: "", value: "" }],
            selectedstate:'',
            stateOption: [{ id: 0, name: 'state', label: "", value: "" }],
            errorMap:{'countryName': { 'valid': true, 'error': '' },
            'stateName': { 'valid': true, 'error': '' },
            'districtName': { 'valid': true, 'error': '' }}
        }
        this.countryInput = React.createRef();
        this.stateInput = React.createRef();
        this.districtInput = React.createRef();
    }
    componentDidMount() {
        this.renderDataFromServer();
    }
    renderDataFromServer() {
        HttpAPI.getDistrict().then(
            response => {
                var response = response.data;
                this.setState({ DistrictData: response.data,filterDistrictData:response.data })
            }
        );
        HttpAPI.getCountry().then(
            response => {
                var response = response.data;
                this.setState(
                    { countryData: response.data }
                    )
                    var arr=[]
                    arr.push({id:0,name:'country',label:'',value:''})
                    response.data.map((item,key)=>
                    arr.push({
                        id: item.id, name: 'country',
                        label: item.countryName ,
                        value: item.countryName
                    })
                    );
                    this.setState({ countryOption: arr })
                    
                }
        );
            
        
        HttpAPI.getState().then(
            response => {
                var response = response.data;
                this.setState({ stateData: response.data })
                this.populateDefault(response.data)
            }
        );
    }
    populateDefault(stateData){
        this.setState({
            selectedcountry:{
                id: this.state.countryData[0].id,name: 'country',
                label: this.state.countryData[0].countryName ,
                value: this.state.countryData[0].countryName,
            },
            countryId:this.state.countryData[0].id,
            countryName:this.state.countryData[0].countryName 
        })
        const data = stateData.filter(row => row.countryId === this.state.countryData[0].id);
       
        var arr = []
        arr.push({ id: 0, name: 'state', label: '', value: '' })
        data.map(item => {
            arr.push({ id: item.id, name: 'state', label: item.stateName, value: item.stateName })
        })
        this.setState({
            selectedstate: { id: data[0].id, name: 'state', label: data[0].stateName, value: data[0].stateName },
            stateOption: arr,
            stateId:data[0].id,
            stateName:data[0].stateName
        })
    }
    changeSelectVar = (e) => {
        this.setState({
            [e.target.name + 'Id']: e.target.options[e.target.selectedIndex].id,
            [e.target.name + 'Name']: e.target.value
        });
    }
    changeCountryVar = (e) => {
        var filterId =Number(e.id);
        const data = this.state.stateData.filter(row => row.countryId === filterId);
        //this.setState({
            //filterState: data
        //});
        var arr = []
        arr.push({ id: 0, name: 'state', label: '', value: '' })
        data.map(item => {
            arr.push({ id: item.id, name: 'state', label: item.stateName, value: item.stateName })
        })
        this.setState({
            selectedstate: '',
            stateOption: arr,

        })
        this.setSelectInputVar(e);
    }
    changeCountry=(e)=>{
        this.changeSelectVar(e);
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    deleteRowById(id) {
        HttpAPI.deleteDistrictById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'District Delete Successfully',
                        apiResponseModalOpen: true
                    })
                    this.clearData();
                    this.renderDataFromServer();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.closeAPIResponse();
                }, 1000)

            }
        );
    }
    getRowById(id) {
        HttpAPI.getDistrictById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState({
                        districtName: response.data.districtName,
                        countryName: response.data.countryMST.countryName,
                        countryId: response.data.countryId,
                        stateName: response.data.stateMST.stateName,
                        stateId: response.data.stateId,
                        selectedcountry:{ id: response.data.countryId, name: 'country', label: response.data.countryMST.countryName, value:response.data.countryMST.countryName },
                        selectedstate:{ id: response.data.stateId, name: 'state', label: response.data.stateMST.stateName, value:response.data.stateMST.stateName },
                        updateRow: response.data,
                        id: response.data.id

                    });

                    const data = this.state.stateData.filter(row => row.countryId === response.data.countryId);
                    this.setState({
                        filterState: data
                    });

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: response.message,
                        // apiResponseModalOpen:true
                    })

                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        // apiResponseModalOpen:true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)


            }
        );
    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    setSelectInputVar = (e) => {
        this.setState({
            [e.name + 'Id']: e.id,
            [e.name + 'Name']: e.value,
            ['selected' + e.name]: e
        });
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    globalSearch = (searchInput) => {
        let filteredData = this.state.DistrictData.filter(value => {
            return (
                (value.districtName!==null && value.districtName.toLowerCase().includes(searchInput.toLowerCase()))
                );
            });
            this.setState({ filterDistrictData: filteredData });
        
    }
    createDistrict() {
        let data = {
            'districtName': this.state.districtName,
            'stateId': this.state.stateId,
            'countryId': this.state.countryId,
        };
        let senddata = ApiUtils.appendCreateRequiredField(data);
        HttpAPI.createDistrict(senddata).then(
            response => {
                var response = response.data;
                if (response.status === true) {

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'District Insert Successfully',
                        apiResponseModalOpen: true
                    })
                    this.clearData();
                    this.renderDataFromServer();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)
            }
        );
    }
    UpdateDistrict() {

        let data = {
            'id': this.state.updateRow.id,
            'districtName': this.state.districtName,
            'countryId': this.state.countryId,
            'stateId': this.state.stateId,
            'active': this.state.updateRow.active,
            'createdAt': this.state.updateRow.createdAt,
            'createdBy': this.state.updateRow.createdBy,
        };
        let senddata = ApiUtils.appendUpdateRequiredField(data);

        HttpAPI.UpdateDistrict(senddata).then(
            response => {
                var response = response.data;

                if (response.status === true) {
                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'District Update Successfully',
                        apiResponseModalOpen: true
                    })
                    this.clearData();
                    this.renderDataFromServer();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)

            }
        );
    }
    saveData = () => {
        if (this.validateData() === true) {
            if (this.state.id > 0) {
                this.UpdateDistrict();
            } else {
                this.createDistrict();
            }
        } 
    }
    clearData = () => {
        this.setState({
            districtName: '',
            countryId: 0,
            countryName: '',
            stateId: '',
            stateName: '',
            selectedcountry:'',
            selectedstate:'',
            countryOption: [{ id: 0, name: 'country', label: "", value: "" }],
            selectedstate:'',
            stateOption: [{ id: 0, name: 'state', label: "", value: "" }],
            errorMap:{'countryName': { 'valid': true, 'error': '' },
            'stateName': { 'valid': true, 'error': '' },
            'districtName': { 'valid': true, 'error': '' }}
        })
    }
    validateData() {
        let map = {
            'countryName': { 'valid': true, 'error': '' },
            'stateName': { 'valid': true, 'error': '' },
            'districtName': { 'valid': true, 'error': '' },}
            let isValid = true;
        if (this.state.countryName === '' || this.state.countryName === 'select country') {
            //this.setState({ apiResponseMsg: 'Please select Country' })
            map.countryName.valid = false;
            map.countryName.error = 'Please select Country'
            this.countryInput.current.focus();
            isValid = false
        }
        if (this.state.stateName === '' || this.state.stateName === 'select state') {
            //this.setState({ apiResponseMsg: 'Please select State' })
            map.stateName.valid = false;
            map.stateName.error = 'Please select State'
            if(isValid===true){
                this.stateInput.current.focus();
            }
            isValid = false
        }
        if (this.state.districtName === '') {
            //this.setState({ apiResponseMsg: 'Please fill District Name' })
            map.districtName.valid = false;
            map.districtName.error = 'Please fill District Name'
            if(isValid===true){
                this.districtInput.current.focus();
            }
            isValid = false
        }
        this.setState({errorMap:map})
        return isValid;
    }

    render() {
        const columns = [
            {
                id: "1",
                Header:()=>(
                    <div className="bgcolor">
                    District Name
                    </div>
                ) ,
                accessor: "districtName",
                style: {
                    height:35,
                    textAlign: 'Center',
                },
               

            },
            {
                id: "2",
                Header:()=>(
                    <div className="bgcolor">
                    Actions
                    </div>
                ) ,
                accessor: "id",
                sortable: false,
                style: {
                    height:35,
                    textAlign: 'Center',

                },
                
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.getRowById(props.value)} />
                                <MDBIcon className=" m-2" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.value)} />
                            </div>
                        </div>
                    );
                }
            }
        ]
        return (
            <React.Fragment>
                <MDBRow className="justify-content-left ml-0">
                    
                    <MDBCol sm="9" className="d-flex flex-row-reverse" >
                    <CustomBreadCrumb items={['Master', 'District']} linkTo={['', '']} />
                    </MDBCol>
                    
                    </MDBRow>
                    <MDBRow>
                    <MDBCol lg="3">

                    </MDBCol>
                    <MDBCol lg="6">
                        
                        <MDBCard className="mb-5 mr-0 ">
                            <MDBCardBody className="align-items-center justify-content-between mr-0">
                                <div className="row">
                                <div className="form-group col-sm-12 col-xs-12 col-md-3 col-lg-4 col-xl-3 DistrictcountryName">
                                            <label for="country" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Select Country</label>
                                            <Select name='country' ref={this.countryInput} options={this.state.countryOption}value={this.state.selectedcountry} onChange={this.changeCountryVar}  id="select" class="browser-default md-select form-control">
                                                <option value="select country">Select</option>
                                               {/* {
                                                    this.state.countryData.map(item => {
                                                        return <option id={item.id} value={item.countryName}>{item.countryName}</option>
                                                    })
                                                }*/}
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.countryName.valid == true ? '' : this.state.errorMap.countryName.error}</span>
                                        </div>
                                        <div className="form-group col-sm-12 col-xs-12 col-md-3 col-lg-4 col-xl-3 DistrictState ">
                                            <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Select State</label>
                                            <Select name="state" ref={this.stateInput} options={this.state.stateOption} value={this.state.selectedstate}  id="select" class="browser-default md-select form-control" onChange={this.setSelectInputVar}>
                                                <option value="select state">Select </option>
                                               {/* {
                                                    this.state.filterState.map(item => {
                                                        return <option id={item.id} value={item.stateName}>{item.stateName}</option>
                                                    })
                                                }*/}

                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.stateName.valid == true ? '' : this.state.errorMap.stateName.error}</span>
                                        </div>
                                        <div className="form-group col-sm-12 col-xs-12 col-md-3 col-lg-4 col-xl-3  DistrictName">
    
                                        <label for="district" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > District Name</label>
                                        <input type="text" ref={this.districtInput} name="districtName" value={this.state.districtName} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}id="district">
                                        </input>
                                        <span className='error-message'>{this.state.errorMap.districtName.valid == true ? '' : this.state.errorMap.districtName.error}</span>
                                        </div>
                                        <div className="form-group col-sm-12 col-xs-12 col-md-2 col-lg-4 col-xl-2 ">
                                        <MDBBtn size='md' onClick={this.saveData} className="Districtsavebutton">
                                            <span className="Savedata">
                                            Add New
                                            </span>
                                    </MDBBtn>
                                    
                                        </div>
                                        <div className="form-group col xs-12 col-sm-12 col-md-1 col-lg-4 col-xl-1 ">
                                        <MDBBtn size='md' onClick={this.saveData} className="Districtsavecancel">
                                            <span className="Savedata">
                                            Cancel
                                            </span>
                                    </MDBBtn>
                                        </div>
                                </div>
                                
                                
                                <div className="input-group md-form form-sm form-2 pl-0 countrysearchicon">
                                <input className="form-control my-0 py-0  lime-border" type="text"  onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                <div className="input-group-append">
                                <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                aria-hidden="true"></i></span>
                                </div>
                               </div>
                                <MDBRow className="districttable">
                                    <MDBCol lg="12" className="mb-2 ">
                                        <ReactTable
                                            resizable={false}
                                            minRows={1}

                                            noDataText="NO DATA FOUND"
                                            showPagination={true}
                                            PaginationComponent={Pagination}
                                            defaultPageSize={15}
                                            style={{
                                                // This will force the table body to overflow and scroll, since there is not enough room
                                            }}
                                            data={this.state.filterDistrictData}
                                            columns={columns} />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>

                    </MDBCol>
                    <APIResponse
                        isOpen={this.state.apiResponseModalOpen}
                        response={this.state.apiResponse}
                        msg={this.state.apiResponseMsg}
                        closeAPIResponse={this.closeAPIResponse}
                    />
                    <MDBCol lg="3">

                    </MDBCol>
                </MDBRow>
            </React.Fragment>
        );
    }
}
export default CountryPage