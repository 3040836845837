import React, { Component } from "react";
import { render } from 'react-dom';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import { MDBCol, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBIcon } from 'mdbreact';
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import APIResponse from '../common/APIResponse'
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'
import Pagination from "../common/Pagination";
class CompanyPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companydata: [],
            filterCompanydata:[],
            companyName: '',
            companyCode: '',
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            id: 0,
            description: '',
            updateRow: {},
            errorMap: {'companyCode': { 'valid': true, 'error': '' },
            'companyName': { 'valid': true, 'error': '' },
        }
        };
        this.companyInput = React.createRef();
    }
    componentDidMount() {
        this.renderDataFromServer();
    }

    renderDataFromServer() {
        HttpAPI.getCompany().then(
            response => {
                var response = response.data;
                this.setState({
                    companydata: response.data,
                    filterCompanydata:response.data
                })
            }
        );
        HttpAPI.getCompanyCode().then(response=>{
            var response = response.data;
            if(response.status===true){
                this.setState({companyCode:response.data.modelCode})
            } 
        });

    }
    closeAPIResponse() {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    deleteRowById(id) {
        HttpAPI.deleteCompanyById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'Company Delete Successfully',
                        apiResponseModalOpen: true
                    })

                    this.clearData();
                    this.renderDataFromServer();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.closeAPIResponse();
                }, 1000)

            }
        );
    }
    getRowById(id) {
        HttpAPI.getCompanyById(id).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState(
                        {
                            companyCode: response.data.companyCode,
                            companyName: response.data.description,
                            updateRow: response.data,
                            id: response.data.id

                        }
                    );
                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: false
                    })

                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)


            }
        );
    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    createCompany() {
        let data = {
            'companyCode': this.state.companyCode,
            'description': this.state.companyName
        };
        let senddata = ApiUtils.appendCreateRequiredField(data);
        HttpAPI.createCompany(senddata).then(
            response => {
                var response = response.data;
                if (response.status === true) {

                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'Company Insert Successfully',
                        apiResponseModalOpen: true
                    })
                    this.clearData();
                    this.renderDataFromServer();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)

            }
        );
    }
    UpdateCompany() {
        console.log(this.state.updateRow)
        let data = {
            'id': this.state.updateRow.id,
            'companyCode': this.state.companyCode,
            'description': this.state.companyName,
            'active': this.state.updateRow.active,
            'createdAt': this.state.updateRow.createdAt,
            'createdBy': this.state.updateRow.createdBy,
        };
        let senddata = ApiUtils.appendUpdateRequiredField(data);
        HttpAPI.UpdateCompany(senddata).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: 'Company Update Successfully',
                        apiResponseModalOpen: true
                    })
                    this.clearData();
                    this.renderDataFromServer();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)

            }
        );
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    globalSearch = (searchInput) => {
        let filteredData = this.state.companydata.filter(value => {
            return (
                (value.description!==null && value.description.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.companyCode!==null && value.companyCode.toLowerCase().includes(searchInput.toLowerCase()))
                );
            });
            this.setState({ filterCompanydata: filteredData }); 
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    saveData = () => {
        if (this.validateData() === true) {
            if (this.state.id > 0) {
                this.UpdateCompany();
            } else {
                this.createCompany();
            }
        } 
    }
    clearData = () => {
        this.setState({
            companyCode: '',
            companyName: '',
            updateRow: {},
            id: 0,
            errorMap: {'companyCode': { 'valid': true, 'error': '' },
            'companyName': { 'valid': true, 'error': '' },
        }
        })
    }
    validateData() {
        let map = {
            'companyCode': { 'valid': true, 'error': '' },
            'companyName': { 'valid': true, 'error': '' },
        }
        let isValid = true;
        if (this.state.companyCode === '') {
            //this.setState({ apiResponseMsg: 'Please Fill Code' })
            map.companyCode.valid = false;
            map.companyCode.error = 'Please Fill Code'
            isValid = false
        }
        if (this.state.companyName === '') {
            //this.setState({ apiResponseMsg: 'Please Fill Descripation' })
            map.companyName.valid = false;
            map.companyName.error = 'Please Fill Descripation'
            this.companyInput.current.focus()
            isValid = false
        }
        this.setState({errorMap:map})
        return isValid;
    }
    render() {
        const columns = [
            {
                id: "1",
                Header:()=>(
                    <div className="bgcolor">
                        Code
                    </div>

                ) ,
                accessor: "companyCode",
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "2",
                Header:()=>(
                    <div className="bgcolor">
                            Company Name
                    </div>
                ),
                accessor: "description",
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "3",
                Header:()=>(
                    <div className="bgcolor">
                        Actions
                    </div>
                ),
                accessor: "id",
                sortable: false,
                style: {
                    height:35,
                    textAlign: 'Center',

                },
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className="" icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.getRowById(props.value)} />
                                <MDBIcon className="pl-3" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.value)} />
                            </div>
                        </div>
                    );
                }
                
            }
        ]
        return (
            <div>
                 <MDBRow>
                
                <div className="col-sm-9 d-flex flex-row-reverse">
                <CustomBreadCrumb items={['Master','Company']} linkTo={['', '']}/>
                </div>
               
                </MDBRow>
                <MDBRow>
                <MDBCol lg="3">

                </MDBCol>
                <MDBCol lg="6">
                
                    
                   
                        <MDBCard className="mb-5 mr-0 ">
                            <MDBCardBody className="align-items-center justify-content-between  ">
                                <div className="row">
                                     <div className="form-group col-lg-2 col-xl-2 col-md-2 col-sm-12 col-xs-12">
                                     <label for="code" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Code</label>
                                     <input type="text" disabled name="companyCode" value={this.state.companyCode} id="code" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                        </input>
                                        <span className='error-message'>{this.state.errorMap.companyCode.valid == true ? '' : this.state.errorMap.companyCode.error}</span>
                                     </div>
                                     <div className="form-group col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12 selectstate companyleftmargin  ">
                                     <label for="company" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Company Name</label>
                                     <input type="text" name="companyName" ref={this.companyInput} value={this.state.companyName} id="company" className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar}>
                                        </input>
                                        <span className='error-message'>{this.state.errorMap.companyName.valid == true ? '' : this.state.errorMap.companyName.error}</span>
                                     </div>
                                     <div className="form-group col-lg-5 col-xl-3 col-md-5 col-sm-12 col-xs-12 companyleftmargin  selectstate companymargin">
                                     <MDBBtn size='md' onClick={this.saveData} className="companysavebutton">
                                        <span className="Savedata">
                                            Add New
                                            </span>
                                        </MDBBtn>
                                        </div>
                                        <div className="form-group col-lg-1 col-xl-3 col-md-1 companyleftmargin  selectstate companymargin">
                                        <MDBBtn size='md' onClick={this.clearData} className="companycancelbutton">
                                        <span className="Savedata">
                                            Cancel
                                            </span>
                                        </MDBBtn>
                                     </div>
                                     
                                </div>
                                
                               
                                <div className="input-group md-form form-sm form-2 pl-0  countrysearchicon">
                                <input className="form-control my-0 py-0  lime-border" type="text"  onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                <div className="input-group-append">
                                <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                aria-hidden="true"></i></span>
                                </div>
                               </div>
                               
                                <MDBRow className="justify-content-left districttable" >
                                    <MDBCol lg="12" className="mb-3">
                                        <ReactTable
                                            resizable={false}
                                            minRows={1}
                                            showPagination={true}
                                            PaginationComponent={Pagination}
                                            defaultPageSize={15}
                                            style={{
                                                // This will force the table body to overflow and scroll, since there is not enough room
                                            }}
                                            data={this.state.filterCompanydata}
                                            columns={columns} />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    
                    <APIResponse
                        isOpen={this.state.apiResponseModalOpen}
                        response={this.state.apiResponse}
                        msg={this.state.apiResponseMsg}
                        closeAPIResponse={this.closeAPIResponse}
                    />
                <MDBCol lg="3">
                
                </MDBCol>
                </MDBRow>
            </div>);
    }
}
export default CompanyPage;