import React, { Component } from "react";
import { MDBTabContent, MDBFormInline, MDBIcon, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBRow, MDBBtn, MDBInput, MDBBreadcrumb, MDBCard, MDBCardBody, MDBBreadcrumbItem, MDBCol } from 'mdbreact';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import HttpAPI from '../common/api_calls';
import ApiUtils from '../common/api_utils';
import DateRangePickerModal from '../common/DateRangePickerModal'
import PurchaseDetail from './modal/PurchaseDetail'
import * as moment from 'moment';
import CustomBreadCrumb from '../pages/sections/CustomBreadCrumb'
import Pagination from "../common/Pagination";
import Select from 'react-select';
import "./Styleing.css";

class SellPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sellData: [],
            filterSellData: [],
            startDate: null,
            endDate: null,
            dateLabel: '',
            sellNumber:'',
            contact:'',
            rcSubmit:'',
            selectedsellNumber: '',
            selectedcontact: '',
            selectedrcSubmit: '',
            sellNumberOption: [{  name: 'sellNumber', label: '', value:''}],
            contactOption: [{  name: 'contact', label: '', value: '' }],
            rcSubmitOption: [{  name: 'rcSubmit', label: 'Yes', value: 'Yes' },{  name: 'rcSubmit', label: 'No', value: 'No' }],
        }

    }
    componentDidMount() {
        this.renderDataFromServer()
    }
    setSelectInputVar=(e)=>{
        this.setState({
            [e.name ]: e.value,
            ['selected' + e.name]: e
        });
    }
    renderDataFromServer() {
HttpAPI.getSellData().then(
    response => {
        var response = response.data;
        if(response.status===true){
            this.setState({
                sellData:response.data,
                filterSellData:response.data 
            })
            let sellCodeArr=[]
            let UniqContactArr=[]
            response.data
            .map(row=>{
                
                sellCodeArr.push({  name: 'sellNumber', label: row.sellCode, value: row.sellCode })
                if (UniqContactArr.indexOf(row.contactMST.firstName) === -1) {
                    UniqContactArr.push(row.contactMST.firstName)
                }
            })
            let contactArr=[]
            UniqContactArr.map(row=>{
                contactArr.push({name: 'contact', label: row, value: row})
            })
            this.setState({sellNumberOption:sellCodeArr,contactOption:contactArr})

        }
    })
    }
    onToggleDateControl=()=>{
        this.setState({isOpenDateRange:true})
    }
    hangleDateRange=(selectedStartDate,selectedEndDate)=> {
        
        this.setState({
            startDate:selectedStartDate,
            endDate:selectedEndDate,
            isOpenDateRange:false,
            dateLabel:moment(selectedStartDate).format('DD/MM/YYYY').toString() + '-'+moment(selectedEndDate).format('DD/MM/YYYY').toString()
        })
    }
    handleSearchChange = event => {
        this.globalSearch(event.target.value);
    };
    openAddSell=()=>{
        window.localStorage.setItem('editsellId', 0)
        window.location.href = '/addSell'
    }
    editSell=(id)=>{
        window.localStorage.setItem('editsellId', id)
        window.location.href = '/addSell'
    }
    viewSell=()=>{

    }
    searchClick=()=>{
        let criteria = ''
        if(this.state.sellNumber!==''){
            criteria = criteria + 'sell_code='+this.state.sellNumber
        }
        if(this.state.contact!==''){
            criteria = criteria + 'customer_name='+this.state.contact
        }
        if(this.state.rcSubmit!==''){
            if(this.state.rcSubmit==='Yes'){
                criteria = criteria + 'rc_given=true'
            }else{
                criteria = criteria + 'rc_given=false'
            }
        }
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        if (this.state.startDate !== null) {
            criteria = criteria + "&sell_from=" + (new Date(this.state.startDate - tzoffset)).toISOString().slice(0, -1)
        }
        if (this.state.endDate !== null) {
            criteria = criteria + "&sell_to=" + (new Date(this.state.endDate - tzoffset)).toISOString().slice(0, -1)
        }
        HttpAPI.getSellFilter(criteria).then(response => {
            var response = response.data;
            if(response.status===true){
             this.setState({
                sellData:response.data,
                filterSellData:response.data
             })   
            }
        })
    }
    addReceipt = (props) => {
        window.localStorage.setItem('editReceiptId',0)
        window.localStorage.setItem('receiptSellId', props.original.id);
        window.location.href = '/addReceipt'
    }
    clearSearch=()=>{
        this.setState({
            startDate: null,
            endDate: null,
            dateLabel: '',
            sellNumber:'',
            contact:'',
            rcSubmit:'',
            selectedsellNumber: '',
            selectedcontact: '',
            selectedrcSubmit: '',
        })
        this.renderDataFromServer()
    }
    deleteRowById=(id)=>{
        HttpAPI.deleteSellById(id).then(
            response => {
                this.renderDataFromServer();
            });
    }
    globalSearch = (searchInput) => {
        let filteredData = this.state.sellData.filter(value => {
            return (
                (value.sellDate !== null && moment(value.sellDate).format('DD/MM/YYYY').toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseMST !== null && value.purchaseMST.purchaseNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.sellCode !== null && value.sellCode.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.sellFor == null && value.sellFor.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseMST !== null && value.purchaseMST.companyMST.description.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseMST !== null && value.purchaseMST.modelMST.description.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseMST !== null && value.purchaseMST.bikeNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.contactMST !== null && value.contactMST.firstName.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.contactMST !== null && value.contactMST.mobileNumber.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.amount !== null && value.amount.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.recievedAmount !== null && value.recievedAmount.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.installments !== null && value.installments.toLowerCase().includes(searchInput.toLowerCase())) ||
                (value.purchaseMST !== null && (value.purchaseMST.rcMST.isSubmitted===true?'Yes':'No').toLowerCase().includes(searchInput.toLowerCase()))
            );
        });
        this.setState({ filterSellData: filteredData });
    }
    render() {
        const columns = [
            {
                id: "1",
                Header: () => (
                    <div className="bgcolor">
                        Date
                    </div>
                ),
                accessor: d => moment(d.sellDate).format('DD/MM/YYYY'),
                width: 100,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            /*
            {
                id: "2",
                Header: () => (
                    <div className="bgcolor">
                        Purchase Code
                    </div>
                ),
                accessor: 'purchaseMST.purchaseNumber',
                width: 150,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            */
            {
                id: "3",
                Header: () => (
                    <div className="bgcolor">
                        Sales Code
                    </div>
                ),
                accessor: 'sellCode',
                width: 120,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "4",
                Header: () => (
                    <div className="bgcolor">
                        Sales For
                    </div>
                ),
                accessor: 'sellFor',
                width: 80,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            /*
            {
                id: "5",
                Header: () => (
                    <div className="bgcolor">
                        Company
                    </div>
                ),
                accessor: 'purchaseMST.companyMST.description',
                width: 150,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            */
            {
                id: "6",
                Header: () => (
                    <div className="bgcolor">
                        Model
                    </div>
                ),
                accessor: 'purchaseMST.modelMST.description',
                width: 80,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "7",
                Header: () => (
                    <div className="bgcolor">
                        Bike No
                    </div>
                ),
                accessor: 'purchaseMST.bikeNumber',
                width: 120,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "8",
                Header: () => (
                    <div className="bgcolor">
                        Vendor Name
                    </div>
                ),
                accessor: 'contactMST.firstName',
                width: 105,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            /*
            {
                id: "9",
                Header: () => (
                    <div className="bgcolor">
                        Vendor Number
                    </div>
                ),
                accessor: 'contactMST.mobileNumber',
                width: 150,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            */
            {
                id: "10",
                Header: () => (
                    <div className="bgcolor">
                        Total Amt
                    </div>
                ),
                accessor: 'amount',
                width: 80,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "11",
                Header: () => (
                    <div className="bgcolor">
                        Paid Amt
                    </div>
                ),
                accessor: 'recievedAmount',
                width: 80,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "12",
                Header: () => (
                    <div className="bgcolor">
                        Due Amt
                    </div>
                ),
                accessor: d=>Number(d.amount)-Number(d.recievedAmount),
                width: 85,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
            {
                id: "13",
                Header: () => (
                    <div className="bgcolor">
                       EMI
                    </div>
                ),
                accessor: 'installments',
                width: 60,
                style: {
                    height:35,
                    textAlign: 'Center',

                },

            },
           
            {
                id: "14",
                Header: () => (
                    <div className="bgcolor">
                       RC Sub
                    </div>
                ),
                accessor:d=> d.purchaseMST.rcMST===null?'':d.purchaseMST.rcMST.submitted===true?'Yes':'No',
                width: 80,
                style: {
                    height:35,
                    textAlign: 'Center',

                },
            },
            {
                id: "15",
                Header: () => (
                    <div className="bgcolor">
                        Receipt
                    </div>
                ),

                sortable: false,
                width: 70,
                style: {
                    height:35,
                    textAlign: 'Center',

                },
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='book' style={{ cursor: 'pointer' }} onClick={() => this.addReceipt(props)} />
                            </div>
                        </div>
                    );
                }
            },{
                id: "16",
                Header: () => (
                    <div className="bgcolor">
                        Actions
                    </div>
                ),
                accessor: "id",
                width: 160,
                sortable: false,
                style: {
                    height:35,
                    textAlign: 'Center',

                },
                Cell: props => {

                    return (
                        <div>
                            <div>
                                <MDBIcon className=" m-2" icon='eye' style={{ cursor: 'pointer' }} onClick={() => this.viewSell(props.value)} />
                                <MDBIcon className=" m-2" icon='edit' style={{ cursor: 'pointer' }} onClick={() => this.editSell(props.value)} />
                                <MDBIcon className=" m-2" icon='trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteRowById(props.value)} />
                            </div>
                        </div>
                    );
                }
            }
        ]
        return (
            <div>
                <MDBRow>
                    
                    <div className="col-sm-12 d-flex flex-row-reverse">
                        <CustomBreadCrumb items={['General', 'Sales']} linkTo={['', '']} />
                    </div>
                </MDBRow>
                <MDBRow>
                    <MDBCol lg="12">
                        <MDBCard className="mb-6 mr-0 " >
                            <MDBCardBody className="align-items-center justify-content-between mr-0">
                                <div className="row">
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 ">
                                        <label for="sell" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Sales Number</label>
                                        <Select name="sellNumber" options={this.state.sellNumberOption} value={this.state.selectedsellNumber} onChange={this.setSelectInputVar} id="sell" class="input-field browser-default md-select form-control form-control-sm" >
                                        </Select>
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 purchasefor">
                                        <label for="vendor" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Vendor</label>
                                        <Select name="contact" options={this.state.contactOption} value={this.state.selectedcontact} onChange={this.setSelectInputVar} id="vendor" class="input-field browser-default md-select form-control form-control-sm" >
                                        </Select>
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 purchasefor ">
                                        <label for="rcSubmit" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >RC Submmited</label>
                                        <Select name="rcSubmit" options={this.state.rcSubmitOption} value={this.state.selectedrcSubmit} onChange={this.setSelectInputVar} id="rcSubmit" class="input-field browser-default md-select form-control form-control-sm" >
                                        </Select>
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 purchasefor ">
                                        <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Date Range</label>
                                        <input
                                            value={this.state.dateLabel} onClick={this.onToggleDateControl} className="form-control" />
                                    </div>
                                    <div className="form-group col-xl-1 col-lg-2 col-md-1 col-sm-12 col-xs-12 ">
                                        <MDBBtn className="  salessave btn-md " size='md' onClick={this.searchClick}><i className="fas fa-filter"></i><span className="Savedata pl-1 ">Apply filter</span></MDBBtn>
                                    </div>
                                    <div className="form-group col-xl-1 col-lg-2 col-md-1 ml-2 col-sm-12 col-xs-12">
                                        <MDBBtn className=" salescancel  " size='md' onClick={this.clearSearch}><i className="fas fa-undo-alt"></i><span className="Savedata pl-1">  Clear Filter</span></MDBBtn>
                                    </div>
                                    <DateRangePickerModal isOpen={this.state.isOpenDateRange} hangleDateRange={this.hangleDateRange} />
                                </div>
                                <div className="row purchasesearchicon">
                            <div className="input-group md-form form-sm form-2 col-xl-11 col-lg-10 col-md-10 col-sm-12 col-xs-12 ">
                                <input className="form-control my-0 py-0  lime-border" type="text" onChange={this.handleSearchChange} placeholder="Search" aria-label="Search"></input>
                                <div className="input-group-append">
                                    <span className="input-group-text gray lighten-2" id="basic-text1"><i className="fas fa-search text-grey"
                                        aria-hidden="true"></i></span>
                                </div>
                                
                            </div>
                            
                            <div className="form-group col-xl-1 col-lg-2 col-md-2 col-sm-12 col-xs-12 ">
                            <MDBBtn className="mt-3 purchaseaddnew" size='md' onClick={this.openAddSell}><span className="Savedata">Add New </span></MDBBtn>
                                </div>       
                            </div>
                            <MDBRow className="justify-content-left   ">
                                <MDBCol lg="12"  >
                                    <ReactTable
                                        resizable={false}
                                        minRows={1}
                                        noDataText="NO DATA FOUND"
                                        showPagination={true}
                                        PaginationComponent={Pagination}
                                        defaultPageSize={25}

                                        style={{
                                            // This will force the table body to overflow and scroll, since there is not enough room
                                        }}
                                        data={this.state.filterSellData}
                                        columns={columns} />
                                </MDBCol>
                            </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }
}
export default SellPage