import React, { Component } from "react";
import { MDBContainer, MDBFormInline, MDBModalBody, MDBModal, MDBInput, MDBCol, MDBRow, MDBCard, MDBCardBody, MDBBreadcrumb, MDBBreadcrumbItem, MDBBtn, MDBIcon } from "mdbreact";
import DatePicker from "react-datepicker";
import APIResponse from '../../common/APIResponse'
import HttpAPI from '../../common/api_calls';
import ApiUtils from '../../common/api_utils';
import Pagination from "../../common/Pagination";
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import CustomBreadCrumb from '../sections/CustomBreadCrumb'
import Select from 'react-select';
import * as moment from 'moment';
class AddReceipt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            bikeNo: '',
            contactName: '',
            contactId: 0,
            code: '',
            totalPayment: 0,
            remainingPayment: 0,
            paidPayment: 0,
            sellId: 0,
            id: 0,
            payableAmount: '',
            companyName: '',
            modelName: '',
            modeOfPayment: '',
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            editReceipt: {},
            partyReceiptData: [],
            selectedSell:{},
            modeOfPaymentOption: ApiUtils.getModeOfPaymentOption(),
            selectedmodeOfPayment: { name: 'modeOfPayment', label: "", value: "" },
            partyVoucherLabel:'Add Receipt',
            errorMap: {
                'bikeNo': { 'valid': true, 'error': '' },
                'contact': { 'valid': true, 'error': '' },
                'code': { 'valid': true, 'error': '' },
                'totalPayment': { 'valid': true, 'error': '' },
                'paidPayment': { 'valid': true, 'error': '' },
                'modeOfPayment': { 'valid': true, 'error': '' },
                'date': { 'valid': true, 'error': '' },
            }
        }
        this.bikeNumInput = React.createRef();
        this.contactInput = React.createRef();
        this.codeInput = React.createRef();
        this.totalPaymentInput = React.createRef();
        this.paidPaymentInput = React.createRef();
        this.modeOfPaymentInput = React.createRef();
        this.dateInput = React.createRef();
    }
    componentDidMount() {
        this.renderDataFromServer()
    }
    renderDataFromServer() {
        HttpAPI.getPartyReceiptCode().then(response => {
            var response = response.data;
            if (response.status === true) {
                this.setState({ code: response.data.code })

            }
        })
        
        if (window.localStorage.getItem('editReceiptId') !== null && window.localStorage.getItem('editReceiptId') > 0) {
            let id = window.localStorage.getItem('editReceiptId')
            this.setState({partyVoucherLabel:'Edit Receipt'})
            HttpAPI.getReceiptById(id).then(response => {
                var response = response.data;
                if (response.status === true) {
                    this.editData(response.data)
                    HttpAPI.getSellById(response.data.sellId).then(response => {
                        var response = response.data;
                        if (response.status === true) {
                            this.setState({
                                contactId: response.data.contactMST.id,
                                contactName: response.data.contactMST.firstName,
                                bikeNo: response.data.purchaseMST.bikeNumber,
                                totalPayment: response.data.amount,
                                companyName: response.data.purchaseMST.companyMST.description,
                                modelName: response.data.purchaseMST.modelMST.description,
                                selectedSell:response.data
                            })
                            
                        }
                    })
                    this.getReceiptBySellId(response.data.sellId)
                }
            });
        }
        
        if (window.localStorage.getItem('receiptSellId') !== null && window.localStorage.getItem('receiptSellId') > 0) {
            let pId = window.localStorage.getItem('receiptSellId')
            this.setState({ sellId: pId })
            HttpAPI.getSellById(pId).then(response => {
                var response = response.data;
                if (response.status === true) {
                    this.setState({
                        contactId: response.data.contactMST.id,
                        contactName: response.data.contactMST.firstName,
                        bikeNo: response.data.purchaseMST.bikeNumber,
                        totalPayment: response.data.amount,
                        companyName: response.data.purchaseMST.companyMST.description,
                        modelName: response.data.purchaseMST.modelMST.description,
                        selectedSell:response.data
                    })
                    this.getReceiptBySellId(pId)
                }
            })


        }
        this.calRemainingPayment(0)
    }
    getReceiptBySellId(pId) {
        HttpAPI.getReceiptBySellId(pId).then(response => {
            var response = response.data;
            if (response.status === true) {
                this.setState({ partyReceiptData: response.data })
                if (response.data.length > 0) {

                    let numbers = 0
                    response.data.map(row => {
                        if(row.id!==this.state.id){
                            numbers = Number(numbers) + Number(row.paidPayment)
                        }
                    });
                    numbers=numbers+Number(this.state.paidPayment)
                    if (numbers > 0) {
                        this.setState({ 
                            remainingPayment: Number(this.state.totalPayment) - Number(numbers)
                        })
                    } else {
                        this.setState({ remainingPayment: this.state.totalPayment })
                    }
                } else {
                    this.setState({ remainingPayment: this.state.totalPayment })
                }

            }
        })

    }
    calRemainingPayment(paid) {
        var numbers = this.state.partyReceiptData.map(n => n.paidPayment)
        var sum = 0
        for (let i = 0; i < numbers.length; i++) {
            sum = sum + numbers[i];
        }
        var remaining = (this.state.totalPayment - sum) - paid
        if (remaining > 0) {
            this.setState({
                remainingPayment: remaining
            })
        } else {
            this.setState({
                remainingPayment: this.state.totalPayment
            })
        }

    }
    editData = (voucherRecord) => {
        this.setState({
            bikeNo: voucherRecord.bikeNumber,
            code: voucherRecord.code,
            totalPayment: voucherRecord.totalPayment,
            remainingPayment: voucherRecord.remainingPayment,
            paidPayment: voucherRecord.paidPayment,
            
            date: new Date(voucherRecord.date),
            modeOfPayment: voucherRecord.modeOfPayment,
            contactId: voucherRecord.contactId,
            contactName: voucherRecord.contactMST.firstName,
            sellId: voucherRecord.sellId,
            editReceipt: voucherRecord,
            selectedmodeOfPayment: {
                name: 'modeOfPayment',
                label: voucherRecord.modeOfPayment,
                value: voucherRecord.modeOfPayment
            },
            id: voucherRecord.id
        })
    }
    setInputVar = (e) => {
        if (e.target.name === 'paidPayment') {
            if (this.state.partyReceiptData.length > 0) {
                let totalPaid = 0
                this.state.partyReceiptData.map(row =>{
                    if(row.id!==this.state.id){
                    totalPaid = Number(totalPaid) + Number(row.paidPayment)
                    }
            })
                console.log(Number(totalPaid))
                let remaing = Number(this.state.totalPayment) - (Number(totalPaid) + Number(e.target.value))
                
                this.setState({ remainingPayment: remaing })
            } else {
                let remaing = this.state.totalPayment - e.target.value
                this.setState({ remainingPayment: remaing })
            }
        }
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    changeSelectVar = (e) => {
        this.setState({
            [e.name]: e.value,
            ['selected' + e.name]: e
        });
    }
    handleDateChange = (e) => {
        this.setState({ date: e })
    }
    clearData = () => {
        
        this.setState({
            date: new Date(),
            bikeNo: '',
            contactName: '',
            contactId: 0,
            code: '',
            totalPayment: 0,
            remainingPayment: 0,
            companyName: '',
            modelName: '',
            paidPayment: 0,
            sellId: 0,
            id: 0,
            payableAmount: '',
            modeOfPayment: '',
            selectedmodeOfPayment: '',
            editReceipt: {},
            errorMap: {
                'bikeNo': { 'valid': true, 'error': '' },
                'contact': { 'valid': true, 'error': '' },
                'code': { 'valid': true, 'error': '' },
                'totalPayment': { 'valid': true, 'error': '' },
                'paidPayment': { 'valid': true, 'error': '' },
                'modeOfPayment': { 'valid': true, 'error': '' },
                'date': { 'valid': true, 'error': '' },
            }
        })
    }
    closeAddForm = () => {
        window.localStorage.setItem('editReceiptId', 0)
        window.localStorage.setItem('receiptSellId', 0)
        this.clearData()
    }
    createVoucher = () => {
        let data = {
            'bikeNumber': this.state.bikeNo,
            'code': this.state.code,
            'totalPayment': this.state.totalPayment,
            'remainingPayment': this.state.remainingPayment,
            'paidPayment': this.state.paidPayment,
            'payableAmount': this.state.payableAmount,
            'date': this.state.date,
            'modeOfPayment': this.state.modeOfPayment,
            'contactId': this.state.contactId,
            'sellId': this.state.sellId,
            'isSellReceipt':true
        }
        let senddata = ApiUtils.appendCreateRequiredField(data)
        HttpAPI.createReceipt(senddata).then(response => {
            var response = response.data;
            if (response.status === true) {
                
                this.setState({
                    apiResponse: true,
                    apiResponseMsg: 'Receipt inserted successfully',
                    apiResponseModalOpen: true
                })
                window.localStorage.setItem('editReceiptId',0)
                window.localStorage.setItem('receiptSellId',this.state.sellId)
                this.clearData()
                this.renderDataFromServer();
            } else {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: response.message,
                    apiResponseModalOpen: true
                })
            }
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 1000)

        });
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    updateVoucher = () => {
        let data = {
            'id': this.state.id,
            'bikeNumber': this.state.bikeNo,
            'code': this.state.code,
            'totalPayment': this.state.totalPayment,
            'remainingPayment': this.state.remainingPayment,
            'paidPayment': this.state.paidPayment,
            'payableAmount': this.state.payableAmount,
            'date': this.state.date,
            'modeOfPayment': this.state.modeOfPayment,
            'contactId': this.state.contactId,
            'sellId': this.state.sellId,
            'createdAt': this.state.editReceipt.createdAt,
            'createdBy': this.state.editReceipt.createdBy,
            'active': this.state.editReceipt.active,
            'isSellReceipt':true
        }
        let senddata = ApiUtils.appendUpdateRequiredField(data)
        HttpAPI.updateReceipt(senddata).then(response => {
            var response = response.data;
            if (response.status === true) {
                this.setState({
                    apiResponse: true,
                    apiResponseMsg: 'Receipt updated successfully',
                    apiResponseModalOpen: true
                })
                window.localStorage.setItem('editReceiptId',0)
                window.localStorage.setItem('receiptSellId',this.state.sellId)
                this.clearData()
                this.renderDataFromServer();
                

            } else {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: response.message,
                    apiResponseModalOpen: true
                })
            }
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 1000)

        });
    }
    saveData = () => {
        if (this.validateData() === true) {
            if (this.state.id > 0) {
                this.updateVoucher();
            } else {
                this.createVoucher()
            }

        }
    }
    validateData = () => {
        let map = {
            'bikeNo': { 'valid': true, 'error': '' },
            'contact': { 'valid': true, 'error': '' },
            'code': { 'valid': true, 'error': '' },
            'totalPayment': { 'valid': true, 'error': '' },
            'paidPayment': { 'valid': true, 'error': '' },
            'modeOfPayment': { 'valid': true, 'error': '' },
            'date': { 'valid': true, 'error': '' },
        }
        let isValid = true;
        if (this.state.date === null) {
            map.date.valid = false
            map.date.error = 'Please fill Date'
            if (isValid === true) {
                this.dateInput.current.focus()
            }
            isValid = false;
        }
        if (this.state.bikeNo === '') {
            
            map.bikeNo.valid = false
            map.bikeNo.error = 'Please fill Bike No'
            this.bikeNumInput.current.focus()
            isValid = false;
        }
        if (this.state.contactName === '' || this.state.contactName === 'select customer') {
            map.contact.valid = false
            map.contact.error = 'Please select customer'
            if (isValid === true) {
                this.contactInput.current.focus()
            }
            isValid = false;
        }
        if (this.state.code === '') {
            map.code.valid = false
            map.code.error = 'Please fill Code'
            if (isValid === true) {
                this.codeInput.current.focus()
            }
            isValid = false;
        }
        if (this.state.totalPayment === '') {
            map.totalPayment.valid = false
            map.totalPayment.error = 'Please fill Total Payment'
            if (isValid === true) {
                this.totalPaymentInput.current.focus()
            }
            isValid = false;
        }
        if (this.state.paidPayment === '') {
            
            map.paidPayment.valid = false
            map.paidPayment.error = 'Please fill Paid Payment'
            if (isValid === true) {
                this.paidPaymentInput.current.focus()
            }
            isValid = false;
        }
        if (this.state.remainingPayment < 0) {
            map.paidPayment.valid = false
            map.paidPayment.error = 'Please have entered higher than remaining'
            if (isValid === true) {
                this.paidPaymentInput.current.focus()
            }
            isValid = false;
        }

        if (this.state.modeOfPayment === '') {
            map.modeOfPayment.valid = false
            map.modeOfPayment.error = 'Please fill Mode Of Payment'
            if (isValid === true) {
                this.modeOfPaymentInput.current.focus()
            }
            isValid = false;
        }
        this.setState({ errorMap: map })
        return isValid;
    }

    render() {
        const columns = [
            {
                id: "1",
                Header: () => (
                    <div className="bgcolor">
                        Code
                    </div>
                ),
                accessor: "code",
                maxWidth: 150,
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
            },
            {
                id: "2",
                Header: () => (
                    <div className="bgcolor">
                        Date
                    </div>
                ),
                accessor: d => moment(d.date).format('DD/MM/YYYY'),
                maxWidth: 150,
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
            },
            {
                id: "3",
                Header: () => (
                    <div className="bgcolor">
                        Vendor Name
                    </div>
                ),
                accessor: d => d.contactMST.contactType + '-' + d.contactMST.firstName + ' ' + d.contactMST.lastName,
                maxWidth: 150,
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
            },
            {
                id: "4",
                Header: () => (
                    <div className="bgcolor">
                        Company
                    </div>
                ),
                accessor: d => d.sellMST.purchaseMST.companyMST.description,
                maxWidth: 150,
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
            },
            {
                id: "4",
                Header: () => (
                    <div className="bgcolor">
                        Model
                    </div>
                ),
                accessor: d => d.sellMST.purchaseMST.modelMST.description,
                maxWidth: 150,
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
            },
            {
                id: "5",
                Header: () => (
                    <div className="bgcolor">
                        Bike No
                    </div>
                ),
                accessor: "bikeNumber",
                maxWidth: 150,
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },

            },


            {
                id: "6",
                Header: () => (
                    <div className="bgcolor">
                        Total Payment
                    </div>
                ),
                accessor: "totalPayment",
                maxWidth: 150,
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
            },
            {
                id: "7",
                Header: () => (
                    <div className="bgcolor">
                        Paid Payment
                    </div>
                ),
                accessor: "paidPayment",
                maxWidth: 150,
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
            },
            {
                id: "8",
                Header: () => (
                    <div className="bgcolor">
                        Remaining Payment
                    </div>
                ),
                accessor: "remainingPayment",
                maxWidth: 150,
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
            },


            {
                id: "9",
                Header: () => (
                    <div className="bgcolor">
                        Mode Of Payment
                    </div>
                ),
                accessor: "modeOfPayment",
                maxWidth: 150,
                width: 150,
                style: {
                    height: 35,
                    textAlign: 'Center',

                },
            }
        ]
        return (<div>
            <MDBRow className="justify-content-left ml-0" >
                <MDBCol sm="3">

                </MDBCol>

                <MDBCol sm="3">

                </MDBCol>
                <div className="CustomBreadCrumbaddvoucher">
                    <CustomBreadCrumb items={['Account', 'Receipt', this.state.partyVoucherLabel]} linkTo={['', '/receipt', '']} />
                </div>
            </MDBRow>
            <MDBRow className="justify-content-left ml-0 ">
                <MDBCol lg="12">
                    <MDBCard className="mb-5">
                        <MDBCardBody className="align-items-center justify-content-between">
                            <div className="row">
                                <div className="form-group col-sm-1">
                                    <label for="code" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Code</label>
                                    <input type="text" disabled ref={this.codeInput} name="code" value={this.state.code} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="code"></input>
                                    <span className='error-message'>{this.state.errorMap.code.valid == true ? '' : this.state.errorMap.code.error}</span>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Date</label>
                                    <DatePicker
                                        selected={this.state.date}
                                        onChange={this.handleDateChange}
                                        placeholderText='Date'
                                        className="form-control"
                                        disabled
                                        ref={this.dateInput}
                                    />
                                    <span className='error-message'>{this.state.errorMap.date.valid == true ? '' : this.state.errorMap.date.error}</span>
                                </div>

                                <div className="form-group col-sm-2">
                                    <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Vendor Name</label>
                                    <input disabled type="text" name="contactName" value={this.state.contactName} className="form-control form-text font-small m-0 p-0" id="bikeno"></input>
                                    <span className='error-message'>{this.state.errorMap.contact.valid == true ? '' : this.state.errorMap.contact.error}</span>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Company</label>
                                    <input disabled type="text" name="companyName" value={this.state.companyName} className="form-control form-text font-small m-0 p-0" id="bikeno"></input>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Model</label>
                                    <input disabled type="text" name="modelName" value={this.state.modelName} className="form-control form-text font-small m-0 p-0" id="bikeno"></input>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="bikeno" style={{ 'font-size': '0.7rem', 'color': 'grey' }} > Bike No</label>
                                    <input disabled type="text" ref={this.bikeNumInput} name="bikeNo" value={this.state.bikeNo} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="bikeno"></input>
                                    <span className='error-message'>{this.state.errorMap.bikeNo.valid == true ? '' : this.state.errorMap.bikeNo.error}</span>
                                </div>

                            </div>
                            <div className="row">
                                <div className="form-group col-sm-2">
                                    <label for="totalPayment" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Total Payment</label>
                                    <input type="number" name="totalPayment" ref={this.totalPaymentInput} value={this.state.totalPayment} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="totalPayment"></input>
                                    <span className='error-message'>{this.state.errorMap.totalPayment.valid == true ? '' : this.state.errorMap.totalPayment.error}</span>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="paidPayment" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Paid Payment</label>
                                    <input type="number" name="paidPayment" ref={this.paidPaymentInput} value={this.state.paidPayment} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="paidPayment"></input>
                                    <span className='error-message'>{this.state.errorMap.paidPayment.valid == true ? '' : this.state.errorMap.paidPayment.error}</span>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="remainingPayment" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Remaning Payment</label>
                                    <input disabled type="number" name="remainingPayment" value={this.state.remainingPayment} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" id="remainingPayment"></input>
                                </div>
                                <div className="form-group col-sm-2">
                                    <label for="modeofpayment" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Mode Of Payment</label>
                                    <Select name="modeOfPayment" ref={this.modeOfPaymentInput} options={this.state.modeOfPaymentOption} value={this.state.selectedmodeOfPayment} onChange={this.changeSelectVar} id="purchase" class="input-field browser-default md-select form-control form-control-sm" >
                                    </Select>
                                    <span className='error-message'>{this.state.errorMap.modeOfPayment.valid == true ? '' : this.state.errorMap.modeOfPayment.error}</span>
                                </div>
                            </div>



                            <MDBRow className="SaveCancelbutton mt-1">
                                <MDBCol lg='6'>
                                    <MDBBtn size='sm' onClick={this.saveData}>
                                        <span className="Savedata">
                                            Save
                                    </span>
                                    </MDBBtn>
                                    <MDBBtn size='sm' onClick={this.closeAddForm}>
                                        <span className="Savedata">
                                            Cancel
                                    </span>
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="justify-content-left ml-0 mx-auto mt-3">
                                <MDBCol lg="12"  >
                                    <ReactTable
                                        resizable={false}
                                        minRows={1}
                                        noDataText="NO DATA FOUND"
                                        showPagination={true}
                                        PaginationComponent={Pagination}
                                        defaultPageSize={15}
                                        style={{
                                            // This will force the table body to overflow and scroll, since there is not enough room
                                        }}
                                        data={this.state.partyReceiptData}
                                        columns={columns} />
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <APIResponse
                    isOpen={this.state.apiResponseModalOpen}
                    response={this.state.apiResponse}
                    msg={this.state.apiResponseMsg}
                    closeAPIResponse={this.closeAPIResponse}
                />
            </MDBRow> </div>);
    }

}
export default AddReceipt;