import React, { Component } from 'react';

import { MDBRow, MDBFormInline, MDBIcon, MDBInput, MDBCard, MDBTabContent, MDBTabPane, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCardBody, MDBBreadcrumbItem, MDBCol } from 'mdbreact';

import DatePicker from "react-datepicker";
import APIResponse from '../../common/APIResponse'
import HttpAPI from '../../common/api_calls';
import ApiUtils from '../../common/api_utils';
import CustomCheckBox from '../sections/CustomCheckBox'
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import "../Styleing.css";

import CustomBreadCrumb from '../sections/CustomBreadCrumb'
class AddPurchaseModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            purchaseDt: new Date(),
            purchaseNo: '',
            purchaseFor: '',
            companyData: [],
            companyId: 0,
            companyName: '',
            modelData: [],
            modelId: 0,
            modelName: '',
            filterModel: [],
            purchaseFromType: '',
            purchaseAmount: '',
            bikeNo: '',
            engineNo: '',
            chasisNo: '',
            rtoDetail: '',
            parsingDt: null,
            rcbookReceived: false,
            isFinance: false,
            alldocReceived: '',
            purchaseId: 0,
            editRecord: {},
            openRCModal: false,
            rcId: 0,
            addressProof: '',
            idProof: '',
            selecteddata: {},
            modelYear: '',
            contactData: [],
            contactName: '',
            contactId: 0,
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            MobileNo: '',
            selectedForm35Copy:'',
            form352Copy: false,
            form351Copy: false,
            noc: false,
            kyc: false,
            addressData: [],
            addressName: '',
            addressTypeName: '',
            addressTypeId: 0,
            isSold:false,
            loanAccountNo: '',
            selectedcompany: '',
            selectedmodel: '',
            selectedpurchaseFor: '',
            selectedcontact: '',
            selectedalldocReceived: '',
            selectedaddressType: '',
            companyOption: [{ id: 0, name: 'company', label: "", value: "" }],
            modelOption: [{ id: 0, name: 'model', label: "", value: "" }],
            purchaseForOption: [{ name: 'purchaseFor', label: 'Retail', value: 'Retail' }, { name: 'purchaseFor', label: 'Scrap', value: 'Scrap' }],
            contactOption: [{ id: 0, name: 'contact', label: "", value: "" }],
            allDocReceivedOption: [{ name: 'alldocReceived', label: 'Yes', value: 'Yes' }, { name: 'alldocReceived', label: 'No', value: 'No' }],
            addressOption: [{ id: 0, name: 'address', label: "", value: "" }],
            errorMap: {
                'purchaseFor': { 'valid': true, 'error': '' },
                'modelYear': { 'valid': true, 'error': '' },
                'contact': { 'valid': true, 'error': '' },
                'addressType': { 'valid': true, 'error': '' },
                'company': { 'valid': true, 'error': '' },
                'model': { 'valid': true, 'error': '' },
                'purchaseDt': { 'valid': true, 'error': '' },
                'bikeNo': { 'valid': true, 'error': '' },
                'engineNo': { 'valid': true, 'error': '' },
                'chasisNo': { 'valid': true, 'error': '' },
                'rtoDetail': { 'valid': true, 'error': '' },
            },

        }
        this.purchaseForInput = React.createRef();
        this.modelYearInput = React.createRef();
        this.contactInput = React.createRef();
        this.addressTypeInput = React.createRef();
        this.companyInput = React.createRef();
        this.modelInput = React.createRef();
        this.purchaseDate = React.createRef();
        this.bikeNumberInput = React.createRef();
        this.engineNumberInput = React.createRef();
        this.chasisNumberInput = React.createRef();
        this.rtoDetailInput = React.createRef();
    }
    closeaddrcmodel = (id) => {
        this.setState({ openRCModal: false })
        this.setState({ rcId: id })
    }
    openaddrcmodel = () => {
        if (this.validateData() === true) {
            if (this.state.rcId > 0) {
                window.localStorage.setItem('editRCId', this.state.rcId)
            } else {
                let data = {
                    'purchaseNumber': this.state.purchaseNo,
                    'bikeNumber': this.state.bikeNo,
                    'engineNumber': this.state.engineNo,
                    'chasisNumber': this.state.chasisNo,
                    'purchaseFor': this.state.purchaseFor,
                    'purchaseDate': this.state.purchaseDt,
                    'purchaseAmount': this.state.purchaseAmount,
                    'rtoDetail': this.state.rtoDetail,
                    'parsingDate': this.state.parsingDt,
                    'modelYear': this.state.modelYear,
                    'rcBookReceived': this.state.rcbookReceived,
                    'allDocsReceived': this.isAllDocReceived(),
                    'financed': this.state.isFinance,
                    'Form35': this.state.form351Copy,
                    'Form352Copy': this.state.form352Copy,
                    'kycDocs': this.state.kyc,
                    'noc': this.state.noc,
                }
                if (this.state.companyId > 0) {
                    let companyMST = { 'description': this.state.companyName }
                    let id = { 'companyId': this.state.companyId, 'companyMST': companyMST }
                    data = Object.assign(data, id)
                }
                if (this.state.contactId > 0) {
                    let contactMST = { 'firstName': this.state.contactName,'mobileNumber':this.state.MobileNo }
                    let id = { 'contactId': this.state.contactId, 'contactMST': contactMST }
                    data = Object.assign(data, id)
                }
                if (this.state.modelId > 0) {
                    let modelMST = { 'description': this.state.modelName }
                    let id = { 'modelId': this.state.modelId, 'modelMST': modelMST }
                    data = Object.assign(data, id)
                }
                window.localStorage.setItem('RCdata', JSON.stringify(data))
            }
            window.localStorage.setItem('openRCFrom', 'purchase')
            window.location.href = '/addRC'
        }
    }
    editPurchase = (editPurchase) => {
        console.log(editPurchase)
        this.setState({
            editRecord: editPurchase,
            isSold:editPurchase.sold,
            purchaseId: editPurchase.id,
            purchaseNo: editPurchase.purchaseNumber,
            purchaseFor: editPurchase.purchaseFor,
            contactId: editPurchase.contactId,
            contactName: editPurchase.contactMST.firstName,
            companyId: editPurchase.companyId,
            companyName: editPurchase.companyMST.description,
            modelId: editPurchase.modelId,
            modelName: editPurchase.modelMST.description,
            modelYear: editPurchase.modelYear,
            purchaseAmount: editPurchase.purchaseAmount,
            bikeNo: editPurchase.bikeNumber,
            engineNo: editPurchase.engineNumber,
            chasisNo: editPurchase.chasisNumber,
            rtoDetail: editPurchase.rtoDetail,
            rcbookReceived: editPurchase.rcBookReceived,
            alldocReceived: editPurchase.allDocsReceived,
            loanAccountNo:editPurchase.loanAccountNo,
            isFinance: editPurchase.financed,
            form351Copy: editPurchase.form35,
            form352Copy: editPurchase.form352Copy,
            kyc: editPurchase.kycDocs,
            noc: editPurchase.noc,
        })
        if(editPurchase.form35===true){
            this.setState({selectedForm35Copy:'1'})
        }else if(editPurchase.form352Copy===true){
            this.setState({selectedForm35Copy:'2'})
        }else{
            this.setState({selectedForm35Copy:''})
        }
        this.setState({
            selectedpurchaseFor: { name: 'purchaseFor', label: editPurchase.purchaseFor.charAt(0).toUpperCase() + editPurchase.purchaseFor.slice(1), value: editPurchase.purchaseFor.charAt(0).toUpperCase() + editPurchase.purchaseFor.slice(1) },
            selectedcompany: { id: editPurchase.companyId, name: 'company', label: editPurchase.companyMST.description, value: editPurchase.companyMST.description },
            selectedmodel: { id: editPurchase.modelId, name: 'model', label: editPurchase.modelMST.description, value: editPurchase.modelMST.description },
            selectedcontact: { id: editPurchase.contactId, name: 'contact', label: editPurchase.contactMST.contactType + '-' + editPurchase.contactMST.firstName + ' ' + editPurchase.contactMST.lastName, value: editPurchase.contactMST.firstName },
            selectedalldocReceived: { name: 'alldocReceived', label: editPurchase.allDocsReceived, value: editPurchase.allDocsReceived },
            selectedaddressType: { id: editPurchase.addressId, name: 'addressType', label: editPurchase.addressMST.addressType, value: editPurchase.addressMST.addressType },
            
        })
        if (editPurchase.rcId !== null) {
            this.setState({ rcId: editPurchase.rcId })
        }
        this.setState({
            purchaseDt: new Date(editPurchase.purchaseDate),
            parsingDt: new Date(editPurchase.parsingDate),

        })
        if (editPurchase.contactMST !== null) {
            let addressProof = editPurchase.contactMST.addressProof == null ? '' : editPurchase.contactMST.addressProof
            let addressProofNumber = editPurchase.contactMST.addressProofNumber == null ? '' : editPurchase.contactMST.addressProofNumber

            let idType = editPurchase.contactMST.idType == null ? '' : editPurchase.contactMST.idType
            let idNumber = editPurchase.contactMST.idNumber == null ? '' : editPurchase.contactMST.idNumber
            this.setState({
                MobileNo:editPurchase.contactMST.mobileNumber,
                addressProof: addressProof + '-' + addressProofNumber,
                idProof: idType + '-' + idNumber,
            })

            let addressFilter = this.state.addressData.filter(row => row.contactId === editPurchase.contactMST.id)
            var arr = []
            arr.push({ id: 0, name: 'addressType', label: '', value: '' })
            addressFilter.map((item, key) =>
                arr.push({
                    id: item.id, name: 'addressType',
                    label: item.addressType,
                    value: item.addressType
                })
            );
            this.setState({ addressOption: arr })
        }

        const data = this.state.modelData.filter(row => row.companyId === editPurchase.companyId);
        this.setState({
            filterModel: data
        });

    }
    setSelectInputVar = (e) => {
        this.setState({
            [e.name]: e.value,
            ['selected' + e.name]: e
        });
    }
    setAddressInputVar = (e) => {

        this.setState({
            [e.name + 'Name']: e.value,
            [e.name + 'Id']: e.id,
            ['selected' + e.name]: e
        });
        let data = this.state.addressData.filter(row => row.id === Number(e.id))[0]
        this.setState({
            addressName: data.addressName
        })
    }
    componentDidMount() {
        this.renderDataFromServer();
    }
    setFileInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.files[0],
            [e.target.name + 'Name']: e.target.files[0].name
        });
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    renderDataFromServer() {
       
        let id = window.localStorage.getItem('editPurchaseId');
        if (window.localStorage.getItem('openRCFromId') !== null && window.localStorage.getItem('openRCFromId') > 0) {
            this.setState({ rcId: window.localStorage.getItem('openRCFromId') })
            if (window.localStorage.getItem('RCdata') !== '') {
                this.editPurchase(JSON.parse(window.localStorage.getItem('RCdata')))
            }
        }
        HttpAPI.getAddress().then(response => {
            var response = response.data;
            this.setState({
                addressData: response.data
            })
        });
        HttpAPI.getContact().then(
            response => {
                var response = response.data;
                this.setState({
                    contactData: response.data
                })
                var arr = []
                arr.push({ id: 0, name: 'contact', label: '', value: '' })
                response.data.map((item, key) =>
                    arr.push({
                        id: item.id, name: 'contact',
                        label: item.contactType + '-' + item.firstName + ' ' + item.lastName,
                        value: item.firstName
                    })
                );
                this.setState({ contactOption: arr })
            }
        );
        HttpAPI.getAddress().then(
            response => {
                var response = response.data;
                this.setState({
                    addressData: response.data
                })
            }
        );

        HttpAPI.getPurchaseNo().then(response => {
            var response = response.data;
            if (response.status === true) {
                this.setState({ purchaseNo: response.data.purchaseNo })
            }
        });
        HttpAPI.getCompany().then(
            response => {
                var response = response.data;
                this.setState({
                    companyData: response.data
                })
                var arr = []
                arr.push({ id: 0, name: 'company', label: '', value: '' })
                response.data.map((item, key) => {
                    arr.push({ id: item.id, name: 'company', label: item.description, value: item.description })
                })
                this.setState({ companyOption: arr })
            }
        );
        HttpAPI.getModel().then(
            response => {
                var response = response.data;
                this.setState({
                    modelData: response.data
                })
            }
        );
        if (id > 0) {
            HttpAPI.getPurchaseById(id).then(
                response => {
                    var response = response.data;
                    if (response.status === true) {
                        this.editPurchase(response.data)
                    }
                }
            );
        }
    }

    toggle = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });

    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    changeSelectVar = (e) => {
        this.setState({
            [e.name + 'Id']: e.id,
            [e.name + 'Name']: e.value,
            ['selected' + e.name]: e
        });
    }
    changeSelectCustomerVar = (e) => {
        let id = e.id
        this.changeSelectVar(e)
        if (id > 0) {
            let filterRow = this.state.contactData.filter(row => row.id === Number(id))[0]
            this.setState({ MobileNo: filterRow.mobileNumber })
            let addressData = this.state.addressData.filter(row => row.contactId === Number(id))
            var arr = []
            arr.push({ id: 0, name: 'addressType', label: '', value: '' })
            addressData.map((item, key) =>
                arr.push({
                    id: item.id, name: 'addressType',
                    label: item.addressType,
                    value: item.addressType
                })
            );
            this.setState({ addressOption: arr })
        }
        if (id > 0) {
            let selectedCustomer = this.state.contactData.filter(row => row.id === Number(id))[0]
            let addressProof = selectedCustomer.addressProof == null ? '' : selectedCustomer.addressProof
            let addressProofNumber = selectedCustomer.addressProofNumber == null ? '' : selectedCustomer.addressProofNumber

            let idType = selectedCustomer.idType == null ? '' : selectedCustomer.idType
            let idNumber = selectedCustomer.idNumber == null ? '' : selectedCustomer.idNumber
            this.setState({
                addressProof: addressProof + '-' + addressProofNumber,
                idProof: idType + '-' + idNumber,
            })
        }
    }
    changeCompanyVar = (e) => {
        var filterId = Number(e.id);
        const data = this.state.modelData.filter(row => row.companyId === filterId);

        // this.setState({
        //     filterModel: data
        // });
        var arr = []
        arr.push({ id: 0, name: 'model', label: '', value: '' })
        data.map(item => {
            arr.push({ id: item.id, name: 'model', label: item.description, value: item.description })
        })
        console.log(arr)
        this.setState({
            selectedmodel: '',
            modelOption: arr,

        })
        this.changeSelectVar(e);
    }
    changeCompany = (e) => {

        this.changeSelectVar(e)
    }
    checkBoxChange = (e) => {

        if (e.target.name === 'isFinance') {
            if (e.target.checked === false) {
                
                this.setState({
                    selectedForm35Copy:'',
                    alldocReceived: false,
                    loanAccountNo: '',
                    form351Copy: false,
                    form352Copy: false,
                    noc: false,
                    kyc: false,
                })
            }else{
            this.setState({
                selectedForm35Copy:'1',
                form351Copy: true,
                    form352Copy: false,
            })
        }
        }
        this.setState({ [e.target.name]: e.target.checked });
    }
    handlePurchaseDateChange = (e) => {
        this.setState({ purchaseDt: e })

    }
    handleParsingDateChange = (e) => {
        this.setState({ parsingDt: e })

    }

    updatePurchase = () => {
        let data = {
            'id': this.state.purchaseId,
            'purchaseNumber': this.state.purchaseNo,
            'purchaseFor': this.state.purchaseFor,
            'purchaseDate': this.state.purchaseDt,
            'companyId': this.state.companyId,
            'modelId': this.state.modelId,
            'contactId': this.state.contactId,
            'purchaseAmount': this.state.purchaseAmount,
            'bikeNumber': this.state.bikeNo,
            'engineNumber': this.state.engineNo,
            'chasisNumber': this.state.chasisNo,
            'rtoDetail': this.state.rtoDetail,
            'addressId': this.state.addressTypeId,
            'addressName': this.state.addressName,
            'parsingDate': this.state.parsingDt,
            'rcBookReceived': this.state.rcbookReceived,
            'allDocsReceived': this.isAllDocReceived(),
            'form35': this.state.form351Copy,
            'form352Copy': this.state.form352Copy,
            'kycDocs': this.state.kyc,
            'noc': this.state.noc,
            'loanAccountNo': this.state.loanAccountNo,
            'modelYear': this.state.modelYear,
            'financed': this.state.isFinance,
            'active': this.state.editRecord.active,
            'createdAt': this.state.editRecord.createdAt,
            'createdBy': this.state.editRecord.createdBy,
        }
        let senddata = {}
        if (this.state.rcId > 0) {
            let rc = { 'rcId': this.state.rcId }
            senddata = Object.assign(rc, ApiUtils.appendUpdateRequiredField(data))
        } else {
            senddata = ApiUtils.appendUpdateRequiredField(data)
        }

        HttpAPI.updatePurchase(senddata).then(response => {
            var response = response.data;
            if (response.status === true) {
                this.setState({
                    apiResponse: true,
                    apiResponseMsg: response.message,
                    apiResponseModalOpen: true
                })
                this.closeAddForm();
            } else {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: response.message,
                    apiResponseModalOpen: true
                })
            }
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 1000)
        });
    }
    createPurchase = () => {
        let data = {
            'purchaseNumber': this.state.purchaseNo,
            'purchaseFor': this.state.purchaseFor,
            'purchaseDate': this.state.purchaseDt,
            'companyId': this.state.companyId,
            'modelId': this.state.modelId,
            'contactId': this.state.contactId,
            'purchaseAmount': this.state.purchaseAmount,
            'bikeNumber': this.state.bikeNo,
            'engineNumber': this.state.engineNo,
            'chasisNumber': this.state.chasisNo,
            'addressId': this.state.addressTypeId,
            'addressName': this.state.addressName,
            'rtoDetail': this.state.rtoDetail,
            'parsingDate': this.state.parsingDt,
            'form35': this.state.form351Copy,
            'form352Copy': this.state.form352Copy,
            'kycDocs': this.state.kyc,
            'loanAccountNo': this.state.loanAccountNo,
            'noc': this.state.noc,
            'modelYear': this.state.modelYear,
            'rcBookReceived': this.state.rcbookReceived,
            'allDocsReceived': this.isAllDocReceived(),
            'financed': this.state.isFinance,
        }
        let senddata = {}
        if (this.state.rcId > 0) {
            let rc = { 'rcId': this.state.rcId }
            senddata = Object.assign(rc, ApiUtils.appendCreateRequiredField(data))
        } else {
            senddata = ApiUtils.appendCreateRequiredField(data)
        }

        
        HttpAPI.createPurchase(senddata).then(response => {
            var response = response.data;
            if (response.status === true) {
                this.setState({
                    apiResponse: true,
                    apiResponseMsg: response.message,
                    apiResponseModalOpen: true
                })
                this.closeAddForm();
            } else {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: response.message,
                    apiResponseModalOpen: true
                })
            }
            window.setTimeout(() => {
                this.setState({
                    apiResponse: false,
                    apiResponseMsg: '',
                    apiResponseModalOpen: false
                })
            }, 1000)
        });

    }
    validateData = () => {
        let map = {
            'purchaseFor': { 'valid': true, 'error': '' },
            'modelYear': { 'valid': true, 'error': '' },
            'contact': { 'valid': true, 'error': '' },
            'addressType': { 'valid': true, 'error': '' },
            'company': { 'valid': true, 'error': '' },
            'model': { 'valid': true, 'error': '' },
            'purchaseDt': { 'valid': true, 'error': '' },
            'bikeNo': { 'valid': true, 'error': '' },
            'engineNo': { 'valid': true, 'error': '' },
            'chasisNo': { 'valid': true, 'error': '' },
            'rtoDetail': { 'valid': true, 'error': '' },
            
        }
        let isValid = true;
        if (this.state.purchaseDt === null) {
            //this.setState({ apiResponseMsg: 'Please select Purchase Date' })
            map.purchaseDt.valid = false;
            map.purchaseDt.error = 'Please select Purchase Date'
            if (isValid === true) {
                this.purchaseDate.current.focus()
            }
            isValid = false
        }
        if (this.state.purchaseFor === '') {
            //this.setState({ apiResponseMsg: 'Please select Purchase for' })
            map.purchaseFor.valid = false;
            map.purchaseFor.error = 'Please select Purchase for'
            if (isValid === true) {
                this.purchaseForInput.current.focus()
            }
            isValid = false
        }
        if (this.state.companyName === '' || this.state.companyName === 'select company') {
            //this.setState({ apiResponseMsg: 'Please select company' })
            map.company.valid = false;
            map.company.error = 'Please select company'
            if (isValid === true) {
                this.companyInput.current.focus()
            }
            isValid = false
        }

        if (this.state.modelName === '' || this.state.modelName === 'select model') {
            //this.setState({ apiResponseMsg: 'Please select model' })
            map.model.valid = false;
            map.model.error = 'Please select model'
            if (isValid === true) {
                this.modelInput.current.focus()
            }
            isValid = false
        }
        if (this.state.modelYear === '') {
            //this.setState({ apiResponseMsg: 'Please fill Model Year' })
            map.modelYear.valid = false;
            map.modelYear.error = 'Please fill Model Year'
            if (isValid === true) {
                this.modelYearInput.current.focus()
            }
            isValid = false
        } else if (ApiUtils.validateModelYear(this.state.modelYear) === false) {
            //this.setState({ apiResponseMsg: 'Please fill proper Model Year' })
            map.modelYear.valid = false;
            map.modelYear.error = 'Please fill proper Model Year'
            if (isValid === true) {
                this.modelYearInput.current.focus()
            }
            isValid = false
        }

        if (this.state.contactName === '' || this.state.contactName === 'Vendor/Dealer') {
            //this.setState({ apiResponseMsg: 'Please select Dealer/Vendor' })
            map.contact.valid = false;
            map.contact.error = 'Please select Dealer/Vendor'
            if (isValid === true) {
                this.contactInput.current.focus()
            }
            isValid = false
        }
        if (this.state.addressTypeName === '') {
            //this.setState({ apiResponseMsg: 'Please select Address Type' })
            map.addressType.valid = false;
            map.addressType.error = 'Please select Address Type'
            if (isValid === true) {
                this.addressTypeInput.current.focus()
            }
            isValid = false
        }

        if (this.state.bikeNo === '') {
            //this.setState({ apiResponseMsg: 'Please fill Bike No' })
            map.bikeNo.valid = false;
            map.bikeNo.error = 'Please fill Bike No'
            if (isValid === true) {
                this.bikeNumberInput.current.focus()
            }
            isValid = false
        } else if (ApiUtils.ValidateBikeNo(this.state.bikeNo) === false) {
            //this.setState({ apiResponseMsg: 'Please fill valid Bike No' })
            map.bikeNo.valid = false;
            map.bikeNo.error = 'Please fill valid Bike No'
            if (isValid === true) {
                this.bikeNumberInput.current.focus()
            }
            isValid = false
        }
        if (this.state.engineNo === '') {
            //this.setState({ apiResponseMsg: 'Please fill Engine No' })
            map.engineNo.valid = false;
            map.engineNo.error = 'Please fill Engine No'
            if (isValid === true) {
                this.engineNumberInput.current.focus()
            }
            isValid = false
        } else if (ApiUtils.validateEngineNo(this.state.engineNo) === false) {
            //this.setState({ apiResponseMsg: 'Please fill valid Engine No' })
            map.engineNo.valid = false;
            map.engineNo.error = 'Please fill valid Engine No'
            if (isValid === true) {
                this.engineNumberInput.current.focus()
            }
            isValid = false
        }
        if (this.state.chasisNo === '') {
            //this.setState({ apiResponseMsg: 'Please fill Chasis No' })
            map.chasisNo.valid = false;
            map.chasisNo.error = 'Please fill Chasis No'
            if (isValid === true) {
                this.chasisNumberInput.current.focus()
            }
            isValid = false
        } else if (ApiUtils.validateChasisNo(this.state.chasisNo) === false) {
            //this.setState({ apiResponseMsg: 'Please fill valid Chasis No' })
            map.chasisNo.valid = false;
            map.chasisNo.error = 'Please fill valid Chasis No'
            if (isValid === true) {
                this.chasisNumberInput.current.focus()
            }
            isValid = false
        }
        if (this.state.rtoDetail === '') {
            map.rtoDetail.valid = false;
            map.rtoDetail.error = 'Please fill RTO Detail'
            if (isValid === true) {
                this.rtoDetailInput.current.focus()
            }
            isValid = false
        }
        console.log('validate' + isValid)
        this.setState({ errorMap: map })
        return isValid;
    }
    saveData = () => {
        if (this.validateData() === true) {
            if (this.state.purchaseId > 0) {
                this.updatePurchase();
            } else {
                this.createPurchase()
            }
        }
    }

    closeAddForm = () => {

        this.clearData();
        this.renderDataFromServer();
        //this.props.closeAddPurchase();
    }
    clearData = () => {
        window.localStorage.setItem('editPurchaseId', 0)
        window.localStorage.setItem('openRCFromId', 0)
        this.setState({
            purchaseDt: new Date(),
            purchaseNo: '',
            purchaseFor: '',
            companyId: 0,
            isSold:false,
            companyName: 'select company',
            modelId: 0,
            modelName: 'select modal',
            filterModel: [],
            purchaseFromType: '',
            purchaseAmount: '',
            bikeNo: '',
            engineNo: '',
            chasisNo: '',
            rtoDetail: '',
            parsingDt: null,
            rcbookReceived: false,
            isFinance: false,
            alldocReceived: '',
            addressProof: '',
            idProof: '',
            contactName: '',
            contactId: 0,
            modelYear: '',
            addressTypeName: '',
            addressTypeId: 0,
            addressName: '',
            loanAccountNo:'',
            selectedForm35Copy:'',
            form352Copy: false,
            form351Copy: false,
            noc: false,
            kyc: false,
            selectedaddressType: '',
            selectedcompany: '',
            selectedmodel: '',
            selectedpurchaseFor: '',
            selectedcontact: '',
            selectedallDocReceived: '',
            selectedmodeOfPayment:'',
            addressOption: [{ id: 0, name: 'addressType', label: "", value: "" }],
            companyOption: [{ id: 0, name: 'company', label: "", value: "" }],
            modelOption: [{ id: 0, name: 'model', label: "", value: "" }],
            purchaseForOption: [{ name: 'purchaseFor', label: 'Retail', value: 'Retail' }, { name: 'purchaseFor', label: 'Scrap', value: 'Scrap' }],
            contactOption: [{ id: 0, name: 'contact', label: "", value: "" }],
            allDocReceivedOption: [{ name: 'alldocReceived', label: 'Yes', value: 'Yes' }, { name: 'alldocReceived', label: 'No', value: 'No' }],
            errorMap: {
                'purchaseFor': { 'valid': true, 'error': '' },
                'modelYear': { 'valid': true, 'error': '' },
                'contact': { 'valid': true, 'error': '' },
                'addressType': { 'valid': true, 'error': '' },
                'company': { 'valid': true, 'error': '' },
                'model': { 'valid': true, 'error': '' },
                'purchaseDt': { 'valid': true, 'error': '' },
                'bikeNo': { 'valid': true, 'error': '' },
                'engineNo': { 'valid': true, 'error': '' },
                'chasisNo': { 'valid': true, 'error': '' },
                'rtoDetail': { 'valid': true, 'error': '' },
                
            },

        })

    }
    isAllDocReceived = () => {
        if (this.state.kyc === true && this.state.noc === true  && this.state.form352Copy === true) {
            return true
        }
        return false
    }
    changeFormRadio=(e)=>{
        if(e.target.value==='1'){
            this.setState({form351Copy:true,form352Copy:false})
        }else{
            this.setState({form351Copy:false,form352Copy:true})
        }
        this.setState({selectedForm35Copy:e.target.value})
    }
    render() {
        return (

            <div className="carddata">


                <MDBRow className="justify-content-left ml-0" >

                    <MDBCol sm="3">

                    </MDBCol>

                    <MDBCol sm="3">

                    </MDBCol>
                    <div className="CustomBreadCrumbadd">
                        <CustomBreadCrumb items={['Purchase', 'Purchase Managment', 'Add Purchase']} linkTo={['', '/purchaseManagment', '']} />
                    </div>
                </MDBRow>
                <MDBRow className="justify-content-left ml-0 ">
                    <MDBCol lg="12">
                        <MDBCard className="mb-5 addpurchasemodel">
                            <MDBCardBody className="align-items-center justify-content-between">
                                <MDBRow style={{ backgroundColor: 'lightgrey' }} className="font-small m-0 p-0 block-example border border-light rounded">

                                    <MDBCol sm='12'>
                                        <span > Purchase Details </span>
                                    </MDBCol>

                                </MDBRow>
                                <form>
                                    <div className="row">
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 ">
                                            <label for="purchaseId" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Purchase No</label>
                                            <input type="text" disabled onChange={this.setInputVar} name='purchaseNo' className="form-control form-text font-small m-0 p-0" value={this.state.purchaseNo} id="purchaseId">
                                            </input>

                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4">
                                            <label for="purchaseDate" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Purchase Date</label>
                                            <div className="form-inline">
                                            <DatePicker
                                                selected={this.state.purchaseDt}
                                                dateFormat="dd-MM-yyyy"
                                                onChange={this.handlePurchaseDateChange}
                                                placeholderText='Purchase Dt'
                                                className="form-control"
                                                name='purchaseDt'
                                                ref={this.purchaseDate}
                                                
                                            />
                                            
                                            </div>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="purchase" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Purchase For</label>
                                            <Select name="purchaseFor" ref={this.purchaseForInput} options={this.state.purchaseForOption} value={this.state.selectedpurchaseFor} onChange={this.setSelectInputVar} id="purchase" class="input-field browser-default md-select form-control form-control-sm" >
                                                {/*
                                                 <option value="retail" >Retail</option>
                                                <option value="scrap">Scrap</option> */}
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.purchaseFor.valid == true ? '' : this.state.errorMap.purchaseFor.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="select" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Company</label>
                                            <Select name='company' ref={this.companyInput} options={this.state.companyOption} value={this.state.selectedcompany} onChange={this.changeCompanyVar} id="select" class="input-field browser-default md-select form-control form-control-sm">

                                                {/* {
                                                    this.state.companyData.map(item => {
                                                        return <option id={item.id} value={item.description}>{item.description}</option>
                                                    })
                                                } */}
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.company.valid == true ? '' : this.state.errorMap.company.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="model" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Model</label>
                                            <Select name="model" ref={this.modelInput} options={this.state.modelOption} value={this.state.selectedmodel} onChange={this.changeSelectVar} id="model" class="browser-default md-select form-control form-control-sm">
                                                {/* <option value="select model">Select </option>
                                                {
                                                    this.state.filterModel.map(item => {
                                                        return <option id={item.id} value={item.description}>{item.description}</option>
                                                    })
                                                } */}
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.model.valid == true ? '' : this.state.errorMap.model.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="ModelYear" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Model Year</label>
                                            <input type="Number" ref={this.modelYearInput} onChange={this.setInputVar} name='modelYear' className="form-control form-text font-small m-0 p-0" value={this.state.modelYear} id="ModelYear">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.modelYear.valid == true ? '' : this.state.errorMap.modelYear.error}</span>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="purchaseAmout" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Purchase Amount</label>
                                            <input type="Number" onChange={this.setInputVar} name='purchaseAmount' className="form-control form-text font-small m-0 p-0 " value={this.state.purchaseAmount} id="purchaseAmout">
                                            </input>
                                        </div>
                                       
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 ">
                                            <label for="Vendor" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Vendor/Dealer</label>
                                            <Select name="contact" ref={this.contactInput} options={this.state.contactOption} value={this.state.selectedcontact} onChange={this.changeSelectCustomerVar} id="Vendor" class="browser-default md-select form-control form-control-sm">

                                                {/* {
                                                    this.state.contactData.map(item => {
                                                        return <option id={item.id} value={item.firstName}>{item.firstName}</option>
                                                    })
                                                } */}
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.contact.valid == true ? '' : this.state.errorMap.contact.error}</span>
                                        </div>

                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">

                                            <label for="Address" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  > Address Type</label>
                                            <Select name="addressType" ref={this.addressTypeInput} value={this.state.selectedaddressType} options={this.state.addressOption} onChange={this.setAddressInputVar} id="Address" class="browser-default md-select form-control form-control-sm" >

                                                {/* <option value="Address1" >Address1</option>
                                                <option value="Address2">Address2</option>
                                                <option value="Address3">Address3</option> */}
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.addressType.valid == true ? '' : this.state.errorMap.addressType.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="AddressName" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Address</label>
                                            <input type="text" onChange={this.setInputVar} name='addressName' className="form-control form-text font-small m-0 p-0" value={this.state.addressName} id="AddressName"></input>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="Mobile No" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >MobileNo</label>
                                            <input type="number" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" onChange={this.setInputVar}  name='MobileNo' className="form-control form-text font-small m-0 p-0" value={this.state.MobileNo} id="Mobile No"></input>
                                        </div>
                                    </div>
                                </form>



                                <MDBRow style={{ backgroundColor: 'lightgrey' }} className="font-small m-0 p-0 block-example border border-light align-items-center rounded">
                                    <MDBCol sm='12'>
                                        <span> Bike Details </span>
                                    </MDBCol>
                                </MDBRow>
                                <div className="row">
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                        <label for="Bike No" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  > Bike No</label>
                                        <input type="text" name="bikeNo" ref={this.bikeNumberInput} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" value={this.state.bikeNo} id="Bike No">
                                        </input>
                                        <span className='error-message'>{this.state.errorMap.bikeNo.valid == true ? '' : this.state.errorMap.bikeNo.error}</span>
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                        <label for="Chasis No" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  > Chasis No</label>
                                        <input type="text" name='chasisNo' ref={this.chasisNumberInput} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" value={this.state.chasisNo} id="Chasis No">
                                        </input>
                                        <span className='error-message'>{this.state.errorMap.chasisNo.valid == true ? '' : this.state.errorMap.chasisNo.error}</span>
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                        <label for="Engine No" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  > Engine No</label>
                                        <input type="text" name='engineNo' ref={this.engineNumberInput} onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" value={this.state.engineNo} id="Engine No">
                                        </input>
                                        <span className='error-message'>{this.state.errorMap.engineNo.valid == true ? '' : this.state.errorMap.engineNo.error}</span>
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                        <label for="rtodetail" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >RTO Detail</label>
                                        <input type="text" ref={this.rtoDetailInput} name='rtoDetail' onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" value={this.state.rtoDetail} id="rtodetail">
                                        </input>
                                        <span className='error-message'>{this.state.errorMap.rtoDetail.valid == true ? '' : this.state.errorMap.rtoDetail.error}</span>
                                    </div>

                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                        <label for="Parsing Dt" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Passing Date</label>
                                        <DatePicker
                                            selected={this.state.parsingDt}
                                            onChange={this.handleParsingDateChange}
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText='Passing Dt'
                                            className="form-control"
                                            isClearable />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 purchasecheckbox ">

                                        <CustomCheckBox id="rcbookreceivedCheck" name='rcbookReceived' value='RC Book Received' handleCheckChieldElement={this.checkBoxChange} checked={this.state.rcbookReceived} />
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12" >
                                        <label for="addressProffId" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >ID Proof</label>
                                        <input type="text" onChange={this.setInputVar} name='idProof' className="form-control form-text font-small m-0 p-0" value={this.state.idProof} id="addressProffId"></input>
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12" >
                                        <label for="addressProofId" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Address Proof</label>
                                        <input type="text" onChange={this.setInputVar} name='addressProof' className="form-control form-text font-small m-0 p-0" value={this.state.addressProof} id="addressProofId"></input>
                                    </div>
                                    <div className="form-group col-xl-1 col-lg-1 col-md-4 col-sm-12 col-xs-12 purchasecheckbox">
                                        <CustomCheckBox id="isFinanceCheck" name='isFinance' value=' Financed' handleCheckChieldElement={this.checkBoxChange} checked={this.state.isFinance} />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 loanaccountno">
                                        <label for="loanaccountno" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Loan Account No</label>
                                        <input type="text" disabled={!this.state.isFinance} name='loanAccountNo' onChange={this.setInputVar} className="form-control form-text font-small m-0 p-0" value={this.state.loanAccountNo} id="loanaccountno">
                                        </input>
                                    </div>
                                   
                                                
                                    
                                            <div className=" col-sm-4  ">
                                                
                                                <label for="loanaccountno" className="ml- "style={{ 'font-size': '0.7rem', 'color': 'grey' }}  > Doc's Received</label>
                                                <div className=" form-group h-70 " style={{width:'170px',border:'1px solid gray'}}>
                                                <label for="loanaccountno" className="ml-3  font-weight-bold text-left"style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Form 35</label>
                                                <div>
                                                    <form>
                                                  <div className="radiochecked">
                                                  
                                               <input type="radio" className="big checkmark"   disabled={!this.state.isFinance} onChange={this.changeFormRadio} value='1' id='copy'checked={this.state.selectedForm35Copy === '1'} />
                                               <label for="copy" className="text-left"  style={{ 'font-size': '0.8rem', 'color': 'grey' }}>1 Copy</label>
                                               <input type="radio" className="ml-2 mt-2 checkmark" size='sm'  disabled={!this.state.isFinance} onChange={this.changeFormRadio} value='2'  checked={this.state.selectedForm35Copy === '2'} />
                                               <label for="copy" className="ml-1"  style={{ 'font-size': '0.8rem', 'color': 'grey' }}>2 Copy</label>
                                               </div>
                                              
                                                 
                                                  <div className="radiochecked ">
                                                  <input type="Checkbox" className="checkmark" id="nocId" disable={!this.state.isFinance} name='noc' onChange={this.checkBoxChange} checked={this.state.noc}/>

                                                  <label for="copy" className="ml-2 "  style={{ 'font-size': '0.8rem', 'color': 'grey' }}>NOC</label>
                                                  <input type="Checkbox" className="ml-3 mt-3 checkmark" id="kycId" disable={!this.state.isFinance} name='kyc' onChange={this.checkBoxChange} checked={this.state.kyc}/>
                                                  <label for="copy" className="ml-2 "  style={{ 'font-size': '0.8rem', 'color': 'grey' }}>KYC</label>
                                                  </div>
                                                    
                                               
                                               
                                                    </form>
                                                
                                                </div>
                                               
                                                <div>
                                                    
                                        </div>  
                                           
                                        </div>
                                    </div>
                                    {/*
                                    <div className="form-group col-sm-2s form-control-sm " >
                                    <label for="loanaccountno" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Form 35</label>
                                            <div className="radio">
                                            <label>
                                                <input type="radio" disabled={!this.state.isFinance} onChange={this.changeFormRadio} value='1' checked={this.state.selectedForm35Copy === '1'} />
                                                 1 Copy
                                             </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input type="radio" disabled={!this.state.isFinance} onChange={this.changeFormRadio} value='2'  checked={this.state.selectedForm35Copy === '2'} />
                                                2 Copy
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group col-sm-2 form-control-sm alldoccheckbox" >
                                        <CustomCheckBox id="nocId" disable={!this.state.isFinance} name='noc' value='NOC' handleCheckChieldElement={this.checkBoxChange} checked={this.state.noc} />
                                        <CustomCheckBox id="kycId" disable={!this.state.isFinance} name='kyc' value='KYC' handleCheckChieldElement={this.checkBoxChange} checked={this.state.kyc} />
                                    </div>*/}
                                   
                                </div>

                                <MDBRow className="justify-content-left SaveCancelbutton mt-2" >
                                    <MDBCol sm='12' >
                                        <MDBBtn  size='sm' onClick={this.saveData} className='Addpurchasesave'>
                                            <span className="Savedata">
                                                Save
                                            </span>

                                        </MDBBtn>

                                        <MDBBtn size='sm' onClick={this.closeAddForm} className='AddpurchaseCancel'>
                                            <span className="Savedata">
                                                Cancel
                                            </span>
                                        </MDBBtn>
                                        <MDBBtn size='sm' disabled={!this.state.rcbookReceived} onClick={this.openaddrcmodel} className="AddpurchaseRc">
                                            <span className="Savedata">
                                                Add Rc
                                            </span>
                                        </MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>

                <APIResponse
                    isOpen={this.state.apiResponseModalOpen}
                    response={this.state.apiResponse}
                    msg={this.state.apiResponseMsg}
                    closeAPIResponse={this.closeAPIResponse}
                />

            </div>

        );
    }
}
export default AddPurchaseModal