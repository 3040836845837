import React, { Component } from 'react';

import { MDBListGroup, MDBNav, MDBCollapse, MDBNavLink, MDBBtn, MDBIcon, MDBNavItem, MDBContainer, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBCol } from 'mdbreact';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/company-logo.png'

class SideNavigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collapseID: "",
            collapse1: false,
            modal9: this.props.isOpen,
            modal7: false
        }

    }
    componentWillReceiveProps(newProp) {
        this.setState({ modal9: newProp.isOpen });
    }
    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }
    toggle = nr => () => {
        let modalNumber = 'modal' + nr

        if (!this.state[modalNumber] === false) {
            this.props.closeSideBar()
        }
        this.setState({
            [modalNumber]: !this.state[modalNumber]
        });

    }
    render() {
        if (window.location.pathname === '/login' || window.location.pathname === '/') return null;
        return (
            <div >
                <MDBContainer  >
                    <MDBModal isOpen={this.state.modal9} toggle={this.toggle(9)} fullHeight position="left" size='sm'>
                        <MDBModalHeader toggle={this.toggle(9)}>
                            <strong className='mt-2 font-large dark-gray-text aimscenter'>AIMS</strong>
                        </MDBModalHeader>
                        <MDBModalBody >
                            <MDBRow>
                                {/*
             <MDBCol lg='12' className=' text-center'>             
                <img alt="Harvices" className="img-fluid z-depth-1 rounded-circle hoverable" src={logo}/>
            </MDBCol>*/}
                            </MDBRow>
                            <MDBListGroup className="list-group-flush " >
                                <MDBBtn color="list-group-flush" className="my-1" href="/dashboard">
                                    <MDBIcon icon="chart-pie" className="mr-3" />Dashboard
                </MDBBtn>
                                <MDBBtn color="list-group-flush" className="my-1" onClick={this.toggleCollapse("masterCollapse")}>
                                    <MDBIcon icon="book" className="mr-3" />Master
                </MDBBtn>

                                <MDBCollapse id="masterCollapse" isOpen={this.state.collapseID}>
                                    <MDBNav className="flex-column ">
                                        <MDBNavItem><MDBNavLink to="/country" onClick={this.toggle(9)}> <MDBIcon icon="list-ul" className="mr-3" />Country</MDBNavLink></MDBNavItem>
                                        <MDBNavItem><MDBNavLink to="/state" onClick={this.toggle(9)}> <MDBIcon icon="list-ul" className="mr-3" />State</MDBNavLink></MDBNavItem>
                                        <MDBNavItem><MDBNavLink to="/district" onClick={this.toggle(9)}> <MDBIcon icon="list-ul" className="mr-3" />District</MDBNavLink></MDBNavItem>
                                        <MDBNavItem><MDBNavLink to="/city" onClick={this.toggle(9)}> <MDBIcon icon="list-ul" className="mr-3" />City</MDBNavLink></MDBNavItem>
                                        <MDBNavItem><MDBNavLink to="/taluka" onClick={this.toggle(9)}> <MDBIcon icon="list-ul" className="mr-3" />Taluka</MDBNavLink></MDBNavItem>
                                        <MDBNavItem><MDBNavLink to="/company" onClick={this.toggle(9)}> <MDBIcon icon="list-ul" className="mr-3" />Company</MDBNavLink></MDBNavItem>
                                        {/* <MDBNavItem><MDBNavLink to="/vehicleCategory" onClick={this.toggle(9)}> <MDBIcon icon="list-ul" className="mr-3" />Vehicle Category</MDBNavLink></MDBNavItem> */}
                                        <MDBNavItem><MDBNavLink to="/model" onClick={this.toggle(9)}> <MDBIcon icon="list-ul" className="mr-3" />Model</MDBNavLink></MDBNavItem>
                                    </MDBNav>
                                </MDBCollapse>
                                <MDBBtn color="list-group-flush" className="my-1" onClick={this.toggleCollapse("generalCollapse")}>
                                    <MDBIcon icon="address-book" className="mr-3" />General
                </MDBBtn>
                                <MDBCollapse id="generalCollapse" isOpen={this.state.collapseID}>
                                    <MDBNav className="flex-column">
                                        <MDBNavItem>
                                            <MDBNavLink to="/contact" onClick={this.toggle(9)}><MDBIcon icon="address-card" className="mr-3" />Contact</MDBNavLink>
                                        </MDBNavItem>
                                    </MDBNav>
                                </MDBCollapse>

                                <MDBBtn color="list-group-flush" className="my-1" onClick={this.toggleCollapse("purchaseCollapse")}>
                                    <MDBIcon icon="cart-plus" className="mr-3" />Purchase
                                </MDBBtn>
                                <MDBCollapse id="purchaseCollapse" isOpen={this.state.collapseID}>
                                    <MDBNav className="flex-column">
                                        <MDBNavItem>
                                            <MDBNavLink to="/purchaseManagment" onClick={this.toggle(9)}><MDBIcon icon="cart-arrow-down" className="mr-3" />Purchase Managment</MDBNavLink>
                                        </MDBNavItem>
                                        <MDBNavItem>
                                            <MDBNavLink to="/rc" onClick={this.toggle(9)}><MDBIcon icon="book" className="mr-3" />RC</MDBNavLink>
                                        </MDBNavItem>
                                    </MDBNav>
                                </MDBCollapse>
                                <MDBBtn color="list-group-flush" className="my-1" onClick={this.toggleCollapse("sellCollapse")}>
                                    <MDBIcon icon="cart-plus" className="mr-3" />Sell
                                </MDBBtn>
                                <MDBCollapse id="sellCollapse" isOpen={this.state.collapseID}>
                                    <MDBNav className="flex-column">
                                        <MDBNavItem>
                                            <MDBNavLink to="/sellManagment" onClick={this.toggle(9)}><MDBIcon icon="cart-arrow-down" className="mr-3" />Sell Managment</MDBNavLink>
                                        </MDBNavItem>
                                        
                                    </MDBNav>
                                </MDBCollapse>

                                <MDBBtn color="list-group-flush" className="my-1" onClick={this.toggleCollapse("userCollapse")}>
                                    <MDBIcon icon="user-circle" className="mr-2" />User Profile
                </MDBBtn>
                                <MDBCollapse id="userCollapse" isOpen={this.state.collapseID}>
                                    <MDBNav className="flex-column">
                                        <MDBNavItem>
                                            <MDBNavLink to="/usermanagment" onClick={this.toggle(9)}><MDBIcon icon="user" className="mr-3" />User Managment</MDBNavLink>
                                        </MDBNavItem>
                                    </MDBNav>
                                </MDBCollapse>

                                <MDBBtn color="list-group-flush" className="my-1" onClick={this.toggleCollapse("accountCollapse")}>
                                    <MDBIcon icon="user-circle" className="mr-3" />Account
                </MDBBtn>
                                <MDBCollapse id="accountCollapse" isOpen={this.state.collapseID}>
                                    <MDBNav className="flex-column">
                                        <MDBNavItem>
                                            <MDBNavLink to="/partyVoucher" onClick={this.toggle(9)}><MDBIcon icon="user" className="mr-3" />Party Voucher</MDBNavLink>
                                        </MDBNavItem>
                                    </MDBNav>
                                    <MDBNav className="flex-column">
                                        <MDBNavItem>
                                            <MDBNavLink to="/receipt" onClick={this.toggle(9)}><MDBIcon icon="user" className="mr-3" />Receipt</MDBNavLink>
                                        </MDBNavItem>
                                    </MDBNav>
                                </MDBCollapse>
                            </MDBListGroup>
                        </MDBModalBody>
                        <MDBModalFooter>

                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
            </div>
        );
    }
}
export default SideNavigation;