import React, { Component } from 'react';
import Routes from '../src/components/common/Routes';
import TopNavigation from './components/common/topNavigation';
import SideNavigation from './components/common/sideNavigation';
import { MDBRow, MDBCol } from 'mdbreact';
import Footer from './components/common/Footer';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';

class App extends Component {

  render() {
    //   if (window.location.pathname === '/login'||window.location.pathname === '/') 
    //   {
    //     return (
    //       <div className="flexible-content">
    //         <Routes />
    //       </div>
    //   );
    // }else{
    return (
      <div className="flexible-content" >
        <MDBRow>
          <MDBCol lg="12">
            <TopNavigation />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="12">
            <main id="content" className="m-5 pt-2">
              <Routes />
            </main>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="12">
            <Footer />
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
  // }
}

export default App;
