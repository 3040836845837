import React, { Component } from "react";
import { MDBRow, MDBInput, MDBCard, MDBTabContent, MDBTabPane, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCardBody, MDBBreadcrumbItem, MDBCol } from 'mdbreact';

import AddressInfoCardSection from '../sections/AddressInfoCardSection'
import PersonalInfoCardSection from '../sections/PersonalInfoCardSection'
import ApiUtils from '../../common/api_utils'
import HttpAPI from '../../common/api_calls'
import APIResponse from '../../common/APIResponse'
import CustomBreadCrumb from '../sections/CustomBreadCrumb'
import "../Styleing.css"
import Select from 'react-select';
class ContactModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: this.props.isOpen,
            editContact: this.props.editContact,
            activeItem: "1",
            addressInfoData: [],
            contactId: 0,
            contactTypeList: ApiUtils.getContactType(),
            idTypeList: ApiUtils.getIDType(),
            firstName: '',
            lastName: '',
            middleName: '',
            mobile: '',
            photo: null,
            photoName: '',
            idImage: null,
            idImageName: '',
            addressProofList: ApiUtils.getIDType(),
            addressProofImage: null,
            addressProofImageName: '',
            phone: '',
            sign: null,
            signName: '',
            contactType: '',
            idType: '',
            addressProof: '',
            apiResponse: false,
            apiResponseModalOpen: false,
            apiResponseMsg: '',
            vendorOfficeName:'',
            idProofNumber:'',
            idNumber:'',
            selectedcontactType:'',
            selectedidType:'',
            selectedaddressProof:'',
            contactTypeOption:[],
            idTypeOption:[],
            addressProofOption:[],
            errorMap:{ 'contactType': { 'valid': true, 'error': ''},
                'firstName': { 'valid': true, 'error': '' },
            'lastName': { 'valid': true, 'error': '' },
            'mobile': { 'valid': true, 'error': '' },
            'idType': { 'valid': true, 'error': '' },
            'idNumber': { 'valid': true, 'error': '' },
            'idImage': { 'valid': true, 'error': '' },
            'idType': { 'valid': true, 'error': '' },
            'idNumber': { 'valid': true, 'error': '' },
            'idImage': { 'valid': true, 'error': '' },
            'addressProof': { 'valid': true, 'error': '' },
            'idProofNumber': { 'valid': true, 'error': '' },
            'addressProofImage': { 'valid': true, 'error': '' },
            'vendorOfficeName': { 'valid': true, 'error': '' },},
        }
        this.contactTypeInput = React.createRef();
        this.firstNameInput = React.createRef();
        this.lastNameInput = React.createRef();
        this.mobileInput = React.createRef();
        this.idTypeInput = React.createRef();
        this.idNumberInput = React.createRef();
        this.idImageInput = React.createRef();
        this.addressProofInput = React.createRef();
        this.ProofNumberInput = React.createRef();
        this.ProofImageInput = React.createRef();
        this.vendorOfficeNameInput = React.createRef();
    }
    componentDidMount() {
        this.renderDataFromServer()
    }
    renderDataFromServer() {
        let id = window.localStorage.getItem('editContactId')
        if (id > 0) {
            HttpAPI.getContactById(id).then(
                response => {
                    var response = response.data;
                    if (response.status === true) {
                        this.setState({ editContact: response.data })
                        this.setState({ contactId: response.data.id })
                        this.setEditData(response.data)
                    }

                }
            );
            HttpAPI.getAddressByContactId(id).then(
                response => {
                    var response = response.data;
                    if (response.status === true) {
                        this.setState({ addressInfoData: response.data })


                    }

                }
            );

        }
        
        var arr = []
        this.state.contactTypeList.map((item, key) =>
            arr.push({name: 'contactType',label: item,value: item})
        );
        this.setState({contactTypeOption:arr})
        var idTypeArr=[]
        this.state.idTypeList.map((item, key) =>
        idTypeArr.push({name: 'idType',label: item,value: item})
        );
        this.setState({idTypeOption:idTypeArr})
        var addressProofArr=[]
        this.state.addressProofList.map((item, key) =>
        addressProofArr.push({name: 'addressProof',label: item,value: item})
        );
        this.setState({addressProofOption:addressProofArr})
    }

    updatePersonalInfo = () => {
        let data = {
            'id': this.state.contactId,
            'contactType': this.state.contactType,
            'firstName': this.state.firstName,
            'middleName': this.state.middleName,
            'lastName': this.state.lastName,
            'mobileNumber': this.state.mobile,
            'idType': this.state.idType,
            'addressProof': this.state.addressProof,
            'idNumber':this.state.idNumber,
            'addressProofNumber':this.state.addressProofNumber,
            'vendorOfficeName':this.state.vendorOfficeName,
            'phoneNumber': this.state.phone,
            'active': this.state.editContact.active,
            'createdAt': this.state.editContact.createdAt,
            'createdBy': this.state.editContact.createdBy
        }


        let senddata = ApiUtils.appendUpdateRequiredField(data)
        HttpAPI.updateContact(senddata).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    if (this.state.addressInfoData !== []) {
                        let ArrAddress = []
                        this.state.addressInfoData.map(function (row) {
                            if (row.createdAt === undefined) {
                                row.id = 0
                                delete row['id'];
                                delete row['countryMST'];
                                delete row['stateMST'];
                                delete row['cityMST'];
                                delete row['active'];
                                let id = { 'contactId': response.data.id }
                                let addressData = Object.assign(row, id);
                                let sendAddressData = ApiUtils.appendCreateRequiredField(addressData);
                                ArrAddress.push(sendAddressData)
                            } else {
                                row.updatedAt = ApiUtils.getCurrentTimeStamp();
                                row.updatedBy = ApiUtils.getLoggedInUser();
                                ArrAddress.push(row)
                            }
                        });
                        if (ArrAddress.length > 0) {
                            HttpAPI.updateAddress(ArrAddress).then(
                                response => {

                                });
                        }

                    }
                    if (this.state.addressProofImage !== null) {
                        HttpAPI.uploadAddressProofImage(this.state.addressProofImage, response.data.id).then(
                            addressProofResponse => {

                            });
                    }
                    if (this.state.idImage !== null) {
                        HttpAPI.uploadContactIdImage(this.state.idImage, response.data.id).then(
                            contactProofResponse => {

                            });
                    }
                    if (this.state.photo !== null) {
                        HttpAPI.uploadPhotoImage(this.state.photo, response.data.id).then(
                            photoResponse => {

                            });
                    }
                    if (this.state.sign !== null) {
                        HttpAPI.uploadSignImage(this.state.sign, response.data.id).then(
                            SignResponse => {

                            });
                    }
                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                    this.closePersonalInfo();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)
            }
        );
    }
    createPersonalInfo = () => {

        let data = {
            'contactType': this.state.contactType,
            'firstName': this.state.firstName,
            'middleName': this.state.middleName,
            'lastName': this.state.lastName,
            'mobileNumber': this.state.mobile,
            'idType': this.state.idType,
            'addressProof': this.state.addressProof,
            'phoneNumber': this.state.phone,
            'idNumber':this.state.idNumber,
            'addressProofNumber':this.state.addressProofNumber,
            'vendorOfficeName':this.state.vendorOfficeName,

        }


        let senddata = ApiUtils.appendCreateRequiredField(data);
        HttpAPI.createContact(senddata).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    if (this.state.addressInfoData !== []) {
                        let ArrAddress = []
                        this.state.addressInfoData.map(function (row) {

                            delete row['id'];
                            let id = { 'contactId': response.data.id }
                            let addressData = Object.assign(row, id);
                            let sendAddressData = ApiUtils.appendCreateRequiredField(addressData);
                            ArrAddress.push(sendAddressData)


                        })
                        if (ArrAddress.length > 0) {
                            HttpAPI.createAddress(ArrAddress).then(
                                addressResponse => {

                                });
                        }

                    }
                    if (this.state.addressProofImage !== null) {
                        HttpAPI.uploadAddressProofImage(this.state.addressProofImage, response.data.id).then(
                            addressProofResponse => {

                            });
                    }
                    if (this.state.idImage !== null) {
                        HttpAPI.uploadContactIdImage(this.state.idImage, response.data.id).then(
                            contactProofResponse => {

                            });
                    }
                    if (this.state.photo !== null) {
                        HttpAPI.uploadPhotoImage(this.state.photo, response.data.id).then(
                            photoResponse => {

                            });
                    }
                    if (this.state.sign !== null) {
                        HttpAPI.uploadSignImage(this.state.sign, response.data.id).then(
                            SignResponse => {

                            });
                    }
                    this.setState({
                        apiResponse: true,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })

                    this.closePersonalInfo();
                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: '',
                        apiResponseModalOpen: false
                    })
                }, 1000)
            }
        );
    }
    setEditData = (editContact) => {
        this.setState({
            contactId: editContact.id,
            idType: editContact.idType,
            contactType: editContact.contactType,
            firstName: editContact.firstName,
            lastName: editContact.lastName,
            middleName: editContact.middleName,
            mobile: editContact.mobileNumber,
            phone: editContact.phoneNumber,
            addressProof: editContact.addressProof,
            addressProofImageName: editContact.addressProofImage,
            idImageName: editContact.idImage,
            signName: editContact.sign,
            photoName: editContact.photo,
            selectedcontactType:{name: 'contactType',label: editContact.contactType,value: editContact.contactType},
            selectedidType:{name: 'idType',label: editContact.idType,value: editContact.idType},
            selectedaddressProof:{name: 'addressProof',label: editContact.addressProof,value: editContact.addressProof},
            
        })
    }
    toggle = () => {
        this.setState({
            activeItem: '1',
            isModalOpen: !this.state.isModalOpen
        });

    }
    toggleTab = tab => e => {
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab
            });
        }
    };
    setAddressInfo = (data) => {
        this.setState({ addressInfoData: data })
    }

    saveDataPersonalInfo = () => {
        if (this.validatePersonalInfoData() == true) {
            if (this.state.contactId === 0) {
                this.createPersonalInfo();
            } else {
                this.updatePersonalInfo();
            }

        } 
    }
    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    setSelectInputVar = (e) => {
        this.setState({
            [e.name]: e.value,
            ['selected'+e.name]:e
        });
    }
    setFileInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.files[0],
            [e.target.name + 'Name']: e.target.files[0].name
        });
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }
    validatePersonalInfoData = () => {
        let map = {
            'contactType': { 'valid': true, 'error': ''},
            'firstName': { 'valid': true, 'error': '' },
            'lastName': { 'valid': true, 'error': '' },
            'mobile': { 'valid': true, 'error': '' },
            'idType': { 'valid': true, 'error': '' },
            'idNumber': { 'valid': true, 'error': '' },
            'idImage': { 'valid': true, 'error': '' },
            'idType': { 'valid': true, 'error': '' },
            'idNumber': { 'valid': true, 'error': '' },
            'idImage': { 'valid': true, 'error': '' },
            'addressProof': { 'valid': true, 'error': '' },
            'idProofNumber': { 'valid': true, 'error': '' },
            'addressProofImage': { 'valid': true, 'error': '' },
            'vendorOfficeName': { 'valid': true, 'error': '' },
        }
        let isValid = true;
        if(this.state.contactType === ''){
            map.contactType.valid = false;
            map.contactType.error = 'Please Select Contact Type'
            this.contactTypeInput.current.focus()
            isValid = false
        }
        if (this.state.firstName === '') {
            //this.setState({ apiResponseMsg: 'Please fill First Name' })
            map.firstName.valid = false;
            map.firstName.error = 'Please fill First Name'
            if(isValid===true){
                this.firstNameInput.current.focus()
            }
            isValid = false
        }
        if (this.state.lastName === '') {
            //this.setState({ apiResponseMsg: 'Please fill Last Name' })
            map.lastName.valid = false;
            map.lastName.error = 'Please fill Last Name'
            if(isValid===true){
                this.lastNameInput.current.focus()
            }
            isValid = false
        }
        if (this.state.mobile === '') {
            //this.setState({ apiResponseMsg: 'Please fill Mobile Number' })
            map.mobile.valid = false;
            map.mobile.error = 'Please fill Mobile Number'
            if(isValid===true){
                this.mobileInput.current.focus()
            }
            isValid = false
        }
        if (ApiUtils.validateMobileNo(this.state.mobile) === false) {
            //this.setState({ apiResponseMsg: 'Please fill valid Mobile Number' })
            map.mobile.valid = false;
            map.mobile.error = 'Please fill valid Mobile Number'
            if(isValid===true){
                this.mobileInput.current.focus()
            }
            isValid = false
        }
        
        if (this.state.idType === '') {
            //this.setState({ apiResponseMsg: 'Please select ID Type' })
            map.idType.valid = false;
            map.idType.error = 'Please select ID Type'
            if(isValid===true){
                this.idTypeInput.current.focus()
            }
            isValid = false
        }
        if(this.state.idNumber===''){
            map.idNumber.valid = false;
            map.idNumber.error = 'Please fill ID Number'
            if(isValid===true){
                this.idNumberInput.current.focus()
            }
            isValid = false
        }
        // if (this.state.idImage === null) {
        //     //this.setState({ apiResponseMsg: 'Please select ID Image' })
        //     map.idImage.valid = false;
        //     map.idImage.error = 'Please select ID Image'
        //     if(isValid===true){
        //         this.idImageInput.current.focus()
        //     }
        //     isValid = false
            
        // }
        if (this.state.addressProof !== '') {
            if (this.state.idProofNumber === '') {
                map.idProofNumber.valid = false;
                map.idProofNumber.error = 'Please fill Address Proof Number'
                if(isValid===true){
                    this.ProofNumberInput.current.focus()
                }
                isValid = false
            }    
        }
        
        // if (this.state.addressProofImage === null) {
        //     map.addressProofImage.valid = false;
        //     map.addressProofImage.error = 'Please select Address Proof Image'
        //     if(isValid===true){
        //         this.ProofImageInput.current.focus()
        //     }
        //     isValid = false
        // }

        if (this.state.vendorOfficeName === '') {
            map.vendorOfficeName.valid = false;
            map.vendorOfficeName.error = 'Please fill Vendor Office Name'
            if(isValid===true){
                this.vendorOfficeNameInput.current.focus()
            }
            isValid = false
        }
        if(isValid===true){
            if (this.state.addressInfoData.length === 0) {
                //this.setState({ apiResponseMsg: 'Please fill Address',activeItem:"2" })
                this.setState({ activeItem:"2" })
                isValid = false
            }
        }
        this.setState({errorMap:map})
        return isValid;
    }
    clearDataPersonalInfo = () => {
        window.localStorage.setItem('editContactId', 0)

        this.setState({
            activeItem: "1",
            addressInfoData: [],
            contactId: 0,
            firstName: '',
            lastName: '',
            middleName: '',
            mobile: '',
            photo: null,
            photoName: '',
            idImage: null,
            idImageName: '',
            addressProofImage: null,
            addressProofImageName: '',
            phone: '',
            sign: null,
            signName: '',
            contactType: '',
            idType: '',
            addressProof: '',
            isModalOpen: false,
            vendorOfficeName:'',
            idProofNumber:'',
            idNumber:'',
            errorMap:{ 'contactType': { 'valid': true, 'error': ''},
                'firstName': { 'valid': true, 'error': '' },
            'lastName': { 'valid': true, 'error': '' },
            'mobile': { 'valid': true, 'error': '' },
            'idType': { 'valid': true, 'error': '' },
            'idNumber': { 'valid': true, 'error': '' },
            'idImage': { 'valid': true, 'error': '' },
            'idType': { 'valid': true, 'error': '' },
            'idNumber': { 'valid': true, 'error': '' },
            'idImage': { 'valid': true, 'error': '' },
            'addressProof': { 'valid': true, 'error': '' },
            'idProofNumber': { 'valid': true, 'error': '' },
            'addressProofImage': { 'valid': true, 'error': '' },
            'vendorOfficeName': { 'valid': true, 'error': '' },},
        })

    }
    closePersonalInfo = () => {
        this.clearDataPersonalInfo()
    }
    render() {
        return (
            <div>

                <MDBRow className=" justify-content-left ml-0">
                    <MDBCol sm="3">

                    </MDBCol>
                    <MDBCol sm="3">

                    </MDBCol>
                    <MDBCol sm="3">

                    </MDBCol>
                    <div id="ContactModel">
                        <CustomBreadCrumb items={['General', 'Contact', 'Add Contact']} linkTo={['', '/contact', '']} />
                    </div>
                </MDBRow>
                <MDBRow>
                    <MDBCol lg="12">

                        <MDBNav className="nav-tabs mt-0">
                            <MDBNavItem>
                                <MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={this.toggleTab("1")} role="tab" >
                                    Personal Information Tab</MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={this.toggleTab("2")} role="tab" >
                                    Address Tab</MDBNavLink>
                            </MDBNavItem>

                        </MDBNav>
                        <MDBTabContent activeItem={this.state.activeItem} >
                            <MDBTabPane className='m-0 p-0' tabId="1" role="tabpanel">
                                <div>
                                    <MDBCard className="mb-5 contactmodelcard">
                                        <MDBCardBody className="align-items-center justify-content-between  m-1">
                                            <div className="row">
                                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 ">
                                                    <label for="contenttype" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Content Type</label>
                                                    <Select ref={this.contactTypeInput} options={this.state.contactTypeOption} name="contactType" value={this.state.selectedcontactType} onChange={this.setSelectInputVar} id="select" class="browser-default md-select form-control ">
                                                        {/* <option value="select contact type">Select</option>
                                                        {
                                                            this.state.contactTypeList.map(item => {
                                                                return <option value={item}>{item}</option>
                                                            })
                                                        } */}
                                                    </Select>
                                                    <span className='error-message'>{this.state.errorMap.contactType.valid == true ? '' : this.state.errorMap.contactType.error}</span>
                                                </div>
                                                <div className="form-group col-xl-2 col-lg-2 col-md-4  col-sm-12 col-xs-12 selectstate">
                                                    <label for="firstName"  style={{ 'font-size': '0.7rem', 'color': 'grey' }} >First Name</label>
                                                    <input type="text" ref={this.firstNameInput} name="firstName" value={this.state.firstName} className="form-control form-text font-small m-0 p-0 " onChange={this.setInputVar} id="firstName">
                                                    </input>
                                                    <span className='error-message'>{this.state.errorMap.firstName.valid == true ? '' : this.state.errorMap.firstName.error}</span>
                                                </div>
                                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 selectstate">
                                                    <label for="middleName" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Middle Name</label>
                                                    <input type="text" name="middleName" value={this.state.middleName} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="middleName">
                                                    </input>
                                                </div>
                                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 ">
                                                    <label for="lastName" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >last Name</label>
                                                    <input type="text" ref={this.lastNameInput} name="lastName" value={this.state.lastName} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="lastName">
                                                    </input>
                                                    <span className='error-message'>{this.state.errorMap.lastName.valid == true ? '' : this.state.errorMap.lastName.error}</span>
                                                </div>
                                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 selectstate">
                                                    <label for="Mobile" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Mobile No</label>
                                                    <input type="number" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"  ref={this.mobileInput}  name="mobile" value={this.state.mobile} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="lastName">
                                                    </input>
                                                    <span className='error-message'>{this.state.errorMap.mobile.valid == true ? '' : this.state.errorMap.mobile.error}</span>
                                                </div>
                                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 selectstate">
                                                    <label for="phone" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Phone</label>
                                                    <input type="number" name="phone" value={this.state.phone} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="phone">
                                                    </input>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                                    <label for="contenttype" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >ID Type</label>
                                                    <Select name="idType" ref={this.idTypeInput} id="contenttype" options={this.state.idTypeOption} value={this.state.selectedidType} onChange={this.setSelectInputVar} class="browser-default md-select form-control">
                                                        {/* <option value="select id type">Select</option>
                                                        {
                                                            this.state.idTypeList.map(item => {
                                                                return <option value={item}>{item}</option>
                                                            })
                                                        } */}
                                                    </Select>
                                                    <span className='error-message'>{this.state.errorMap.idType.valid == true ? '' : this.state.errorMap.idType.error}</span>
                                                </div>
                                                <div className="form-group  col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 selectstate">
                                                    <label for="idNumber" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >ID No</label>
                                                    <input type="text"  ref={this.idNumberInput} name="idNumber" value={this.state.idNumber} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="idNumber">
                                                    </input>
                                                    <span className='error-message'>{this.state.errorMap.idNumber.valid == true ? '' : this.state.errorMap.idNumber.error}</span>
                                                </div>
                                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 browsedata ">
                                                    <label for="inputGroupFile01" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >ID Image</label>
                                                    <input
                                                        type="file"

                                                        className="custom-file-input font-small"
                                                        id="inputGroupFile01"
                                                        aria-describedby="inputGroupFileAddon01"
                                                        name='idImage'
                                                        accept='.jpg, .png, .jpeg'
                                                        onChange={this.setFileInputVar}
                                                        ref={this.idImageInput} 
                                                    />
                                                    <label className="font-small custom-file-label browsefile" htmlFor="inputGroupFile01">{this.state.idImageName}</label>
                                                    <span className='error-message'>{this.state.errorMap.idImage.valid == true ? '' : this.state.errorMap.idImage.error}</span>
                                                </div>
                                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 ml-0 selectstate">
                                                    <label for="contenttype" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Address proof</label>
                                                    <Select name="addressProof" ref={this.addressProofInput}  value={this.state.selectedaddressProof} options={this.state.addressProofOption} onChange={this.setSelectInputVar} id="select" class="input-field browser-default md-select form-control">
                                                        {/* <option value="select address proof">Select</option>
                                                        {
                                                            this.state.addressProofList.map(item => {
                                                                return <option value={item}>{item}</option>
                                                            })
                                                        } */}
                                                    </Select>
                                                    <span className='error-message'>{this.state.errorMap.addressProof.valid == true ? '' : this.state.errorMap.addressProof.error}</span>
                                                </div>
                                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 selectstate">
                                                    <label for="proofNumber" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >ID Proof No</label>
                                                    <input type="text" name="idProofNumber" ref={this.ProofNumberInput}  value={this.state.idProofNumber} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="proofNumber">
                                                    </input>
                                                    <span className='error-message'>{this.state.errorMap.idProofNumber.valid == true ? '' : this.state.errorMap.idProofNumber.error}</span>
                                                </div>
                                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 browsedata">
                                                    <label for="inputGroupFile01" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Address Proof Image</label>
                                                    <input
                                                        type="file"
                                                        className="custom-file-input font-small"
                                                        id="inputGroupFile01"
                                                        aria-describedby="inputGroupFileAddon01"
                                                        name='addressProofImage'
                                                        accept='.jpg, .png, .jpeg'
                                                        onChange={this.setFileInputVar}
                                                        ref={this.ProofImageInput}
                                                    />
                                                    <label className="custom-file-label font-small browsefile" htmlFor="inputGroupFile01">
                                                        {this.state.addressProofImageName}</label>
                                                        <span className='error-message'>{this.state.errorMap.addressProofImage.valid == true ? '' : this.state.errorMap.addressProofImage.error}</span>
                                                </div>
                                                
                                            </div>

                                            <div className="row">
                                            <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                                    <label for="vendorOffice" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Vendor Office Name</label>
                                                    <input type="text" name="vendorOfficeName" ref={this.vendorOfficeNameInput} value={this.state.vendorOfficeName} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="vendorOffice">
                                                    </input>
                                                    <span className='error-message'>{this.state.errorMap.vendorOfficeName.valid == true ? '' : this.state.errorMap.vendorOfficeName.error}</span>
                                                </div>
                                            <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 browsedata ">
                                                    <label for="inputGroupFile01" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Photo</label>
                                                    <input
                                                        type="file"
                                                        className="custom-file-input font-small"
                                                        id="inputGroupFile01"
                                                        aria-describedby="inputGroupFileAddon01"
                                                        name='photo'
                                                        accept='.jpg, .png, .jpeg'
                                                        onChange={this.setFileInputVar}
                                                    />
                                                    <label className="custom-file-label font-small browsefile" htmlFor="inputGroupFile01">
                                                        {this.state.photoName}</label>
                                                </div>
                                                <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 ml-2 browsedata">
                                                    <label for="inputGroupFile01" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Sign</label>

                                                    <input
                                                       
                                                        type="file"
                                                        className="custom-file-input font-small"
                                                        id="inputGroupFile01"
                                                        aria-describedby="inputGroupFileAddon01"
                                                        name='sign'
                                                        accept='.jpg, .png, .jpeg'
                                                        onChange={this.setFileInputVar}
                                                    />
                                                    <label className="custom-file-label font-small browsefile" htmlFor="inputGroupFile01">
                                                        {this.state.signName}
                                                    </label>
                                                </div>
                                            </div>               
                                                <div className="row  justify-content-left ">
                                                    <div className="col-sm-6 col-xs-12">
                                                    <MDBBtn size='sm' onClick={this.saveDataPersonalInfo} className="ContactSave">
                                                        <span className="Savedata">
                                                            Save
                                                        </span>

                                                    </MDBBtn>
                                                    </div>
                                                    <div className="col-sm-6">
                                                    <MDBBtn size='sm' onClick={this.closePersonalInfo} className="contactcancel">
                                                        <span className="Savedata">
                                                            Cancel
                                                        </span>
                                                    </MDBBtn>
                                                    </div>
                                                </div>

                                           

                                        </MDBCardBody>
                                    </MDBCard>

                                </div>
                            </MDBTabPane>
                            <MDBTabPane tabId="2" role="tabpanel">
                                <AddressInfoCardSection setAddressInfo={this.setAddressInfo} addRessInfo={this.state.addressInfoData} contactId={this.state.contactId} contactType={this.state.contactType} />
                            </MDBTabPane>
                        </MDBTabContent>

                        <APIResponse
                            isOpen={this.state.apiResponseModalOpen}
                            response={this.state.apiResponse}
                            msg={this.state.apiResponseMsg}
                            closeAPIResponse={this.closeAPIResponse}
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }
}
export default ContactModal;