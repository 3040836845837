import React, { Component } from "react";
import { MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn, MDBContainer } from "mdbreact";
import * as moment from 'moment';
import HttpAPI from '../../common/api_calls';
import "../Styleing.css";
class ContactDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: this.props.isOpen,
            record: this.props.contactRecord,
            addressList: [],
        }
    }
    closeModal = () => {
        this.setState({
            openModal: false
        });

    }
    componentWillReceiveProps(newProps) {
        
        if (typeof newProps.contactRecord.id !== undefined) {
            HttpAPI.getAddressByContactId(newProps.contactRecord.id).then(
                response => {
                    var response = response.data;
                    this.setState({
                        addressList: response.data
                    })

                }
            )
        }
        this.setState({
            openModal: newProps.isOpen,
            record: newProps.contactRecord,
        })
    }
    getAddress() {
        let arr = []
        if (this.state.addressList !== undefined) {


            for (var i = 0; i < this.state.addressList.length; i++) {
                let addressRow = this.state.addressList[i]
                arr.push(<MDBContainer >
                    <MDBRow className="justify-content-left ml-0 mx-auto">
                        <MDBCol lg='3' className=''>
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol size='3' lg='7' className='m-0 p-0 '>
                                    <label className='small font-weight-bold  '>Address ID  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small lead'>{addressRow.addressId}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3' className=''>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                
                                <MDBCol size='3' lg='7' className='m-0 p-0 '>
                                    <label className='small font-weight-bold '>Address Type  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0 '>
                                    <label className='small'>{addressRow.addressType}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='8' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Address Line 1  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{addressRow.addressLine1}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='8' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Address Line 2  ;-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{addressRow.addressLine2}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="justify-content-left ml-0 mx-auto">
                        <MDBCol lg='3' className=''>
                            <MDBRow className="justify-content-left ml-0 mx-auto">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Country  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small lead'>{addressRow.countryMST == null ? '' : addressRow.countryMST.countryName}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3' className=''>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small  font-weight-bold'>State  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small'>{addressRow.stateMST == null ? '' : addressRow.stateMST.stateName}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>City  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small ml-3'>{addressRow.cityMST == null ? '' : addressRow.cityMST.cityName}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailformodelyear ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Pincode  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{addressRow.pinCodes}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>);

            }
        }
        return arr
    }
    render() {
        return (<div>
            <MDBModal isOpen={this.state.openModal} size='lg'>
                <MDBModalHeader >Purchase Detail</MDBModalHeader>
                <MDBModalBody>
                    <MDBRow className="justify-content-left ml-0 mx-auto ">
                        <MDBCol lg='3' className=''>
                            <MDBRow className="justify-content-left ml-0 mx-auto ">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Contact Type  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small lead ml-1'>{this.state.record.contactType}</label>
                                </MDBCol>
                            </MDBRow >
                        </MDBCol>
                        <MDBCol lg='3' className=''>
                            <MDBRow className="purchasedetailmargin ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold  '>First Name  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small purchasedetailmarginlabe'>{this.state.record.firstName}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailmargin ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Middle Name  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small purchasedetailmarginlabe '>{this.state.record.middleName}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailmargin ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                    <label className='small font-weight-bold '>Last Name  :-</label>
                                </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small purchasedetailmarginlabe'>{this.state.record.lastName}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow  className="justify-content-left ml-0 mx-auto ">
                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-0 mx-auto ">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small font-weight-bold '>Mobile No  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.mobileNumber}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailmargin ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small font-weight-bold '>Phone No  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <label className='small '>{this.state.record.phoneNumber}</label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailmargin ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small font-weight-bold '>{this.state.record.idType}</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <img src={this.state.record.idImage == null ? '' : this.state.record.idImage} alt="thumbnail" className="img-thumbnail" />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailmargin ml-2">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small font-weight-bold'>{this.state.record.addressProof}</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <img src={this.state.record.addressProofImage == null ? '' : this.state.record.addressProofImage} alt="thumbnail" className="img-thumbnail" />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="justify-content-left ml-0 mx-auto ">

                        <MDBCol lg='3'>
                            <MDBRow className="justify-content-left ml-0 mx-auto ">
                                <MDBCol size='3' lg='7' className='m-0 p-0'>
                                <label className='small font-weight-bold'>Photo  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='3' className='m-0 p-0'>
                                    <img src={this.state.record.photo == null ? '' : this.state.record.photo} alt="thumbnail" className="img-thumbnail" />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol lg='3'>
                            <MDBRow className="purchasedetailmargin ml-2">
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                <label className='small font-weight-bold'>Sign  :-</label>
                            </MDBCol>
                                <MDBCol size='3' lg='6' className='m-0 p-0'>
                                    <img src={this.state.record.sign == null ? '' : this.state.record.sign} alt="thumbnail" className="img-thumbnail" />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='border border-dark'>
                        <MDBCol lg='12'>
                        {this.getAddress()}
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="secondary" onClick={this.closeModal} >Close</MDBBtn>
                </MDBModalFooter>
            </MDBModal>        </div>)
    }
}
export default ContactDetail