import React, { Component } from "react";
import { MDBRow, MDBFormInline, MDBIcon, MDBInput, MDBCard, MDBTabContent, MDBTabPane, MDBContainer, MDBNavItem, MDBNavLink, MDBNav, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBCardBody, MDBBreadcrumbItem, MDBCol } from 'mdbreact';
import { unstable_renderSubtreeIntoContainer } from "react-dom";
import DatePicker from "react-datepicker";
import HttpAPI from '../../common/api_calls';
import ApiUtils from '../../common/api_utils';
import APIResponse from '../../common/APIResponse'
import APILoader from '../../common/APILoader'
import CustomBreadCrumb from '../sections/CustomBreadCrumb'
import CustomCheckBox from '../sections/CustomCheckBox'
import Select from 'react-select';
import { useHistory } from "react-router-dom";
import "../Styleing.css";
class AddRCModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startdate: new Date(),
            purchaseNumber: '',
            rcBookId: '',
            stock: '',
            company: '',
            model: '',
            customerId: 0,
            customerName: '',
            loandetail: '',
            parsingDt: null,
            bikeNumber: '',
            engineNumber: '',
            chasisNumber: '',
            rtoAddress: '',
            apiResponse: false,
            apiResponseModalOpen: false,
            loadingIsOpen:false,
            apiResponseMsg: '',
            companyId: 0,
            modelId: 0,
            companyData: [],
            modelData: [],
            companyName: '',
            modelName: '',
            filterModel: [],
            rcdata: [],
            smsValue: false,
            updaterow: {},
            id: 0,
            rcId: 0,
            purchasRCId: 0,
            mobileNumber: '',
            allDocReceived: false,
            idProofReceived: false,
            apiResponse: false,
            apiResponseModalOpen: false,
            isFinance: false,
            apiResponseMsg: '',
            contactData: [],
            purchaseData: [],
            allPurchaseData: [],
            purchaseRecord: {},
            isPurchaseDis: false,
            selectedForm35Copy: '',
            form352Copy: false,
            form351Copy: false,
            noc: false,
            kyc: false,
            selectedpurchaseNumber: '',
            selectedcompany: '',
            selectedmodel: '',
            selectedcustomer: '',
            selectedloanDetail: '',
            purchaseNumOptions: [{ id: 0, name: 'purchaseNumber', label: "", value: "" }],
            companyOptions: [{ id: 0, name: 'company', label: "", value: "" }],
            modelOptions: [{ id: 0, name: 'model', label: "", value: "" }],
            customerOptions: [{ id: 0, name: 'customer', label: "", value: "" }],
            loanDetailOptions: [{ name: 'loanDetail', label: "Cash", value: "Cash" }, { name: 'loanDetail', label: "Loan", value: "Loan" }],
            errorMap: {
                'purchaseNumber': { 'valid': true, 'error': '' },
                'rcBookId': { 'valid': true, 'error': '' },
                'stock': { 'valid': true, 'error': '' },
                'customer': { 'valid': true, 'error': '' },
                'company': { 'valid': true, 'error': '' },
                'model': { 'valid': true, 'error': '' },
                'rcRecvdDate': { 'valid': true, 'error': '' },
                'bikeNumber': { 'valid': true, 'error': '' },
                'engineNumber': { 'valid': true, 'error': '' },
                'chasisNumber': { 'valid': true, 'error': '' },
                'rtoAddress': { 'valid': true, 'error': '' },
            },
        }
        this.purchaseNumInput = React.createRef();
        this.rcBookIdInput = React.createRef();
        this.stockInput = React.createRef();
        this.customerInput = React.createRef();
        this.companyInput = React.createRef();
        this.modelInput = React.createRef();
        this.rcRecvdDateInput = React.createRef();
        this.bikeNumberInput = React.createRef();
        this.engineNumberInput = React.createRef();
        this.chasisNumberInput = React.createRef();
        this.rtoAddressInput = React.createRef();
    }
    toggle = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });

    }
    componentDidMount() {
        this.renderDataFromServer();
    }
    renderDataFromServer() {
        window.localStorage.setItem('openRCFromId', 0)
        this.setState({loadingIsOpen:true})
        if(window.localStorage.getItem('editRCId')>0){
            HttpAPI.getPurchaseByRCId(window.localStorage.getItem('editRCId')).then(
                response => {
                    var response = response.data;
                    let pdata=[]
                    pdata.push(response.data)
                    this.setState({
                        allPurchaseData: pdata
                    })
                    if (response.data.length > 0) {
                        let NoRCPurchase = response.data.filter(row => row.rcId === null)
                        this.setState({
                            purchaseData: NoRCPurchase
                        })
                        var arr = []
                        arr.push({ id: 0, name: 'purchaseNumber', label: '', value: '' })
                        NoRCPurchase.map((item, key) =>
                            arr.push({ id: item.id, name: 'purchaseNumber', label: item.purchaseNumber, value: item.purchaseNumber })
                        );
                        this.setState({ purchaseNumOptions: arr })
                    }
                    if (window.localStorage.getItem('openRCFrom') !== null && window.localStorage.getItem('openRCFrom') === 'purchase') {
                        // this.setState({
                        //     purchaseData: JSON.parse(window.localStorage.getItem('RCdata'))
                        // })
                        let id = window.localStorage.getItem('editRCId')
            
                        if (id > 0) {
                            this.fillEditRCData()
                        } else {
                            this.state.purchaseData.push(JSON.parse(window.localStorage.getItem('RCdata')))
                            this.setEditData(JSON.parse(window.localStorage.getItem('RCdata')), "purchase");
                        }
            
                    } else {
                        this.fillEditRCData()
                    }
                    this.setState({loadingIsOpen:false})
                }    
            )
        }else{
        HttpAPI.getPurchase().then(
            response => {
                var response = response.data;
                this.setState({
                    allPurchaseData: response.data
                })
                if (response.data.length > 0) {
                    let NoRCPurchase = response.data.filter(row => row.rcId === null)
                    this.setState({
                        purchaseData: NoRCPurchase
                    })
                    var arr = []
                    arr.push({ id: 0, name: 'purchaseNumber', label: '', value: '' })
                    NoRCPurchase.map((item, key) =>
                        arr.push({ id: item.id, name: 'purchaseNumber', label: item.purchaseNumber, value: item.purchaseNumber })
                    );
                    this.setState({ purchaseNumOptions: arr })
                }
                if (window.localStorage.getItem('openRCFrom') !== null && window.localStorage.getItem('openRCFrom') === 'purchase') {
                    // this.setState({
                    //     purchaseData: JSON.parse(window.localStorage.getItem('RCdata'))
                    // })
                    let id = window.localStorage.getItem('editRCId')
        
                    if (id > 0) {
                        this.fillEditRCData()
                    } else {
                        this.state.purchaseData.push(JSON.parse(window.localStorage.getItem('RCdata')))
                        this.setEditData(JSON.parse(window.localStorage.getItem('RCdata')), "purchase");
                    }
        
                } else {
                    this.fillEditRCData()
                }
                this.setState({loadingIsOpen:false})
            }
        );
    }
    }
    fillEditRCData() {

        let id = window.localStorage.getItem('editRCId')
        if (id > 0) {
            HttpAPI.getRCById(id).then(
                response => {
                    var response = response.data;
                    if (response.status === true) {
                        this.setEditData(response.data, "");
                    }
                }
            );
        }
    }
    closeAPIResponse = () => {
        this.setState({
            apiResponse: false,
            apiResponseMsg: '',
            apiResponseModalOpen: false
        })
    }

    setInputVar = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    changeinputvar = (e) => {
        this.setState({
            [e.target.name]: e.target.checked
        })
    }

    changeSelectVar = (e) => {
        if (e.name === 'customer') {
            let id = e.id;
            this.setState({
                [e.name + 'Id']: e.id,
                [e.name + 'Name']: e.value,
                ['selected' + e.name]: e
            });
            if (id > 0) {
                let filterRow = this.state.contactData.filter(row => row.id === Number(id))[0]
                this.setState({ mobileNumber: filterRow.mobileNumber })
            }
        } else {
            this.setState({
                [e.name + 'Id']: e.id,
                [e.name + 'Name']: e.value,
                ['selected' + e.name]: e
            });
        }
    }

    handleDateChange = (e) => {
        this.setState({ startdate: e });
    }


    validateData = () => {
        let map = {
            'purchaseNumber': { 'valid': true, 'error': '' },
            'rcBookId': { 'valid': true, 'error': '' },
            'stock': { 'valid': true, 'error': '' },
            'customer': { 'valid': true, 'error': '' },
            'company': { 'valid': true, 'error': '' },
            'model': { 'valid': true, 'error': '' },
            'rcRecvdDate': { 'valid': true, 'error': '' },
            'bikeNumber': { 'valid': true, 'error': '' },
            'engineNumber': { 'valid': true, 'error': '' },
            'chasisNumber': { 'valid': true, 'error': '' },
            'rtoAddress': { 'valid': true, 'error': '' },
        }
        let isValid = true;
        if (this.state.purchaseNumber === '') {
            //this.setState({ apiResponseMsg: 'Please Fill Purchase No' })
            map.purchaseNumber.valid = false;
            map.purchaseNumber.error = 'Please Fill Purchase No'
            this.purchaseNumInput.current.focus()
            isValid = false
        }
        if (this.state.customerName === '' || this.state.customerName === 'select customer') {
            //this.setState({ apiResponseMsg: 'Please select customer' })
            map.customer.valid = false;
            map.customer.error = 'Please select customer'
            if (isValid === true) {
                this.customerInput.current.focus()
            }
            isValid = false
        }
        if (this.state.companyName === '' || this.state.companyName === 'select company') {
            //this.setState({ apiResponseMsg: 'Please select customer' })
            map.company.valid = false;
            map.company.error = 'Please select company'
            if (isValid === true) {
                this.companyInput.current.focus()
            }
            isValid = false
        }
        if (this.state.modelName === '' || this.state.modelName === 'select model') {
            //this.setState({ apiResponseMsg: 'Please select model' })
            map.model.valid = false;
            map.model.error = 'Please select model'
            if (isValid === true) {
                this.modelInput.current.focus()
            }
            isValid = false
        }

        if (this.state.bikeNumber === '') {
            //this.setState({ apiResponseMsg: 'Please fill Bike Number' })
            map.bikeNumber.valid = false;
            map.bikeNumber.error = 'Please fill Bike Number'
            if (isValid === true) {
                this.bikeNumberInput.current.focus()
            }
            isValid = false
        } else if (ApiUtils.ValidateBikeNo(this.state.bikeNumber) === false) {
            //this.setState({ apiResponseMsg: 'Please fill valid Bike No' })
            map.bikeNumber.valid = false;
            map.bikeNumber.error = 'Please fill valid Bike No'
            if (isValid === true) {
                this.bikeNumberInput.current.focus()
            }
            isValid = false
        }
        if (this.state.engineNumber === '') {
            //this.setState({ apiResponseMsg: 'Please fill Engine Number' })
            map.engineNumber.valid = false;
            map.engineNumber.error = 'Please fill Engine No'
            if (isValid === true) {
                this.engineNumberInput.current.focus()
            }
            isValid = false
        } else if (ApiUtils.validateEngineNo(this.state.engineNumber) === false) {
            //this.setState({ apiResponseMsg: 'Please fill valid Engine No' })
            map.engineNumber.valid = false;
            map.engineNumber.error = 'Please fill valid Engine No'
            if (isValid === true) {
                this.engineNumberInput.current.focus()
            }
            isValid = false
        }
        if (this.state.chasisNumber === '') {
            //this.setState({ apiResponseMsg: 'Please fill Chasis Number' })
            map.chasisNumber.valid = false;
            map.chasisNumber.error = 'Please fill Chasis No'
            if (isValid === true) {
                this.chasisNumberInput.current.focus()
            }
            isValid = false
        } else if (ApiUtils.validateChasisNo(this.state.chasisNumber) === false) {
            //this.setState({ apiResponseMsg: 'Please fill valid Chasis No' })
            map.chasisNumber.valid = false;
            map.chasisNumber.error = 'Please fill valid Chasis No'
            if (isValid === true) {
                this.chasisNumberInput.current.focus()
            }
            isValid = false
        }
        if (this.state.rtoAddress === '') {
            //this.setState({ apiResponseMsg: 'Please fill RTO Address' })
            map.rtoAddress.valid = false;
            map.rtoAddress.error = 'Please fill RTO Address'
            if (isValid === true) {
                this.rtoAddressInput.current.focus()
            }
            isValid = false
        }
        if (this.state.rcBookId === '') {
            //this.setState({ apiResponseMsg: 'Please Fill RC Book ID' })
            map.rcBookId.valid = false;
            map.rcBookId.error = 'Please Fill RC Book No'
            if (isValid === true) {
                this.rcBookIdInput.current.focus()
            }
            isValid = false
        }
        if (this.state.stock === '') {
            //this.setState({ apiResponseMsg: 'Please Fill Stock' })
            map.stock.valid = false;
            map.stock.error = 'Please Fill Stock'
            if (isValid === true) {
                this.stockInput.current.focus()
            }
            isValid = false
        }



        if (this.state.rcRecvdDate === null) {
            //this.setState({ apiResponseMsg: 'Please select RC Received Date' })
            map.rcRecvdDate.valid = false;
            map.rcRecvdDate.error = 'Please select RC Received Date'
            if (isValid === true) {
                this.rcRecvdDateInput.current.focus()
            }
            isValid = false
        }
        this.setState({ errorMap: map })
        return isValid;
    }
    createRC = () => {
        let data = {
            'purchaseNumber': this.state.purchaseNumber,
            'rcBookId': this.state.rcBookId,
            'stock': this.state.stock,
            'rcRecvdDate': this.state.startdate,

        };
        this.setState({loadingIsOpen:true})
        let senddata = ApiUtils.appendCreateRequiredField(data);
        HttpAPI.createRC(senddata).then(
            response => {
                var response = response.data;
                if (response.status === true) {

                    if (window.localStorage.getItem('openRCFrom') === '') {
                        this.state.purchaseRecord.rcId = response.data.id
                        this.state.purchaseRecord.rcBookReceived = true
                        this.state.purchaseRecord.form35 = this.state.form351Copy
                        this.state.purchaseRecord.form352Copy = this.state.form352Copy
                        this.state.purchaseRecord.kyc = this.state.kyc
                        this.state.purchaseRecord.noc = this.state.noc
                        this.state.purchaseRecord.parsingDate = this.state.parsingDt
                        this.state.purchaseRecord.rtoDetail = this.state.rtoAddress
                        HttpAPI.updatePurchase(this.state.purchaseRecord).then()
                    } else {
                        let purchaseDataRecord = JSON.parse(window.localStorage.getItem('RCdata'))
                        purchaseDataRecord.rcBookReceived = true
                        purchaseDataRecord.form35 = this.state.form351Copy
                        purchaseDataRecord.form352Copy = this.state.form352Copy
                        purchaseDataRecord.kyc = this.state.kyc
                        purchaseDataRecord.noc = this.state.noc
                        purchaseDataRecord.parsingDate = this.state.parsingDt
                        purchaseDataRecord.rtoDetail = this.state.rtoAddress
                        window.localStorage.setItem('RCdata', JSON.stringify(purchaseDataRecord))
                        window.localStorage.setItem('openRCFromId', response.data.id)
                    }
                    
                    if (window.localStorage.getItem('openRCFrom') === 'purchase') {
                        window.localStorage.setItem('editRCId', 0)
                        window.localStorage.setItem('openRCFrom', '')
                        //window.localStorage.setItem('RCdata', '')
                        window.history.go(-1);
                    } else {
                        this.clearData();
                        this.renderDataFromServer();
                    }
                    this.setState({
                        APILoader:false,
                        apiResponse: true,
                        apiResponseMsg: 'RC added Successfully..',
                        apiResponseModalOpen: true
                    })


                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.closeAPIResponse();
                }, 1000)
            }
        );
    }
    UpdateRC = () => {
        let data = {
            'id': this.state.rcId,
            'purchaseNumber': this.state.purchaseNumber,
            'rcBookId': this.state.rcBookId,
            'stock': this.state.stock,
            'rcRecvdDate': this.state.startdate,
            'active': this.state.editrcdata.active,
            'createdAt': this.state.editrcdata.createdAt,
            'createdBy': this.state.editrcdata.createdBy,
        }


        let senddata = ApiUtils.appendUpdateRequiredField(data);
        this.setState({APILoader:true})
        HttpAPI.UpdateRC(senddata).then(
            response => {
                var response = response.data;
                if (response.status === true) {
                    this.state.purchaseRecord.rcId = response.data.id
                    this.state.purchaseRecord.rcBookReceived = true
                    this.state.purchaseRecord.form35 = this.state.form351Copy
                    this.state.purchaseRecord.form352Copy = this.state.form352Copy
                    this.state.purchaseRecord.kyc = this.state.kyc
                    this.state.purchaseRecord.noc = this.state.noc
                    this.state.purchaseRecord.parsingDate = this.state.parsingDt
                    this.state.purchaseRecord.rtoDetail = this.state.rtoAddress
                    HttpAPI.updatePurchase(this.state.purchaseRecord).then()
                   
                    if (window.localStorage.getItem('openRCFrom') === 'purchase') {
                        window.localStorage.setItem('editRCId', 0)
                        window.localStorage.setItem('openRCFrom', '')
                        //window.localStorage.setItem('RCdata', '')
                        window.history.go(-1);
                    } else {
                        this.clearData();
                        this.renderDataFromServer();
                    }
                    this.setState({
                        APILoader:false,
                        apiResponse: true,
                        apiResponseMsg: 'RC Update Successfully',
                        apiResponseModalOpen: true
                    })

                } else {
                    this.setState({
                        apiResponse: false,
                        apiResponseMsg: response.message,
                        apiResponseModalOpen: true
                    })
                }
                window.setTimeout(() => {
                    this.closeAPIResponse();
                }, 2000)
            }
        );
    }
    closeRCAddForm = () => {
        this.clearData();
        // if (this.props.openFrom === 'AddPurchase') {
        //     this.props.closeaddrcmodelwithId(this.state.purchasRCId);
        // } else {
        //     this.props.closeaddrcmodel();
        // }
        
        window.location.href = '/rc'
        this.setState({ purchasRCId: 0 })
    }
    setEditData = (editrcdata, openFrom) => {
        const data = this.state.modelData.filter(row => row.companyId === editrcdata.companyId);
        this.setState({
            filterModel: data
        });
        
        if (openFrom === 'purchase') {
            let id = window.localStorage.getItem('editRCId')
            if (id > 0) {
                
                var purchaseRow = this.state.allPurchaseData.filter(row => row.rcId === Number(id))[0]

                this.setState({
                    purchaseRecord:purchaseRow,
                    purchaseNumber: purchaseRow.purchaseNumber,
                    companyId: purchaseRow.companyId,
                    modelId: purchaseRow.modelId,
                    companyName: purchaseRow.companyMST.description,
                    modelName: purchaseRow.modelMST.description,
                    bikeNumber: purchaseRow.bikeNumber,
                    engineNumber: purchaseRow.engineNumber,
                    chasisNumber: purchaseRow.chasisNumber,
                    customerId: purchaseRow.contactId,
                    isFinance: purchaseRow.financed,
                    customerName: purchaseRow.contactMST.firstName,
                    mobileNumber: purchaseRow.contactMST.mobileNumber,
                    rtoAddress: purchaseRow.rtoDetail,
                    form351Copy: purchaseRow.form35,
                    form352Copy: purchaseRow.form352Copy,
                    kyc: purchaseRow.kyc,
                    noc: purchaseRow.noc,
                    parsingDt: new Date(purchaseRow.parsingDate),
                })
                if (purchaseRow.form35 === true) {
                    this.setState({ selectedForm35Copy: '1' })
                } else if (purchaseRow.form352Copy === true) {
                    this.setState({ selectedForm35Copy: '2' })
                } else {
                    this.setState({ selectedForm35Copy: '' })
                }
                this.setState({
                    selectedpurchaseNumber: [{ id: 0, name: 'purchaseNumber', label: editrcdata.purchaseNumber, value: editrcdata.purchaseNumber }],
                    selectedcompany: [{ id: editrcdata.companyId, name: 'company', label: editrcdata.companyMST.description, value: editrcdata.companyMST.description }],
                    selectedmodel: [{ id: editrcdata.modelId, name: 'model', label: editrcdata.modelMST.description, value: editrcdata.modelMST.description }],
                    selectedcustomer: [{ id: editrcdata.contactId, name: 'customer', label: editrcdata.contactMST.firstName, value: editrcdata.contactMST.firstName }],
                })
                this.setState({
                    companyOptions:[{ id: editrcdata.companyId, name: 'company', label: editrcdata.companyMST.description, value: editrcdata.companyMST.description }],
                    modelOptions: [{ id: editrcdata.modelId, name: 'model', label: editrcdata.modelMST.description, value: editrcdata.modelMST.description }],
                    customerOptions: [{ id: editrcdata.contactId, name: 'customer', label: editrcdata.contactMST.firstName, value: editrcdata.contactMST.firstName }],
                })
                this.setState({
                    rcBookId: editrcdata.rcBookId,
                    stock: editrcdata.stock,
                    rcRecvdDate: editrcdata.startdate,
                })
                this.setState({
                    selectedpurchaseNumber: [{ id: 0, name: 'purchaseNumber', label: editrcdata.purchaseNumber, value: editrcdata.purchaseNumber }],
                })
            } else {
                this.setState({
                    purchaseRecord:editrcdata,
                    purchaseNumber: editrcdata.purchaseNumber,
                    companyId: editrcdata.companyId,
                    modelId: editrcdata.modelId,
                    companyName: editrcdata.companyMST.description,
                    modelName: editrcdata.modelMST.description,
                    bikeNumber: editrcdata.bikeNumber,
                    engineNumber: editrcdata.engineNumber,
                    chasisNumber: editrcdata.chasisNumber,
                    customerId: editrcdata.contactId,
                    isFinance: editrcdata.financed,
                    customerName: editrcdata.contactMST.firstName,
                    mobileNumber: editrcdata.contactMST.mobileNumber,
                    rtoAddress: editrcdata.rtoDetail,
                    form351Copy: editrcdata.form35,
                    form352Copy: editrcdata.form352Copy,
                    kyc: editrcdata.kyc,
                    noc: editrcdata.noc,
                    parsingDt: new Date(editrcdata.parsingDate),
                })
                if (editrcdata.form35 === true) {
                    this.setState({ selectedForm35Copy: '1' })
                } else if (editrcdata.form352Copy === true) {
                    this.setState({ selectedForm35Copy: '2' })
                } else {
                    this.setState({ selectedForm35Copy: '' })
                }
                this.setState({
                    selectedpurchaseNumber: [{ id: 0, name: 'purchaseNumber', label: editrcdata.purchaseNumber, value: editrcdata.purchaseNumber }],
                    selectedcompany: [{ id: editrcdata.companyId, name: 'company', label: editrcdata.companyMST.description, value: editrcdata.companyMST.description }],
                    selectedmodel: [{ id: editrcdata.modelId, name: 'model', label: editrcdata.modelMST.description, value: editrcdata.modelMST.description }],
                    selectedcustomer: [{ id: editrcdata.contactId, name: 'customer', label: editrcdata.contactMST.firstName, value: editrcdata.contactMST.firstName }],
                })
                this.setState({
                    companyOptions: [{ id: editrcdata.companyId, name: 'company', label: editrcdata.companyMST.description, value: editrcdata.companyMST.description }],
                    modelOptions: [{ id: editrcdata.modelId, name: 'model', label: editrcdata.modelMST.description, value: editrcdata.modelMST.description }],
                    customerOptions: [{ id: editrcdata.contactId, name: 'customer', label: editrcdata.contactMST.firstName, value: editrcdata.contactMST.firstName }],
                })
            }
        } else {
            
            this.setState({ editrcdata: editrcdata })
            this.setState({ rcId: editrcdata.id })
            
            let purchaseRow = this.state.allPurchaseData.filter(row => row.rcId === editrcdata.id)[0]
            this.setState({
                purchaseRecord:purchaseRow,
                companyId: purchaseRow.companyId,
                modelId: purchaseRow.modelId,
                companyName: purchaseRow.companyMST.description,
                modelName: purchaseRow.modelMST.description,
                customerId: purchaseRow.contactId,
                customerName: purchaseRow.contactMST.firstName,
                mobileNumber: purchaseRow.contactMST.mobileNumber,
                bikeNumber: purchaseRow.bikeNumber,
                engineNumber: purchaseRow.engineNumber,
                isFinance: purchaseRow.financed,
                chasisNumber: purchaseRow.chasisNumber,
                rtoAddress: purchaseRow.rtoAddress,
                allDocReceived: purchaseRow.allDocsReceived,
                rtoAddress: purchaseRow.rtoDetail,
                form351Copy: purchaseRow.form35,
                form352Copy: purchaseRow.form352Copy,
                kyc: purchaseRow.kyc,
                noc: purchaseRow.noc,
                parsingDt: new Date(purchaseRow.parsingDate),
            })
            if (purchaseRow.form35 === true) {
                this.setState({ selectedForm35Copy: '1' })
            } else if (purchaseRow.form352Copy === true) {
                this.setState({ selectedForm35Copy: '2' })
            } else {
                this.setState({ selectedForm35Copy: '' })
            }
            this.setState({
                id: editrcdata.id,
                purchaseNumber: editrcdata.purchaseNumber,
                rcBookId: editrcdata.rcBookId,
                stock: editrcdata.stock,
                rcRecvdDate: editrcdata.startdate,
            })
            this.setState({
                selectedpurchaseNumber: [{ id: 0, name: 'purchaseNumber', label: editrcdata.purchaseNumber, value: editrcdata.purchaseNumber }],
                selectedcompany: [{ id: purchaseRow.companyId, name: 'company', label: purchaseRow.companyMST.description, value: purchaseRow.companyMST.description }],
                selectedmodel: [{ id: purchaseRow.modelId, name: 'model', label: purchaseRow.modelMST.description, value: purchaseRow.modelMST.description }],
                selectedcustomer: [{ id: purchaseRow.contactId, name: 'customer', label: purchaseRow.contactMST.firstName, value: purchaseRow.contactMST.firstName }],
            })
            this.setState({
                companyOptions: [{ id: purchaseRow.companyId, name: 'company', label: purchaseRow.companyMST.description, value: purchaseRow.companyMST.description }],
                modelOptions: [{ id: purchaseRow.modelId, name: 'model', label: purchaseRow.modelMST.description, value: purchaseRow.modelMST.description }],
                customerOptions: [{ id: purchaseRow.contactId, name: 'customer', label: purchaseRow.contactMST.firstName, value: purchaseRow.contactMST.firstName }],
            })
        }
        this.setState({ isPurchaseDis: true })
    }
    setSelectInputVar = (e) => {
        this.setState({
            ['selected' + e.name]: e,
            [e.name]: e.value
        });
    }

    checkBoxChange = (e) => {

        this.setState({ [e.target.name]: e.target.checked });
    }
    changeCompanyVar = (e) => {
        var filterId = Number(e.id);
        const data = this.state.modelData.filter(row => row.companyId === filterId);
        var arr = []
        arr.push({ id: 0, name: 'model', label: '', value: '' })
        data.map(item => {
            arr.push({ id: item.id, name: 'model', label: item.description, value: item.description })
        })
        this.setState({
            selectedmodel: '',
            modelOptions: arr,
        })
        this.changeSelectVar(e);
    }
    changePurchaseNo = (e) => {
        var filterId = Number(e.id);
        const data = this.state.purchaseData.filter(row => row.id === filterId);
        if (data !== null) {
            let purchaseRecord = data[0]
            this.setState({ purchaseRecord: purchaseRecord })
            const filterModelData = this.state.modelData.filter(row => row.companyId === purchaseRecord.companyId);
            this.setState({
                filterModel: filterModelData
            });
            this.setState({
                companyId: purchaseRecord.companyId,
                modelId: purchaseRecord.modelId,
                companyName: purchaseRecord.companyMST.description,
                modelName: purchaseRecord.modelMST.description,
                bikeNumber: purchaseRecord.bikeNumber,
                engineNumber: purchaseRecord.engineNumber,
                chasisNumber: purchaseRecord.chasisNumber,
                customerId: purchaseRecord.contactId,
                customerName: purchaseRecord.contactMST.firstName,
                mobileNumber: purchaseRecord.contactMST.mobileNumber,
                rtoAddress: purchaseRecord.rtoDetail,
                form351Copy: purchaseRecord.form35,
                form352Copy: purchaseRecord.form352Copy,
                kyc: purchaseRecord.kyc,
                noc: purchaseRecord.noc,
                parsingDt: new Date(purchaseRecord.parsingDate),
            })
            if (purchaseRecord.form35 === true) {
                this.setState({ selectedForm35Copy: '1' })
            } else if (purchaseRecord.form352Copy === true) {
                this.setState({ selectedForm35Copy: '2' })
            } else {
                this.setState({ selectedForm35Copy: '' })
            }
            this.setState({
                selectedpurchaseNumber: [{ id: purchaseRecord.id, name: 'purchaseNumber', label: purchaseRecord.purchaseNumber, value: purchaseRecord.purchaseNumber }],
                selectedcompany: [{ id: purchaseRecord.companyId, name: 'company', label: purchaseRecord.companyMST.description, value: purchaseRecord.companyMST.description }],
                selectedmodel: [{ id: purchaseRecord.modelId, name: 'model', label: purchaseRecord.modelMST.description, value: purchaseRecord.modelMST.description }],
                selectedcustomer: [{ id: purchaseRecord.contactId, name: 'customer', label: purchaseRecord.contactMST.firstName, value: purchaseRecord.contactMST.firstName }],
            })
            this.setState({
                companyOptions: [{ id: purchaseRecord.companyId, name: 'company', label: purchaseRecord.companyMST.description, value: purchaseRecord.companyMST.description }],
                modelOptions: [{ id: purchaseRecord.modelId, name: 'model', label: purchaseRecord.modelMST.description, value: purchaseRecord.modelMST.description }],
                customerOptions: [{ id: purchaseRecord.contactId, name: 'customer', label: purchaseRecord.contactMST.firstName, value: purchaseRecord.contactMST.firstName }],
            })
        }
        this.setState({
            //[e.target.name + 'Id']: e.target.options[e.target.selectedIndex].id,
            selectedpurchaseNumber: e,
            purchaseNumber: e.value
        });
    }

    saveData = () => {
        if (this.validateData() === true) {
            if (this.state.rcId === 0) {
                this.createRC();
            } else {
                this.UpdateRC();
            }
        }
    }
    isAllDocReceived = () => {
        if (this.state.kyc === true && this.state.noc === true && this.state.form352Copy === true) {
            return true
        }
        return false
    }
    changeFormRadio = (e) => {
        if (e.target.value === '1') {
            this.setState({ form351Copy: true, form352Copy: false })
        } else {
            this.setState({ form351Copy: false, form352Copy: true })
        }
        this.setState({ selectedForm35Copy: e.target.value })
    }
    handleParsingDateChange = (e) => {
        this.setState({ parsingDt: e })

    }
    clearData = () => {
        window.localStorage.setItem('editRCId', 0)
        window.localStorage.setItem('openRCFrom', '')
        window.localStorage.setItem('RCdata', '')
        this.setState({
            
            startdate: new Date(),
            parsingDt: null,
            purchaseNumber: '',
            rcBookId: '',
            stock: '',
            company: '',
            model: '',
            customerId: 0,
            customerName: '',
            loandetail: 'select loan detail',
            bikeNumber: '',
            engineNumber: '',
            chasisNumber: '',
            rtoAddress: '',
            companyId: 0,
            modelId: 0,
            companyData: [],
            modelData: [],
            companyName: '',
            modelName: '',
            filterModel: [],
            rcdata: [],
            smsValue: false,
            updaterow: {},
            purchaseRecord: {},
            id: 0,
            rcId: 0,
            mobileNumber: '',
            allDocReceived: false,
            idProofReceived: false,
            purchaseData: [],
            isPurchaseDis: false,
            selectedpurchaseNumber: '',
            selectedcompany: '',
            selectedmodel: '',
            selectedcustomer: '',
            selectedloanDetail: '',
            purchaseNumOptions: [{ id: 0, name: 'purchaseNumber', label: "", value: "" }],
            companyOptions: [{ id: 0, name: 'company', label: "", value: "" }],
            modelOptions: [{ id: 0, name: 'model', label: "", value: "" }],
            customerOptions: [{ id: 0, name: 'customer', label: "", value: "" }],
            loanDetailOptions: [{ name: 'loanDetail', label: "Cash", value: "Cash" }, { name: 'loanDetail', label: "Loan", value: "Loan" }],
            errorMap: {
                'purchaseNumber': { 'valid': true, 'error': '' },
                'rcBookId': { 'valid': true, 'error': '' },
                'stock': { 'valid': true, 'error': '' },
                'customer': { 'valid': true, 'error': '' },
                'company': { 'valid': true, 'error': '' },
                'model': { 'valid': true, 'error': '' },
                'rcRecvdDate': { 'valid': true, 'error': '' },
                'bikeNumber': { 'valid': true, 'error': '' },
                'engineNumber': { 'valid': true, 'error': '' },
                'chasisNumber': { 'valid': true, 'error': '' },
                'rtoAddress': { 'valid': true, 'error': '' },
            },
        })
    }
    render() {
        return (
            <div>

                <MDBRow >

                    <MDBCol lg="12">
                        <CustomBreadCrumb items={['Purchase', 'RC', 'Add RC']} linkTo={['', '/rc', '']} />
                        <MDBCard className="mb-5">
                            <MDBCardBody className="align-items-center justify-content-between">
                                <form>
                                    <div className="row">
                                        <div className="form-group col-sm-2">
                                            <label for="purchaseNo" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Purchase No</label>
                                            <Select isDisabled={this.state.isPurchaseDis} ref={this.purchaseNumInput} options={this.state.purchaseNumOptions} name='purchaseNumber' value={this.state.selectedpurchaseNumber} onChange={this.changePurchaseNo} id="select" class="input-field browser-default md-select form-control">
                                                {/* <option value="select purchase">Select</option>
                                                {
                                                    this.state.purchaseData.map(item => {
                                                        return <option id={item.id} value={item.purchaseNumber}>{item.purchaseNumber}</option>
                                                    })
                                                } */}
                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.purchaseNumber.valid == true ? '' : this.state.errorMap.purchaseNumber.error}</span>
                                        </div>
                                        <div className="form-group col-sm-2">
                                            <label for="Customer" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Customer Name</label>
                                            <Select isDisabled={true} name="customer" ref={this.customerInput} options={this.state.customerOptions} value={this.state.selectedcustomer} id="select" onChange={this.changeSelectVar} class="browser-default md-select form-control">

                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.customer.valid == true ? '' : this.state.errorMap.customer.error}</span>
                                        </div>
                                        <div className="form-group col-sm-2">
                                            <label for="Mobileno" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Mobile No</label>
                                            <input type="tel" disabled name="mobileNumber" value={this.state.mobileNumber} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="Mobileno">
                                            </input>

                                        </div>

                                        <div className="form-group col-sm-2">
                                            <label for="Company" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Company </label>
                                            <Select isDisabled={true} name='company' ref={this.companyInput} options={this.state.companyOptions} value={this.state.selectedcompany} onChange={this.changeCompanyVar} id="select" class="input-field browser-default md-select form-control">

                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.company.valid == true ? '' : this.state.errorMap.company.error}</span>
                                        </div>
                                        <div className="form-group col-sm-2">
                                            <label for="Model" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Model </label>
                                            <Select isDisabled={true} name="model" ref={this.modelInput} options={this.state.modelOptions} value={this.state.selectedmodel} onChange={this.changeSelectVar} id="select" class="browser-default md-select form-control">

                                            </Select>
                                            <span className='error-message'>{this.state.errorMap.model.valid == true ? '' : this.state.errorMap.model.error}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-sm-2">
                                            <label for="Bikeno" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Bike No</label>
                                            <input disabled type="text" ref={this.bikeNumberInput} name="bikeNumber" value={this.state.bikeNumber} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="Bikeno">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.bikeNumber.valid == true ? '' : this.state.errorMap.bikeNumber.error}</span>
                                        </div>
                                        <div className="form-group col-sm-2">
                                            <label for="EngineNo" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Engine No</label>
                                            <input disabled type="text" ref={this.engineNumberInput} name="engineNumber" value={this.state.engineNumber} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="EngineNo">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.engineNumber.valid == true ? '' : this.state.errorMap.engineNumber.error}</span>
                                        </div>
                                        <div className="form-group col-sm-2">
                                            <label for="chasisno" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Chasis No</label>
                                            <input disabled type="text" ref={this.chasisNumberInput} name="chasisNumber" value={this.state.chasisNumber} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="chasisno">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.chasisNumber.valid == true ? '' : this.state.errorMap.chasisNumber.error}</span>
                                        </div>
                                        <div className="form-group col-sm-2">
                                            <label for="rtoaddress" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >RTO Detail</label>
                                            <input type="text" ref={this.rtoAddressInput} name="rtoAddress" value={this.state.rtoAddress} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="rtoaddress">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.rtoAddress.valid == true ? '' : this.state.errorMap.rtoAddress.error}</span>
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12">
                                            <label for="Parsing Dt" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Passing Date</label>
                                            <DatePicker
                                                selected={this.state.parsingDt}
                                                onChange={this.handleParsingDateChange}
                                                dateFormat="dd-MM-yyyy"
                                                placeholderText='Passing Dt'
                                                className="form-control"
                                                isClearable />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="form-group col-sm-2 purchasecheckbox">
                                            <CustomCheckBox disable={true} id="isFinanceCheck" name='isFinance' value='Is Financed' handleCheckChieldElement={this.checkBoxChange} checked={this.state.isFinance} />
                                        </div>
                                        <div className="form-group col-sm-2">
                                            <label for="rcbookno" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Rc Book No</label>
                                            <input type="text" name="rcBookId" ref={this.rcBookIdInput} value={this.state.rcBookId} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="rcbookno">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.rcBookId.valid == true ? '' : this.state.errorMap.rcBookId.error}</span>
                                        </div>
                                        <div className="form-group col-sm-2">
                                            <label for="stock" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Stock</label>
                                            <input type="text" name="stock" ref={this.stockInput} value={this.state.stock} className="form-control form-text font-small m-0 p-0" onChange={this.setInputVar} id="stock">
                                            </input>
                                            <span className='error-message'>{this.state.errorMap.stock.valid == true ? '' : this.state.errorMap.stock.error}</span>
                                        </div>
                                        <div className="col-sm-2">
                                            <label for="stock" style={{ 'font-size': '0.7rem', 'color': 'grey' }} >Received Date</label>
                                            <DatePicker
                                                selected={this.state.startdate}
                                                onChange={this.handleDateChange}
                                                className="form-control"
                                                ref={this.rcRecvdDateInput}
                                                isClearable />


                                        </div>
                                        <div className="col-sm-4" >
                                            <label for="loanaccountno" className="ml- " style={{ 'font-size': '0.7rem', 'color': 'grey' }}  > Doc's Received</label>
                                            <div className=" form-group h-70 " style={{ width: '170px', border: '1px solid gray' }}>
                                                <label for="loanaccountno" className="ml-3 font-weight-bold text-left" style={{ 'font-size': '0.7rem', 'color': 'grey' }}  >Form 35</label>
                                                <div className="radiochecked">
                                                    <input type="radio" onChange={this.changeFormRadio} value='1' checked={this.state.selectedForm35Copy === '1'} />
                                                    <label for="copy" className="text-left" style={{ 'font-size': '0.8rem', 'color': 'grey' }}>1 Copy</label>
                                                    <input type="radio" className="ml-2 mt-2" onChange={this.changeFormRadio} value='2' checked={this.state.selectedForm35Copy === '2'} />
                                                    <label for="copy" className="text-left" style={{ 'font-size': '0.8rem', 'color': 'grey' }}>2 Copy</label>
                                                </div>


                                                <div className="radiochecked " >
                                                    <input type="Checkbox" className="" id="nocId" name='noc' onChange={this.checkBoxChange} checked={this.state.noc} />
                                                    <label for="copy" className="ml-2" style={{ 'font-size': '0.8rem', 'color': 'grey' }}>NOC</label>
                                                    <input type="Checkbox" className="ml-3 mt-3" id="kycId" name='kyc' onChange={this.checkBoxChange} checked={this.state.kyc} />
                                                    <label for="copy" className="ml-2 " style={{ 'font-size': '0.8rem', 'color': 'grey' }}>KYC</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>





                                <MDBRow className="SaveCancelbutton">
                                    <MDBCol sm='12'>
                                        <MDBBtn size='sm' onClick={this.saveData}>
                                            <span className="Savedata">
                                                Save
                                            </span>
                                        </MDBBtn>

                                        <MDBBtn size='sm' onClick={this.closeRCAddForm}>
                                            <span className="Savedata">
                                                Cancel
                                            </span>
                                        </MDBBtn>
                                    </MDBCol>

                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                        <APIResponse
                            isOpen={this.state.apiResponseModalOpen}
                            response={this.state.apiResponse}
                            msg={this.state.apiResponseMsg}
                            closeAPIResponse={this.closeAPIResponse}
                        />
                        <APILoader isOpen={this.state.loadingIsOpen} />
                    </MDBCol>
                </MDBRow>
            </div>

        );
    }
}
export default AddRCModal;