import React from 'react';
var ApiUtils = {

  checkStatus: function (response) {
    /*
    */
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      let error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  },
  validateZipcode(zipCode) {
    zipCode = String(zipCode);
    if (zipCode.length >= 5 && zipCode.length <= 6 && (zipCode != "000000") && (zipCode != "00000")) {
      return true;
    } else {
      return false;
    }
  },
  getCreatedBy() {
    let createdBy = {
      'createdAt': this.getCurrentTimeStamp(),
      'createdBy': this.getLoggedInUser()

    }
    return createdBy;
  },
  getUpdatedBy() {
    let updatedBy = {
      'updatedAt': this.getCurrentTimeStamp(),
      'updatedBy': this.getLoggedInUser()

    }
    return updatedBy;
  },
  getIDType(){
    let type=['Pan Card','Adhar Card']
    return type; 
  },
  getContactType(){
    let type=['Dealer','Customer','Bank']
    return type;
  },
  getAddressType(){
    let type=['Resident','Branch','Office','Yard']
    return type;
  },
  getVehicaleCategory(){
    let type=['Two Wheeler','Three Wheeler','Four Wheeler']
    return type;
  },
  getModeOfPaymentOption(){
    return [{  name: 'modeOfPayment', label: "", value: "" },
    { name: 'modeOfPayment', label: "Cash", value: "Cash" },
    { name: 'modeOfPayment', label: "Cheque", value: "Cheque" },
    { name: 'modeOfPayment', label: "Angadia", value: "Angadia" },
    { name: 'modeOfPayment', label: "Reference", value: "Reference" },
  ]
  },
  getInstallementOption(){
    return [{  name: 'installment', label: "0", value: "0" },
    { name: 'installment', label: "1", value: "1" },
    { name: 'installment', label: "3", value: "3" },
    { name: 'installment', label: "6", value: "6" },
    { name: 'installment', label: "9", value: "9" },
    { name: 'installment', label: "12", value: "12" },
  ]
  },
  appendCreateRequiredField(data){
    let isActive={'active':true}
    let retData=Object.assign(data,isActive,ApiUtils.getCreatedBy(),ApiUtils.getUpdatedBy());
    return retData;
  },
  appendUpdateRequiredField(data){
    let retData=Object.assign(data,ApiUtils.getUpdatedBy());
    return retData;
  },
  getCurrentTimeStamp(){
    return new Date().toISOString();
  },
  processAPIResponseWithLoader(response, hasDelay, delayTimeout, toggleLoader, callback) {
    if (hasDelay) {
      window.setTimeout(() => {
        toggleLoader();
        callback(response)
      }, delayTimeout);
    } else {
      toggleLoader();
      callback(response);
    }
  },
  checkZipCodeLength(zipCode) {
    if (zipCode.length >= 5 && zipCode.length <= 6 && (zipCode != "000000") && (zipCode != "00000")) {
      return true;
    } else {
      return false;
    }
  },
  setLoginData(login_response) {
    window.localStorage.setItem('loggedInUser', login_response.userName)
  },
  getLoggedInUser() {
    return window.localStorage.getItem('loggedInUser') === null ? '' : window.localStorage.getItem('loggedInUser');
  },
  
  

  checkFileExtension(filename, docType) {
    try {
      var idxDot = filename.lastIndexOf(".") + 1;
      var extFile = filename.substr(idxDot, filename.length).toLowerCase();
      if (docType == "image") {
        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
          return true;
        } else {
          return false;
        }
      }
      else {
        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "doc" || extFile == "docx" || extFile == "pdf" || extFile == "xls" || extFile == "xlsx") {
          return true;
        } else {
          return false;
        }
      }
    }
    catch (e) {
      console.log(e);
      return false;
    }
  },

  checkFileSize(file, fileLimit) {
    try {
      if (file.size / 1024 / 1024 < fileLimit) {
        return true;
      } else {
        return false;
      }
    }
    catch (e) {
      console.log(e);
      return false;
    }
  },
  ValidateBikeNo(bikeNo){
    const re = /^([A-Z|a-z]{2}\d{2}[A-Z|a-z]{1,2}\d{1,4})?([A-Z|a-z]{3}\d{1,4})?$/
    if(!re.test(bikeNo)){
      return false;
    }
    return true;
  },
  validateEmail(email){
    const re = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
    if(!re.test(email)){
      return false;
    }
    return true;
  },
  validateEngineNo(engineNo){
    // const re= /^([A-Za-z]{2}[A-z0-9]{5,16})$/
    // if(!re.test(engineNo)){
    //   return false;
    // }
    // return true;
    if(engineNo.length>4){
      return true
    }
    return false
  },
  validateMobileNo(mobileNo){
    if (mobileNo.length ==10) {
      
      return this.isNumeric(mobileNo);
    } else {
      return false;
    }
  },
  validateChasisNo(chasisNo){
    // const re= /^([A-Za-z]{2}[A-z0-9]{5,16})$/
    // if(!re.test(chasisNo)){
    //   return false;
    // }
    // return true;
    if(chasisNo.length>4){
      return true
    }
    return false
  },
  isNumeric(num){
    const re=/^[0-9]*$/
    if(!re.test(num)){
      return false;
    }
    return true;
  },
  validateModelYear(modelYear){
    if (modelYear.length ==4) {
      return this.isNumeric(modelYear);
    }
    return false;
  },
  initiateArrayDiff() {
    Array.prototype.diff = function (a) {
      return this.filter(function (i) { return a.indexOf(i) < 0; });
    };
  }
};
export { ApiUtils as default };
